
export function getText( appData, reference )
{
    if( reference.length == 1 )
    {
        return appData[reference[0]]
    }

    if( reference.length > 1 )
    {
        if( reference[0] == 'questions' )
        {
            if( reference.length == 4 )
            {
                var qidx = reference[1]
                var pidx = reference[2]
                var type = reference[3]

                try {
                    return appData['questions'][qidx]['parts'][pidx][type]
                }
                catch {
                    return null
                }
            }
            if( reference.length == 5 )
            {
                var qidx = reference[1]
                var pidx = reference[2]
                var type = reference[3]
                var aidx = reference[4]

                try {
                    return appData['questions'][qidx]['parts'][pidx][type][aidx]['text']
                }
                catch {
                    return null
                }
            }
        }
    }

    return null
}

export function putText( appData, reference, text )
{
    if( reference.length == 1 )
    {
        appData[reference[0]] = text
    }

    if( reference.length > 1 )
    {
        if( reference[0] == 'questions' )
        {
            if( reference.length == 4 )
            {
                var qidx = reference[1]
                var pidx = reference[2]
                var type = reference[3]

                appData['questions'][qidx]['parts'][pidx][type] = text
            }
            if( reference.length == 5 )
            {
                var qidx = reference[1]
                var pidx = reference[2]
                var type = reference[3]
                var aidx = reference[4]

                appData['questions'][qidx]['parts'][pidx][type][aidx]['text'] = text
            }
        }
    }

    return appData
}

