import { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import InputGroup from 'react-bootstrap/InputGroup'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import {backendAccessGet, backendAccessPost} from '../Utils/Authentication'

import { BiDownArrow, BiUpArrow } from 'react-icons/bi'

import VisitEdit from '../Components/Visits/VisitEdit'
import VisitRaw from '../Components/Visits/VisitRaw'

import { FaArrowRight } from "react-icons/fa";
import { TbTrashXFilled } from "react-icons/tb";
import { TbTrashX } from "react-icons/tb";
import { IoClose } from "react-icons/io5";
import { ImCross } from "react-icons/im";
import { FaRegSquarePlus } from "react-icons/fa6";
import { FaSquarePlus } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { ImPlus } from "react-icons/im";

import { MdDriveFileRenameOutline } from "react-icons/md";

import DNDListVertical from '../Components/Misc/DNDListVertical'

import VisitEditor from '../Components/Visits/VisitEditor'
import ModuleSelector from '../Components/Misc/ModuleSelector'

export function VisitButton( props )
{
    const selectedId = props.selectedId 
    const visitId = props.visitId 
    const name = props.name
    const onClick = props.onClick 
    const onRemove = props.onRemove
    const renameVisit = props.renameVisit

    return (
        <div style={{ width:'100%', height:'35px', marginBottom:'5px', cursor:'pointer' }}>
            <div style={{ backgroundColor:'#FFF', width:'30px', height:'35px', float:'left',
                          borderTopLeftRadius:'2px',
                          borderBottomLeftRadius:'2px'}}
                          onClick={ onRemove }>
                <ModuleSelector check={ selectedId == visitId }
                    childTrue={<div className="d-flex justify-content-center align-items-center" 
                                    style={{ height:'100%', float:'left', marginRight:'5px' }}>
                                    <TbTrashXFilled style={{ fontSize:24, marginLeft:'4px' }} />
                                </div>}
                    childFalse={<div className="d-flex justify-content-center align-items-center" 
                                     style={{ height:'100%', float:'left', marginRight:'5px' }}>
                                    <TbTrashX style={{ fontSize:24, marginLeft:'4px' }}/>
                                </div>}
                /> 
            </div>
            <div style={{ backgroundColor:( selectedId == visitId ) ? '#000000':'#FFFFFF', 
                          color: ( selectedId != visitId ) ? '#000000':'#FFFFFF',
                          padding:'8px', 
                          width:'calc(100% - 30px)',
                          height:'35px', float:'right',
                          borderTopRightRadius:'2px',
                          borderBottomRightRadius:'2px'}}
                 onClick={ onClick }
                 onDoubleClick={ e => renameVisit(visitId)}
            > 
                <div className="d-flex align-items-center" style={{ float:'left', height:'100%'}} >
                    { name }
                </div>
                { selectedId == visitId && 
                <div className="d-flex align-items-center" style={{ float:'right', height:'100%', fontSize:16, marginRight:'5px'}}>
                    <FaArrowRight />
                </div>
                }
            </div>
        </div>
    )
}


export function CategoryList( props )
{
    const selectedCategory = props.selectedCategory
    const setSelectedCategory = props.setSelectedCategory
    const category = props.category
    const categoryName = props.categoryName 
    const categoryModules = props.categoryModules
    const addModule = props.addModule

    if( selectedCategory != category )
    {
        return (
            <div style={{ width:'100%', cursor:'pointer', marginBottom:'15px' }} onClick={e => setSelectedCategory(category)}> 
                <IoIosArrowForward style={{ color:"#0057FF", marginRight:'10px' }}/> 
                <b>{ categoryName }</b>
            </div>
        )
    }

    return (
        <div style={{ width:'100%', marginBottom:'10px'}}>
            <div style={{ marginBottom:'10px'}}>
                <div style={{ width:'100%', cursor:'pointer' }}>
                    <IoIosArrowDown style={{ color:"#0057FF", marginRight:'10px' }}/> 
                    <b>{ categoryName }</b>
                </div>
            </div>
            { categoryModules.map( (module,idx) =>
                <div style={{ width:'calc(100% - 22px)', marginLeft:'22px', marginBottom:'5px'}} key={idx}>
                    <div className="fw-light" style={{ cursor:'pointer'}}
                         onClick={ e => addModule( module )}>
                        <FaSquarePlus style={{ color:'#0057FF', marginRight:'10px'}}/>
                       { module.name }
                    </div>
                </div>
            )}
        </div>
    )
}

export function VisitList( props )
{
    const visits = props.visits
    const selectedVisitId = props.selectedAddVisitId
    const selectVisit = props.selectVisit
    const removeVisit = props.removeVisit
    const setCreateNewVisit = props.setCreateNewVisit
    const selectedVisit = props.selectedVisit
    const setSelectedVisit = props.setSelectedVisit
    const updateVisit = props.updateVisit
    const renameVisit = props.renameVisit
    const discardChanges = props.discardChanges
    const hasChange = props.hasChange
    const categories = props.categories
    const dataModules = props.dataModules

    return (
        <div style={{ width:'100%', height:'100%'}}>
            <div style={{ height:'100%', width:'400px', float:'left' }}>
                <div className="d-flex justify-content-center align-items-center" 
                     style={{ width:'100%', height:'50px', padding:'5px' }}>
                    <Form.Control style={{ borderRadius:'2px', border: '1px solid #DADADA' }} placeHolder="Search visits"/> 
                </div>
                <div style={{ width:'100%', height:'calc(100% - 50px)',
                              borderRadius:'2px', padding:'5px'}}>
                    <div style={{ height:'100%', width:'100%', backgroundColor:'#F6F6F6', padding:'20px', borderRadius:'2px'}}>
                        <div style={{ marginBottom:'5px', height:'20px', marginLeft:'10px'}}>
                            <b>Visits</b>
                        </div>
                        <div style={{ height:'calc(100% - 85px)', width:'100%', overflowY:'scroll'}}>
                            { visits.map( (visit,idx) => 
                                <VisitButton selectedId={selectedVisitId}
                                             visitId={visit.visit_id}
                                             name={visit.visit_name}
                                             onClick={ e => selectVisit(visit) }
                                             onRemove={ e => removeVisit(visit) }
                                             renameVisit={ renameVisit }
                                             key={idx}
                                />
                            )}
                        </div>
                        <ModuleSelector check={hasChange}
                                        childFalse={<div style={{ height:'60px', width:'100%', padding:'5px'}}>
                                                        <Button style={{ width:'100%', borderRadius:'2px'}}
                                                                onClick={ e => setCreateNewVisit(true)} >Create a new visit</Button>
                                                    </div>}
                                        childTrue={<div style={{ height:'60px', width:'100%', padding:'5px'}}>
                                                        <Button variant="danger" style={{ width:'100%', borderRadius:'2px', marginBottom:'10px'}}
                                                                onClick={ e => updateVisit( selectedVisitId, selectedVisit )} >Save changes</Button>
                                                        <div className='d-flex justify-content-center align-items-center' style={{ width:'100', color:'blue' }}>
                                                            <div style={{ cursor:'pointer' }} onClick={ discardChanges }>
                                                                Discard changes
                                                            </div>
                                                        </div>  
                                                    </div>}
                        />
                    </div>
                </div>
            </div>
            <div style={{ height:'100%', width:'calc(100% - 400px)', float:'left' }}>
                <div className="d-flex align-items-center justify-content-end" 
                     style={{ width:'100%', height:'50px', padding:'5px' }}>
                    { selectedVisitId &&
                    <div style={{ height:'100%'}}>
                        {/*<Button variant={(hasChange) ? "danger" : "primary"}
                                style={{ marginRight:'5px', borderRadius:'2px' }} 
                                onClick={ e => updateVisit( selectedVisitId, selectedVisit )}>Save</Button>
                        <Button variant="secondary" style={{ borderRadius:'2px' }}
                                onClick={ e => renameVisit( selectedVisitId, selectedVisit )}>Rename</Button>*/}
                    </div>
                    }
                </div>
                <div style={{ width:'100%', height:'calc(100% - 50px)',
                              borderRadius:'2px', padding:'5px'}}>
                    <VisitEditor data={selectedVisit} setData={setSelectedVisit} visitId={selectedVisitId} 
                                 hasChange={hasChange} categories={categories} dataModules={dataModules} canAdd={true}/>
                </div>
            </div>
        </div>
    )
}

export function CreateVisit( props )
{
    const setCreateNewVisit = props.setCreateNewVisit
    const categories = props.categories
    const dataModules = props.dataModules
    const createNewVisit = props.createVisit

    const [ visitName, setVisitName ] = useState("")
    const [ visitNameError, setVisitNameError ] = useState( false )
    const [ data, setData ] = useState( null )

    const selectedCategoryRef = useRef(null)

    const [ selectedCategory, setSelectedCategory ] = useState( null )

    useEffect( () => {

        if( data == null )
        {
            let d = []
            setData( d )
        }

    },[])

    const createVisit = () => 
    {
        if( visitName == "" )
        {
            setVisitNameError( true )
            return
        }

        setVisitNameError( false )
        createNewVisit( visitName, data )

        //setCreateNewVisit( false )
    }

    const addModule = ( module ) => {
        let d = structuredClone( data )

        let category_index = -1

        for( var idx=0 ; idx < d.length ; idx++ )
        {
            let item = d[idx]
            if( item.category == module.category )
            {
                category_index = idx 
                break
            }
        }

        if( category_index == -1 )
        {
            let cat_data = {category:module.category, category_name:module.category_name, modules:[] }
            d.push( cat_data )
            category_index = d.length-1
        }

        for( let m of d[ category_index ].modules )
        {
            if( m.id == module.id )
            {
                return
            }
        }

        d[ category_index ].modules.push( module )
        selectedCategoryRef.current = module.category
        setData(d)
        setSelectedCategory( module.category )
    }

    return (
        <div style={{ width:'100%', height:'100%'}}>
            <div style={{ height:'100%', width:'400px', float:'left' }}>
                <div className="d-flex justify-content-center align-items-center" 
                     style={{ width:'100%', height:'50px', padding:'5px' }}>
                </div>
                <div style={{ width:'100%', height:'calc(100% - 50px)',
                              borderRadius:'2px', padding:'5px'}}>
                    <div style={{ height:'100%', width:'100%', backgroundColor:'#F6F6F6', padding:'20px'}}>
                        <div style={{ height:'40px', width:'100%' }}>
                            <Form.Control placeHolder="Visit name" 
                                          value={visitName}
                                          onChange={ e => setVisitName(e.target.value) }
                                          style={{ borderRadius:'2px'}}
                                          isInvalid={visitNameError}/> 
                        </div>

                        <div style={{ height:'calc(100% - 140px)', width:'100%', overflowY:'scroll',
                                      marginTop:'20px', marginBottom:'20px'}}>
                        { categories.map( (category, idx) => 
                            <CategoryList selectedCategory={selectedCategory}
                                          setSelectedCategory={setSelectedCategory}
                                          category={category} 
                                          categoryName={dataModules[category].category_name}
                                          categoryModules={dataModules[category].modules}
                                          addModule={addModule}
                                          key={idx}
                            />
                        )}
                        </div>
                        <div style={{ height:'60px', width:'100%', padding:'5px'}}>
                            <Button style={{ width:'100%', borderRadius:'2px', marginBottom:'10px'}}
                                    onClick={createVisit} >Create a new visit</Button>
                            <div className='d-flex justify-content-center align-items-center' 
                                 style={{ width:'100', color:'blue' }}>
                                <div style={{ cursor:'pointer' }} onClick={e => setCreateNewVisit(false)}>
                                Discard and return to visit designer
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height:'100%', width:'calc(100% - 400px)', float:'left' }}>
                <div  style={{ width:'100%', height:'50px', padding:'5px' }}>
                </div>
                <div style={{ width:'100%', height:'calc(100% - 50px)', padding:'5px'}}>
                    <VisitEditor  data={data} setData={setData} selectedCategory={selectedCategoryRef} />
                </div>
            </div>
        </div>
    )
}

export default function VisitDesigner ()
{
    const [ categories, setCategories ] = useState(null)
    const [ dataModules, setDataModules ] = useState(null)
    const [ templates, setTemplates ] = useState(null)

    const [ selectedVisitId, setSelectedVisitId ] = useState(null)
    const [ selectedVisit, setSelectedVisit ] = useState(null)
    const selectedVisitJson = useRef(null)
    const [ hasChange, setHasChange ] = useState(false)


    const [ createNewVisit, setCreateNewVisit ] = useState(false)

    const [ visitName, setVisitName ] = useState(null)
    const [ checks, setChecks ] = useState(null)

    const [ editVisitId, setEditVisitId ] = useState(null)
    const [ editVisitIdx, setEditVisitIdx ] = useState(null)
    const [ editVisitName, setEditVisitName ] = useState(null)

    const [ rawVisitId, setRawVisitId ] = useState(null)
    const [ rawVisitIdx, setRawVisitIdx ] = useState(null)
    const [ rawVisitName, setRawVisitName ] = useState(null)

    const [ visitTemplate, setVisitTemplate ] = useState(null)

    const load = () => {
        backendAccessGet('/api/visit-designer/').then( res => {
            if( 'error_message' in res )
            {
                alert( res['error_message'] )
                return
            }

            setCategories( res["category_names"] )
            setDataModules( res["data_modules"] )
            setTemplates( res["templates"] )
        })
    }

    useEffect( () => {
        load()
    },[])

    const selectVisit = ( visit ) => {
        let visit_template_data = visit.visit_template_data


        selectedVisitJson.current = JSON.stringify(visit_template_data)
        setHasChange(false)
        setSelectedVisitId( visit.visit_id )
        setSelectedVisit( structuredClone(visit_template_data) )
    }

    const discardChanges = () => {
        if( selectedVisitId == null )
        {
            return 
        }

        for( let visit of templates )
        {
            if( visit.visit_id == selectedVisitId )
            {
                setSelectedVisit( structuredClone(visit.visit_template_data) )
                break
            }
        }
    }

    useEffect( () => {
        if( selectedVisit == null )
        {
            return 
        }

        let dataJson = JSON.stringify(selectedVisit)

        if( dataJson == selectedVisitJson.current )
        {
            setHasChange(false)
        }
        else 
        {
            setHasChange(true)
        }

    },[selectedVisit])

    const removeVisit = ( visitTemp ) => {
        if( !window.confirm(`Are you sure you want to remove ${visitTemp.visit_name}?`) )
        {
            return
        }

        var data = {}
        data['visit_id'] = visitTemp.visit_id

        backendAccessPost('/api/visit-designer/remove/', data).then( res => {
            if( 'error_message' in res )
            {
                alert( res['error_message'] )
                return
            }
            setTemplates( res["templates"] )
            setSelectedVisitId(null)
            setSelectedVisit(null)
        })
    }

    const createVisit = ( visit_name, modules ) =>
    {
        let data = {}
        data['visit_name'] = visit_name 
        data['module_groups'] = modules 

        backendAccessPost('/api/visit-designer/add/', data ).then( res => {
            if( 'error_message' in res )
            {
                alert( res['error_message'] )
                return
            }

            setTemplates( res["templates"] )
            setCreateNewVisit( false )
        })
    }

    const updateVisit = ( visit_id, visit ) => {
        var data = {}
        data['visit_id'] = visit_id
        data['visit_template'] = visit        

        backendAccessPost('/api/visit-designer/update/', data).then( res => {
            if( 'error_message' in res )
            {
                alert( res['error_message'] )
                return
            }
            setTemplates( res["templates"] )
            selectedVisitJson.current = JSON.stringify(visit)
            setHasChange(false)
        })
    }

    const renameVisit = ( visit_id ) => {
        var new_name = prompt("Enter the new name for the visit :")

        if( new_name == null || new_name == "" )
        {
            return;
        }

        var data = {}
        data['visit_id'] = visit_id
        data['new_name'] = new_name

        backendAccessPost('/api/visit-designer/rename/', data).then( res => {
            if( 'error_message' in res )
            {
                alert( res['error_message'] )
                return
            }
            setTemplates( res["templates"] )
        })
    }

    return (
            <Container style={{ maxWidth:'1400px', height:'calc(100vh - 65px)', fontSize:12, marginTop:'5px' }}>        
                { templates &&
                    <ModuleSelector check={createNewVisit}
                                    childFalse={ <VisitList visits={templates} 
                                                            selectedAddVisitId={selectedVisitId}
                                                            selectVisit={selectVisit}
                                                            removeVisit={removeVisit}
                                                            setCreateNewVisit={setCreateNewVisit}
                                                            selectedVisit={selectedVisit}
                                                            setSelectedVisit={setSelectedVisit}
                                                            selectedVisitJson={selectedVisitJson}
                                                            updateVisit={updateVisit}
                                                            renameVisit={renameVisit}
                                                            discardChanges={discardChanges}
                                                            hasChange={hasChange}
                                                            categories={categories}
                                                            dataModules={dataModules}
                                                  /> }
                                    childTrue={ <CreateVisit setCreateNewVisit={setCreateNewVisit}
                                                             categories={categories}
                                                             dataModules={dataModules}
                                                             createVisit={createVisit}
                                                /> }
                    />
                }
            </Container>
    )
}
