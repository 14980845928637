import { Helmet } from 'react-helmet'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import MenuButton from '../Components/UX/MenuButton';

export default function About ()
{
    return (
        <>
            <div className="d-flex justify-content-center align-items-center"
                    style={{ height: 'calc(100vh - 60px)', width:"100%", backgroundColor:'#F6F6F6'}}>
                    <div style={{ height:'170px', width:'300px' }}>
                        <div className="d-flex justify-content-center align-items-center fw-bolder" 
                             style={{ width:'100%', height:'30px', marginBottom:'10px', fontSize:12 }}>
                            Downloads
                        </div>
                        <MenuButton title="Image uploader"/>
                        <MenuButton title="Brady labels"/>
                        <MenuButton title="ID Match"/>
                    </div> 
            </div>
        </>
    )
}
