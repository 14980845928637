import { useState, useEffect } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

import {backendAccessGet, backendAccessPost} from '../../../Utils/Authentication'

const body_regions = [ "Not taken","Face", "Scalp", "Neck", "Chest", "Abdomen", "Upper back", "Lower back",
                       "Axillae", "Arm", "Antecubital fossa", "Palmar surface", "Gluteal region",
                       "Inguinal crease", "Genital region", "Leg", "Popliteal fossa", "Foot",
                       "Plantar surface" ]

const body_side = [ "Left", "Right", "NA" ]


export default function BiobankViewer( props )
{
    const patientId = props.patientId
    const visitId = props.visitId
    const moduleId = props.moduleId
    
    const instanceData = props.instanceData
    const setInstanceData = props.setInstanceData
    const template = props.template
    const version = props.version
    const status = props.status
    const reload = props.reload
    const hasChange = props.hasChange

    const [ moduleType, setModuleType ] = useState(null)
    const [ lesionalSample, setLesionalSample ] = useState("")
    const [ lesionalSampleSide, setLesionalSampleSide ] = useState("")
    const [ nonLesionalSample, setNonLesionalSample ] = useState("")
    const [ nonLesionalSampleSide, setNonLesionalSampleSide ] = useState("")
    const [ notes, setNotes ] = useState(null)

    const checkInstanceData = () => {
        if( !('bio_samples' in instanceData) )
        {
            return false;
        }

        var old_version = false;

        for( var idx in instanceData.bio_samples )
        {
            var val = instanceData.bio_samples[idx];

            if( typeof val == "string" )
            {
                old_version = true;
                break;
            }
            else
            {
                break;
            }
        }

        if( old_version )
        {
            var new_bio_samples = {}

            for( var idx in instanceData.bio_samples )
            {
                var d = {sample_id : instanceData.bio_samples[idx], check : true, taken: false, copies: 1}
                new_bio_samples[idx] = d
            }

            instanceData.bio_samples = new_bio_samples;
            setInstanceData( {...instanceData} )

            return false;
        }

        return true;
    }

    const load = ( template ) => {
        /*if( !checkInstanceData() )
        {
            return
        }*/

        if( template != null )
        {
            if( instanceData['bio_samples'] == null )
            {
                var d = {}
                d['bio_samples'] = {}
                setInstanceData( d )
            }

            if( instanceData['lesional'] != null )
            {
                setLesionalSample( instanceData['lesional'] )
            }

            if( instanceData['lesional_side'] != null )
            {
                setLesionalSampleSide( instanceData['lesional_side'] )
            }

            if( instanceData['non_lesional'] != null )
            {
                setNonLesionalSample( instanceData['non_lesional'] )
            }

            if( instanceData['non_lesional_side'] != null )
            {
                setNonLesionalSampleSide( instanceData['non_lesional_side'] )
            }

            if( instanceData['notes'] != null )
            {
                setNotes( instanceData['notes'] )
            }
        }
    }

    useEffect( () => { 
        load( template )
    }, [])
    
    useEffect( () => {
        load( template )
    }, [ instanceData, template, version ] )

    useEffect( () => {
        var shouldUpdate = false
        var data = structuredClone(instanceData)

        if( lesionalSample != "" && lesionalSample != data.lesional )
        {
            data['lesional'] = lesionalSample
            shouldUpdate = true
        }

        if( lesionalSampleSide != "" && lesionalSampleSide != data.lesional_side )
        {
            data['lesional_side'] = lesionalSampleSide
            shouldUpdate = true
        }

        if( nonLesionalSample != "" && nonLesionalSample != data.non_lesional )
        {
            data['non_lesional'] = nonLesionalSample
            shouldUpdate = true
        }

        if( nonLesionalSampleSide != "" && nonLesionalSampleSide != data.non_lesional_side )
        {
            data['non_lesional_side'] = nonLesionalSampleSide 
            shouldUpdate = true 
        }

        if( notes != data.notes )
        {
            data['notes'] = notes 
            shouldUpdate = true
        }

        if( shouldUpdate )
        {
            setInstanceData(data)
        }

    }, [ lesionalSample, lesionalSampleSide, nonLesionalSample, nonLesionalSampleSide, notes ] )

    const setCheck = ( sample_id, val ) => {

        var data = structuredClone(instanceData)
        data.bio_samples[sample_id].check = val
        setInstanceData(data)
    }

    const setTaken = ( sample_id, val ) => {
        var data = structuredClone(instanceData)
        data.bio_samples[sample_id].taken = val
        setInstanceData(data)
    }

    const setNumCopies = ( sample_id, val ) => {
        try {
            var data = structuredClone(instanceData)
            data.bio_samples[sample_id].copies = parseInt(val)
            setInstanceData(data)
        } catch {
            alert("Something went wrong.")
        }
    }

    const registerSamples = () => {
        var data = {}
        data['patient_id'] = patientId
        data['visit_id'] = visitId
        data['module_id'] = moduleId

        backendAccessPost('/api/biobank/register/', data ).then( res => {
            if("error_message" in res)
            {
                alert( res["error_message"] )
                return
            }
            reload()
        })
    }

    const registerSample = ( templateVar ) => {
        var data = {}
        data['patient_id'] = patientId
        data['visit_id'] = visitId
        data['module_id'] = moduleId
        data['sample_var'] = templateVar

        backendAccessPost('/api/biobank/register-single/', data ).then( res => {
            if("error_message" in res)
            {
                alert( res["error_message"] )
                return
            }
            reload()
        })
    }

    const downloadCSV = () => {
        if( hasChange )
        {
            alert("Please save the changes first.")
            return
        }

        var data = {}
        data['patient_id'] = patientId
        data['visit_id'] = visitId
        data['module_id'] = moduleId

        backendAccessPost('/api/biobank/fetch-csv/', data ).then( res => {
            if("error_message" in res)
            {
                alert( res["error_message"] )
                return
            }

            var data = new Blob([res['csv_data']], {type: 'text/csv'});
            var csvURL = window.URL.createObjectURL(data);
            var tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', res['csv_fname']);
            tempLink.click();
        })
    }

    return (
        <div style={{ height:'100%', width:'100%', padding:'10px', overflowY:'auto' }}>
        <div className="d-flex justify-content-center" 
             style={{ height:'60px', width:'100%', padding:'10px'}}>
            <Button variant="secondary" onClick={downloadCSV}>Download CSV</Button>
        </div>

        <div style={{marginBottom:'10px'}}>
            <div>
                <b>Lesional</b>
            </div>
            <div>
                <InputGroup style={{width: '400px'}}>
                    <Form.Select style={{width:'200px'}} value={lesionalSample} 
                                 onChange={ e => setLesionalSample(e.target.value)}>
                        <option value=""> -- Select --</option>
                        { body_regions.map( (b,idx) =>
                        <option key={idx} value={b}>{b}</option>
                        )}
                    </Form.Select>
                    <Form.Select style={{width:'100px'}} value={lesionalSampleSide} 
                                 onChange={ e => setLesionalSampleSide(e.target.value)}>
                        <option value=""> -- Select --</option>
                        { body_side.map( (b,idx) =>
                        <option key={idx} value={b}>{b}</option>
                        )}
                    </Form.Select>

                </InputGroup>
            </div>
            <div>
                <b>Non-lesional</b>
            </div>
            <div>
                <InputGroup style={{width: '400px'}}>
                    <Form.Select style={{width:'200px'}} value={nonLesionalSample}
                                 onChange={ e => setNonLesionalSample(e.target.value) }>
                        <option value=""> -- Select --</option>
                        { body_regions.map( (b,idx) =>
                            <option key={idx} value={b}>{b}</option>
                        )}
                    </Form.Select>
                    <Form.Select style={{width:'100px'}} value={nonLesionalSampleSide} 
                                 onChange={ e => setNonLesionalSampleSide(e.target.value)}>
                        <option value=""> -- Select --</option>
                        { body_side.map( (b,idx) =>
                        <option key={idx} value={b}>{b}</option>
                        )}
                    </Form.Select>
                </InputGroup>
            </div>
        </div>


        { instanceData.bio_samples &&
        <>
        <div>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th style={{ width: "20px"}}>#</th>
                    <th style={{ width: "20px"}}>Taken</th>
                    <th style={{ width: "calc(100% - 220px)"}}>Sample Name</th>
                    <th style={{ width: "200px"}}>Status</th>
                    <th style={{ width: "40px"}}>#Copies</th>
                    <th style={{ width: "20px"}}>Print</th>
                </tr>
            </thead>

            <tbody>
            { template.map( (t,idx) => 
                <>
                <tr>
                    <td> {idx + 1} </td>
                    <td>
                        { instanceData.bio_samples[ t.var ] && 
                        <Form.Check type='checkbox' checked={instanceData.bio_samples[t.var].taken}
                                     onChange={ e => setTaken(t.var, e.target.checked) }/>
                        }
                    </td>
                    <td> { t.name } </td>
                    <td>
                        { instanceData.bio_samples[ t.var ] && 
                        <>
                            {instanceData.bio_samples[t.var].sample_id}
                        </>
                        }
                        { !instanceData.bio_samples[ t.var ] &&
                        <Button size="sm" variant="secondary"
                                onClick={ e => registerSample(t.var) }>Register Sample</Button>
                        }
                    </td>
                    <td>
                        { instanceData.bio_samples[ t.var ] && 
                        <Form.Control size="sm" value={instanceData.bio_samples[t.var].copies}
                                                 onChange={ e => setNumCopies(t.var, e.target.value)}/>
                        }
                    </td>
                    <td>
                        { instanceData.bio_samples[ t.var ] && 
                        <Form.Check type='checkbox' checked={instanceData.bio_samples[t.var].check}
                                     onChange={ e => setCheck(t.var, e.target.checked) }/>
                        }
                    </td>

                </tr>
                </>
            )}
            </tbody>
        </Table>
        </div>
        <div>
            <b>Notes</b>
        </div>
        <div>
            <Form.Control as="textarea" value={notes} onChange={ e => setNotes(e.target.value) } rows={10}   />
        </div>
        </>
        }
        </div>
    )
}
