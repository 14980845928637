import { Navigate } from 'react-router-dom'
import { verify } from '../Utils/Authentication'
import { useState, useEffect } from 'react'

export default function AlreadyLoggedIn({ children }) {
    const [ loaded, setLoadded ] = useState( false )
    const [ auth, setAuth ] = useState( false )

    useEffect( () => {
        verify().then( a => {
            setAuth(a)
            setLoadded(true)
        })
    }, [])

    if( !loaded )
    {
        return null
    }

    return auth ? <Navigate to="/" /> : children ;
}
