import { useState, useEffect, useRef } from 'react'
import DNDListVertical from '../Misc/DNDListVertical'

import { FaArrowRight } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { FaSquarePlus } from "react-icons/fa6";
import { ImPlus } from "react-icons/im";

export function CategoryButton( props )
{
    const item = props.item
    const index = props.index 

    const selectedIdx = props.params.selectedIdx
    const onClick = props.params.onClick
    const onRemove = props.params.onRemove

    return (
        <div className="d-flex align-items-center" 
             style={{ backgroundColor:( selectedIdx == index ) ? '#000000':'#FFFFFF', 
                      color: ( selectedIdx != index ) ? '#000000':'#FFFFFF',
                      border: ( selectedIdx == index ) ? '0.2px solid' : '0.2px dashed',
                      height:'35px', width:'100%', borderRadius:'2px', marginBottom:'5px', padding:'8px',
                      cursor:'pointer' }}
             onClick={ e => onClick( index, item ) }
        >
            <div style={{ marginLeft:'5px', width:'100%', height:'100%'}}>
                <div className="d-flex align-items-center" 
                     style={{ height:'100%', float:'left' }}
                     onClick={ e => onRemove(index) }>
                    <ImCross />
                </div>
                <div style={{ height:'100%', marginLeft:'10px', float:'left'}}>
                    { item.category_name }
                </div>
                { selectedIdx == index && 
                <div className="d-flex align-items-center" style={{ float:'right', height:'100%', marginRight:'5px', fontSize:16 }}>
                    <FaArrowRight />
                </div>
                }
            </div>
        </div>
    )
}

export function ModuleButton( props )
{
    const item = props.item
    const index = props.index 

    const selectedIdx = props.params.selectedIdx
    const onClick = props.params.onClick 
    const onRemove = props.params.onRemove

    return (
        <div className="d-flex align-items-center" 
             style={{ backgroundColor:( selectedIdx == index ) ? '#000000':'#FFFFFF', 
                      color: ( selectedIdx != index ) ? '#000000':'#FFFFFF',
                      border: ( selectedIdx == index ) ? '0.2px solid' : '0.2px dashed',
                      height:'35px', width:'100%', borderRadius:'2px', marginBottom:'5px', padding:'8px',
                      cursor:'pointer' }}
             onClick={ e => onClick( index, item ) }
        >
            <div style={{ marginLeft:'5px', width:'100%', height:'100%'}}>
                <div className="d-flex align-items-center" 
                     style={{ height:'100%', float:'left' }}
                     onClick={e => onRemove(index)} >
                    <ImCross />
                </div>
                <div style={{ height:'100%', marginLeft:'10px', float:'left'}}>
                    {item.name}
                </div>
            </div>
        </div>
    )
}

export function AddNewModuleButton( props )
{
    const text = props.text
    const visitId = props.visitId

    const categoryIdx = props.categoryIdx
    const categoty = props.category
    const modules = props.modules
    const data = props.data
    const addModule = props.addModule
    
    const [ isActive, setIsActive ] = useState(false)

    const [ moduleList, setModuleList ] = useState([])

    useEffect( () => {
        setIsActive(false)
    },[ visitId, categoryIdx ])

    useEffect( () => {
        if( !isActive )
        {
            setModuleList([])
            return
        }

        let module_list = []

        for( let module of modules )
        {
            let found = false
            for( let m of data )
            {
                if( m.id == module.id )
                {
                    found = true 
                    break
                }
            }

            if( !found )
            {
                module_list.push( module )
            }
        }

        setModuleList( module_list )


    },[isActive] )

    const add = ( module ) => {
        addModule( module )
        setIsActive( false )
    }


    if( isActive )
    {
        return (
            <div  
                     style={{ backgroundColor:'#FFFFFF', 
                              color:'#000000',
                              border: '0.2px dashed',
                              width:'100%', borderRadius:'2px', marginBottom:'5px', padding:'10px'}}
            >
            { moduleList.map( (module,idx) => 
            <div className="flex justify-content-center align-items-center" 
                 style={{ width:'100%', height:'30px', padding:'3px' }}>
                <div style={{ cursor:'pointer' }} 
                     onClick={ e => add( module ) }>
                    <FaSquarePlus style={{ color:'#0057FF', marginRight:'10px'}}/>
                        { module.name }
                </div>
            </div>
            )}

            <div className="d-flex justify-content-center align-items-center" style={{width:'100%', height:'30px'}}>
                    <div style={{ cursor:'pointer', color:'blue' }} 
                         onClick={ e => setIsActive(false) }>
                        Close
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="d-flex align-items-center" 
                     style={{ backgroundColor:'#FFFFFF', 
                              color:'#AEAEAE',
                              border: '0.2px dashed',
                              height:'35px', width:'100%', borderRadius:'2px', marginBottom:'5px', padding:'8px',
                              cursor:'pointer' }}
                     onClick={ e => setIsActive(!isActive)}
                >
            <div style={{ marginLeft:'5px', width:'100%', height:'100%'}}>
                <div className="d-flex align-items-center" 
                     style={{ height:'100%', float:'left' }}>
                    <ImPlus />
                </div>
                <div style={{ height:'100%', marginLeft:'10px', float:'left'}}>
                    <b>{text}</b>
                </div>
            </div>
        </div>
    )
}

export function AddNewCategoryButton( props )
{
    const text = props.text
    const visitId = props.visitId
    const categories = props.categories
    const dataModules = props.dataModules
    const data = props.data
    const addCategory = props.addCategory

    const [ isActive, setIsActive ] = useState(false)
    const [ categoryList, setCategoryList ] = useState([])

    useEffect( () => {
        setIsActive(false)
    },[ visitId ])

    useEffect( () => {
        if( !isActive )
        {
            setCategoryList([])
            return
        }

        let category_list = []

        for( let category of categories )
        {
            let found = false

            for( let d of data )
            {
                if( d.category == category )
                {
                    found = true 
                    break
                }
            }

            if( !found )
            {
                category_list.push( category )
            }
        }

        setCategoryList( category_list )
    },[ isActive ] )

    const add = ( cat ) => {
        addCategory( cat )
        setIsActive(false)
    }

    if( isActive )
    {
        return (
            <div  
                     style={{ backgroundColor:'#FFFFFF', 
                              color:'#000000',
                              border: '0.2px dashed',
                              width:'100%', borderRadius:'2px', marginBottom:'5px', padding:'10px'}}
            >
                { categoryList.map( (cat,idx) => 
                <div className="flex justify-content-center align-items-center" 
                     style={{ width:'100%', height:'30px', padding:'3px' }}>
                    <div style={{ cursor:'pointer' }} 
                         onClick={ e => add( cat ) }>
                        <FaSquarePlus style={{ color:'#0057FF', marginRight:'10px'}}/>

                        { dataModules[ cat ].category_name }
                    </div>
                </div>

                )}
                <div className="d-flex justify-content-center align-items-center" style={{width:'100%', height:'30px'}}>
                    <div style={{ cursor:'pointer', color:'blue' }} 
                         onClick={ e => setIsActive(false) }>
                        Close
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="d-flex align-items-center" 
             style={{ backgroundColor:'#FFFFFF', 
                      color:'#AEAEAE',
                      border: '0.2px dashed',
                      height:'35px', width:'100%', borderRadius:'2px', marginBottom:'5px', padding:'8px',
                      cursor:'pointer' }}
             onClick={ e => setIsActive(!isActive)}
        >
            <div style={{ marginLeft:'5px', width:'100%', height:'100%'}}>
                <div className="d-flex align-items-center" 
                     style={{ height:'100%', float:'left' }}>
                    <ImPlus />
                </div>
                <div style={{ height:'100%', marginLeft:'10px', float:'left'}}>
                    <b>{text}</b> 
                </div>
            </div>
        </div>
    )
}

export default function VisitEditor( props )
{
    const data = props.data
    const setData = props.setData
    const selectedCategory = props.selectedCategory
    const visitId = props.visitId
    const hasChange = props.hasChange 
    const categories = props.categories
    const dataModules = props.dataModules
    const canAdd = props.canAdd
    
    const [ selectedCategoryIdx, setSelectedCategoryIdx ] = useState(null)
    const [ selectedCategoryId, setSelectedCategoryId ] = useState(null)
    const [ selectedModuleIdx, setSelectedModuleIdx ] = useState(null)

    useEffect( () => {
        if( selectedCategoryIdx == null )
        {
            return
        }

        let category_id = data[selectedCategoryIdx].category
        setSelectedCategoryId( category_id )

        setSelectedModuleIdx(null)
    }, [selectedCategoryIdx])

    useEffect( () => {
        if( selectedCategory == null )
        {
            return
        }

        if( selectedCategory.current == null )
        {
            return
        }

        for( let idx=0 ; idx<data.length ; idx++ )
        {
            if( data[idx].category == selectedCategory.current )
            {
                setSelectedCategoryIdx( idx )
                break
            }
        }
    },[ data ])

    useEffect( () => {
        setSelectedCategoryIdx(null)
        setSelectedModuleIdx(null)
    }, [ visitId ] )

    const categoryIdBuider = ( index, item ) => {
        if( item.id )
        {
            return item.id + index 
        }

        return item.category_name + index
    }

    const categoryOnClick = ( index, item ) => {
        setSelectedCategoryIdx( index )
    }

    const categoryReorder = ( list, startIndex, endIndex ) => {
        var l = structuredClone(data)
        const [removed] = l.splice(startIndex, 1);
        l.splice(endIndex, 0, removed);

        setData( l )
        setSelectedCategoryIdx(endIndex)
    }

    const categoryRemove = ( index ) => {
        if( !window.confirm("Are you sure you want to remove?") )
        {
            return
        }


        var l = structuredClone(data)
        const [removed] = l.splice(index, 1);
        
        setData( l )
        setSelectedCategoryIdx(null)
    }

    const moduleIdBuider = ( index, item ) => {
        if( item.id )
        {
            return item.id + index 
        }

        return item.module_id + index
    }

    const moduleOnClick = ( index, item ) => {
        setSelectedModuleIdx( index )
    }

    const moduleReorder = ( list, startIndex, endIndex ) => {
        var l = structuredClone(data[selectedCategoryIdx].modules)
        const [removed] = l.splice(startIndex, 1);
        l.splice(endIndex, 0, removed);

        let dd = structuredClone( data )

        dd[selectedCategoryIdx].modules = l 
        setData( dd )
        setSelectedModuleIdx(endIndex)
    }

    const moduleRemove = ( index ) => {
        if( !window.confirm("Are you sure you want to remove?") )
        {
            return
        }


        var l = structuredClone(data[selectedCategoryIdx].modules)
        const [removed] = l.splice(index, 1);
        
        data[selectedCategoryIdx].modules = l 

        setSelectedModuleIdx(null)
        setData( [ ...data ] )
    }

    const addCategory = ( cat ) => {
        let cat_data = dataModules[cat] 

        let add_data = {}
        add_data.category = cat_data.category
        add_data.category_name = cat_data.category_name
        add_data.modules = []

        let dd = structuredClone( data )
        dd.push( add_data )
        let catIdx = dd.length-1

        setData( dd )
        setSelectedCategoryIdx( catIdx )
    }
    
    const addModule = ( module ) => {
        if( selectedCategoryIdx == null )
        {
            return
        }

        if( data[selectedCategoryIdx] == null )
        {
            return
        }

        let dd = structuredClone( data )
        dd[selectedCategoryIdx].modules.push( module )
        
        let sidx =  dd[selectedCategoryIdx].modules.length-1

        setData( dd )
        setSelectedModuleIdx( sidx )
    }

    return (
        <div style={{ height:'100%', width:'100%', 
                      border: (hasChange) ? '1px solid #DC4C64' : '1px solid #0057FF', 
                      borderRadius:'2px', padding:'10px'}}>
            { ( data ) &&
            <>
            <div style={{ width:'50%', height:'100%', float:'left', padding:'10px', overflowY:'scroll'  }}>
                <div className="fw-bolder" style={{ width:'100%', height:'20px', marginBottom:'5px'}} >
                    <div style={{ marginLeft:'10px'}}>Categories</div>
                </div>
                <div className="fw-light" style={{ width:'100%', overflowY:'scroll'}}>
                    <DNDListVertical items={data}
                                     direction="vertical"
                                     reorder={categoryReorder}
                                     Inner={CategoryButton}
                                     innerParams={{ selectedIdx: selectedCategoryIdx, 
                                                'onClick': categoryOnClick,
                                                'onRemove' : categoryRemove
                                              }}
                                     idBuilder={categoryIdBuider}
                    />  
                </div>
                { canAdd &&
                <AddNewCategoryButton text="Add a new category"
                                      visitId={visitId}
                                      categories={categories} 
                                      dataModules={dataModules}
                                      data={data}
                                      addCategory={addCategory}
                />
                }
            </div>
            <div style={{ width:'50%', height:'100%', float:'right', padding:'10px', overflowY:'scroll' }}>
            { (selectedCategoryIdx != null && data[selectedCategoryIdx] != null ) &&
                <>
                    <div className="fw-bolder" style={{ width:'100%', height:'20px', marginBottom:'5px'}} >
                        <div style={{ marginLeft:'10px'}}>Modules</div>
                    </div>
                    <div className="fw-light" style={{ width:'100%', 
                                                       overflowY:'scroll'}}
                    >
                        <DNDListVertical items={data[selectedCategoryIdx].modules}
                                         direction="vertical"
                                         reorder={moduleReorder}
                                         Inner={ModuleButton}
                                         innerParams={{ selectedIdx: selectedModuleIdx, 
                                                'onClick': moduleOnClick,
                                                'onRemove' : moduleRemove
                                              }}
                                         idBuilder={moduleIdBuider}
                        />
                    </div>
                    { ( canAdd && selectedCategoryId ) &&
                    <AddNewModuleButton text="Add a new module"
                                        visitId={visitId}
                                        categoryIdx={selectedCategoryIdx}
                                        category={selectedCategoryId}
                                        modules={dataModules[selectedCategoryId].modules}
                                        data={data[selectedCategoryIdx].modules}
                                        addModule={addModule}
                    />
                    }
                </>
            }
            </div>
            </>
            }
        </div>
    )
}


