import { useState } from 'react'

import { Helmet } from 'react-helmet'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import {backendAccessGet, backendAccessPost} from '../Utils/Authentication'

export function ChangePassword( props )
{
    const [ oldPassword, setOldPassword ] = useState("")
    const [ newPassword, setNewPassword ] = useState("")
    const [ newPassword2, setNewPassword2 ] = useState("")

    const changePassword = () => {
        if( oldPassword == "" || newPassword == "" || newPassword2 == "" )
        {
            return
        }

        if( newPassword != newPassword2 )
        {
            window.alert("New passwords do not match.")
        }

        var data = {}
        data['old_password'] = oldPassword
        data['new_password'] = newPassword

        backendAccessPost("/api/settings/change-password/", data).then( res => {
            if( 'error_message' in res )
            {
                window.alert( res['error_message'] );
                return
            }

            setOldPassword("")
            setNewPassword("")
            setNewPassword2("")
        })
    }

    return (
        <div style={{width:'100%', padding:'10px'}}>
            <b>Change password</b>
            <div style={{ height:'100%', padding:'10px',
                          marginTop:'10px'}} >
                <Row style={{ marginBottom:'5px'}}>
                    <div style={{ width:'100%'}}>
                        <Form.Control type="password" value={oldPassword} 
                                      onChange={e => setOldPassword(e.target.value)}
                                      placeHolder="Old password"
                                      style={{ borderRadius:'0px', border:'none', 
                                               borderBottom:'0.2px solid #AEAEAE'}}
                                      />
                    </div>
                </Row>
                <Row style={{ marginBottom:'5px'}}>
                    <div style={{ width:'100%'}}>
                        <Form.Control type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)}
                                      placeHolder="New password"
                                      style={{ borderRadius:'0px', border:'none', 
                                               borderBottom:'0.2px solid #AEAEAE'}}
                                      />
                    </div>

                </Row>
                <Row style={{ marginBottom:'10px'}}>
                    <div style={{ width:'100%'}}>
                        <Form.Control type="password" value={newPassword2} onChange={e => setNewPassword2(e.target.value)}
                                      placeHolder="Re-enter new password"
                                      style={{ borderRadius:'0px', border:'none', 
                                               borderBottom:'0.2px solid #AEAEAE'}}
                                      />

                    </div>

                </Row>
                <Row>
                    <div style={{ width:'100%'}}>
                        <Button variant="secondary" onClick={changePassword}
                                style={{ borderRadius:'2px', float:'right' }} size="sm">Change password</Button>
                    </div>
                </Row>
            </div>
        </div>
    )
}

export default function Settings ()
{
    return (
        <>
            <div style={{ height: 'calc(100vh - 60px)', width:"100%"}}>
                <Container style={{ height:'100%', maxWidth:'500px', overflowY:'auto', padding:'10px'}}>
                    <ChangePassword />
                </Container>
            </div>
        </>
    )
}
