import Row from 'react-bootstrap/Row'
import MultiLineText from '../MultiLineText'

export default function Introduction( props )
{
    const language = props.language
    const template = props.template

    return (
        <Row>
            <MultiLineText text={template['introduction'][language]}/>
        </Row>
    )
}
