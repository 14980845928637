import { backendAccessPost } from '../../../Utils/Authentication'


export function id2var( data, temp )
{
    var out = {}

    for( var q_idx in temp['questions'] )
    {
        var q_index = parseInt(q_idx)+1
        var names = []

        var value = 0;
        for( var p_idx in temp['questions'][q_idx]['parts'] )
        {
            var t = temp['questions'][q_idx]['parts'][p_idx] 
            var k = q_index + "_" + p_idx;
            names.push( q_index + "_" + p_idx )

            if( t.answers.length > 0 )
            {
                var a_idx = data[k]
                
                if( !(a_idx == null && p_idx > 0) )
                {
                    value += parseFloat(t.answers[data[k]].value);
                }  
            }
            else
            {
                value += parseFloat( data[k] )
            }
        }

        out[temp['questions'][q_idx]['variable']] = value
    }

    return out
}

export function calculateScore( instanceData, template, setScores, setFormulas, setEvals, setInstanceData ) 
{
    var data = { data : instanceData,
                 template : template }

    backendAccessPost('/api/utils/calc-score/', data ).then( res => { 
        if( "error_message" in res )
        {
            alert( res["error_message"] )
            return
        }

        setScores( res.results ) 
        setFormulas( res.ffs )
        setEvals( res.evs ) 

        var d = {}
        d['values'] = instanceData.values
        d['scores'] = res.results

        setInstanceData(d)
    })
}

