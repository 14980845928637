import { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import QuestionnaireViewer from '../Tools/QuestionnaireViewer'
import { calculateScore } from '../Tools/QuestionnaireScoring'

export default function SurveyViewer( props )
{
    const moduleId = props.moduleId
    const instanceData = props.instanceData
    const setInstanceData = props.setInstanceData
    const template = props.template
    const version = props.version
    const status = props.status
    const reference = props.reference
    const createPlaceHolder = props.createPlaceHolder
    const dumpEmpty = props.dumpEmpty
    const reload = props.reload

    const [ data, setData ] = useState({})
    const [ languages, setLanguages ] = useState(null)
    const [ language, setLanguage ] = useState(null)
    const [ scores, setScores ] = useState(null)
    const [ formulas, setFormulas ] = useState(null)
    const [ evals, setEvals ] = useState(null)

    const load = ( template ) => {
        var d = { ...instanceData }

        if('values' in d )
        {
            setData(d['values'])
        }
        
        if('scores' in d )
        {
            setScores(d['scores'])
        }

        if( template != null )
        {
            setLanguages(Object.keys( template['title']))

            if( language == null )
            {
                setLanguage(template["primary_language"])
            }
        }
    }

    useEffect( () => { 
        load( template )
    }, [])

    useEffect( () => { 
        load( template )
    }, [ instanceData, template, version ] )

    const update = ( key, value ) => { 
        data[key] = value
        setData( { ...data } )

        var idata = { ...instanceData }
        idata['values'] = data

        setInstanceData( idata )
    }

    return (
        <div style={{ height:'100%', width:'100%', padding:'10px', overflowY:'auto' }}>
        { version == 0 &&
            <div className="d-flex justify-content-center">
                <div style={{marginRight:'3px'}}>
                    <Button variant="secondary" onClick={createPlaceHolder}>Fill in remote</Button>
                </div>
                <div style={{marginLeft:'3px'}}>
                    <Button variant="secondary" onClick={dumpEmpty}>Fill in here</Button>
                </div>
            </div>
        }

        { version > 0 &&
            <>
            { status == 1 &&
                <div>
                    <center>
                    <div>
                        <h3>Remote reference</h3>
                    </div>
                    <div>
                        <h1>{reference}</h1>
                    </div>
                    <div> 
                        <Button variant="secondary" onClick={reload}>Reload</Button>
                    </div>
                    </center>
                </div>
            }

            { ( language && status>1 ) &&
                <>
                <b>Language</b> 
                <div style={{ padding:'10px', borderRadius:'10px', backgroundColor:'#F5F5F5'}}>
                    <div style={{ maxWidth:'300px'}}>
                        <Form.Select value={language} onChange={ (e) => setLanguage( e.target.value )} size="sm">
                        { languages.map( (l,idx) => 
                            <option value={l}>{l}</option>
                        )}
                        </Form.Select>
                    </div>
                </div>
                <b>Score</b> 
                <div style={{padding:'10px', borderRadius:'10px', backgroundColor:'#F5F5F5'}}>
                    <Button variant="secondary" onClick={ e => calculateScore( instanceData, template, setScores, setFormulas, setEvals, setInstanceData )}  size="sm">Calculate Score</Button>
                    { scores != null &&
                        <>
                            { Object.keys(scores).map( (k,idx) => 
                                <div key={idx}>
                                    <b>{k}</b> : { scores[k] }
                                    { ( formulas && evals ) &&
                                        <>
                                            / { formulas[k] } / { evals[k] }
                                        </>
                                    }
                                </div>
                            )}
                        </>
                    }
                </div>

                <QuestionnaireViewer data={data}
                                     template={template}
                                     update={update}
                                     language={language} />
                </>
            }
            </>
        }

        </div>
    )
}
