import Row from 'react-bootstrap/Row'

export default function Title( props )
{
    const language = props.language
    const template = props.template

    return (
        <Row style={{ marginTop: "50px", marginBottom: "30px" }}>
            <center>
                <h2>{ template['title'][language] }</h2>
            </center>
        </Row>
    )
}
