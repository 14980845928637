import { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

export default function TodoCheckListViewer( props )
{
    const moduleId = props.moduleId
    const instanceData = props.instanceData
    const setInstanceData = props.setInstanceData
    const template = props.template

    const [ data, setData ] = useState({})

    useEffect( () => {
        
        var d = { ...instanceData }
        for( var tidx in template['array'] )
        {
            var t = template['array'][tidx]
            var varName = t.variable

            if( d[varName] == null )
            {
                d[varName] = false
            }
        }
        setData(d)
    }, [])

    const update = ( key, value ) => {
        data[key] = value
        setData( { ...data } )
        setInstanceData( {...data} )
    }

    return (
        <div style={{ height:'100%', width:'100%', padding:'10px', overflowY:'auto' }}>
        { template['array'] &&
        <>
        {template['array'].map( (t,idx) => 
            <Form.Check type='checkbox'
                        key={idx}
                        label={t.content}
                        checked={data[t.variable]}
                        onChange={ (e) => update( t.variable, e.target.checked ) }
                         />
        )}
        </>
        }

        </div>
    )
}
