import { Helmet } from 'react-helmet'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import { useState } from "react"
import { useNavigate } from 'react-router-dom';

import MenuButton from '../Components/UX/MenuButton';

export default function Home ()
{ 
    const navigate = useNavigate()
    return (
        <>
            <div style={{ height: 'calc(100vh - 60px)', width:"100%", backgroundColor:'#F6F6F6', top:''}}>
                <div className="d-flex align-items-center justify-content-center"
                     style={{ height: "100%", width: "100%" }}>
                    <div style={{ height:'280px', width:'300px' }}>
                        <div className="d-flex justify-content-center align-items-center fw-bolder" 
                             style={{ width:'100%', height:'30px', marginBottom:'10px', fontSize:12 }}>
                            ETD
                        </div>
                        <MenuButton title="Patients" onClick={ e => navigate("/patients")}/>
                        <MenuButton title="Biobank" onClick={ e => navigate("/biobank")}/>
                        <MenuButton title="Visit designer" onClick={ e => navigate("/visit-designer")}/>
                        <MenuButton title="Module builder" onClick={ e => navigate("/module-builder")}/>
                        <MenuButton title="Documentation" onClick={ e => navigate("/documentation")}/>
                        <MenuButton title="Downloads" onClick={ e => navigate("/downloads")}/>
                    </div> 
                </div>
            </div>

        </>
    )
}
