
import { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

export default function DNDListVertical ( props )
{
    const itemElement = props.itemElement

    const items = props.items 
    const reorder = props.reorder
    const direction = props.direction
    const Inner = props.Inner
    const innerParams = props.innerParams
    const idBuilder = props.idBuilder

    const getListStyle = isDraggingOver => { 

        let style = {
        //background: isDraggingOver ? 'lightblue' : 'lightgrey',
        display: 'flex',
        
        //padding: grid,
        //overflow: 'auto',
        }

        if( direction == "vertical" ) 
        {
            delete style['display']
        }

        return style;
    }

    const getItemStyle = (isDragging, draggableStyle) => {

        return ({
        // some basic styles to make the items look a bit nicer
        //userSelect: 'none',
        //margin: `0 ${grid}px 0 0`,

        // change background colour if dragging
        
        //background: isDragging ? 'lightgreen' : 'grey',

        // styles we need to apply on draggables
        ...draggableStyle,
    })
    }

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        reorder( items, result.source.index, result.destination.index )
    }

    return (
    <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppableMisc" direction={direction}>
          {(provided, snapshot) => (
            <div 
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {items.map((item, index) => (
                <Draggable key={idBuilder(index,item)} draggableId={idBuilder(index,item)} index={index}  >
                  {(provided, snapshot) => (
                    <div  
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                     
                    >
                      <Inner item={item} index={index} params={innerParams}/>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    )
}
