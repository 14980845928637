import { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

export function ScalarInput( props )
{
    const data = props.data
    const update = props.update
    const t = props.t

    return (
            <div className="d-flex" style={{width:'100%', marginBottom:'5px', padding:'5px'}}>
            { data &&
                <>
                <div className="d-flex align-items-center" 
                        style={{ width:'300px', float:'left'}}>
                        {t.title}
                </div>

                <div className="d-flex align-items-center" 
                     style={{ width:'300px', float:'left'}}>
                    <Form.Control type="text"
                              value={ (data[t.var] != null) ? data[t.var] : "" }
                              onChange={ e => update(t.var, e.target.value) }
                    />
                </div>
                </>
            }
            </div>
        )
}

export function TextInput( props )
{
    const moduleId = props.moduleId
    const data = props.data
    const update = props.update
    const t = props.t

    const [ text, setText ] = useState(null)

    const load = () => {
        if( data == null )
        {
            return
        }

        if( data[t.var] == null )
        {
            setText(null)
        }
        if( data[t.var] == text )
        {
            return
        }
        else
        {
            setText(data[t.var])
        }
    }

    useEffect( () => {
        load()
    }, [])

    useEffect( () => {
        load()
    }, [moduleId, data])

    useEffect( () => {
        if( text == null )
        {
            return
        }

        update( t.var, text )
    },[text] )


    return (
            <div className="d-flex" style={{width:'100%', marginBottom:'5px', padding:'5px'}}>
            { data &&
            <>
            <div className="d-flex" 
                        style={{ width:'300px', float:'left'}}>
                        {t.title}
                    </div>

            <div className="d-flex align-items-center"
                 style={{ width:'300px', float:'left'}}>
                <Form.Control type="text"
                              as="textarea"
                              value={text}
                              onChange={ e => setText(e.target.value) }
                              rows={3}
                />
            </div>
            </>
            }
            </div>
        )
}

export function ChoiceInput( props )
{
    const moduleId = props.moduleId
    const data = props.data
    const update = props.update
    const t = props.t

    return (
        <div className="d-flex" style={{width:'100%', marginBottom:'5px', padding:'5px'}}>
            { data &&
            <>
            <div className="d-flex align-items-center" 
                        style={{ width:'300px', float:'left'}}>
                        {t.title}
            </div>

            <div className="d-flex align-items-center"
                 style={{ width:'300px', float:'left'}}>
                <Form.Select value={(data[t.var] != null) ? data[t.var] : ""} onChange={ e => update( t.var, e.target.value )}>
                    <option value={""}> -- Select -- </option>
                    { t['options'].map( (opt,idx) =>
                        <option value={t['values'][idx]}>{opt}</option>
                    )}
                </Form.Select>
            </div>
            </> 
            }
        </div>
    )
}

export function CheckListInput( props )
{
    const moduleId = props.moduleId
    const data = props.data
    const update = props.update
    const t = props.t

    const [ selections, setSelections ] = useState(null)

    const load = () => {
        if( data == null )
        {
            return
        }

        if( data[t.var] == null )
        {
            setSelections({})
        }
        else if( data[t.var].selections == null )
        {
            setSelections({})
        }
        else
        {
            setSelections(data[t.var].selections)
        }
    }

    useEffect( () => {
        load()
    }, [])
    useEffect( () => {
        load()
    }, [moduleId])

    useEffect( () => {
        if( data == null )
        {
            return
        }

        if( data[t.var] == null )
        {
            return
        }

        if( JSON.stringify( data[t.var].selections ) != JSON.stringify( selections ) )
        {
            load()
        }
    }, [data])

    useEffect( () => {
        if( selections == null )
        {
            return
        }
        var should_update = false
        var res = {}

        if( selections != null && Object.keys( selections ).length > 0 )
        {
            res['selections'] = selections
            should_update = true
        }

        if( should_update )
        {
            update( t.var, res )
        }
    },[selections] )

    const updateChecks = ( name, value ) => {
        var s = structuredClone( selections )
        s[name] = value
        setSelections( s )
    }


    return (
        <div className="d-flex" style={{width:'100%', marginBottom:'5px', padding:'5px'}}>
            { selections &&
            <>
            <div className="d-flex" 
                        style={{ width:'300px', float:'left'}}>
                        {t.title}
            </div>
            { selections != null &&
            <div style={{ width:'300px', float:'left'}}>
                 { t['options'].map( (opt,idx) => 
                     <div style={{ width: '100%'}}>
                        <Form.Check type="checkbox"
                                    checked={ ( selections[opt] == null ) ? false : selections[opt] }
                                    label={opt}
                                    onChange={ (e) => updateChecks( opt, e.target.checked )}
                                    style={{fontWidth:'bold'}}
                     />
                     </div>

                 )}
            </div>
            }
            </>
            }
        </div>
    )
}

export function CheckboxInput( props )
{
    const moduleId = props.moduleId
    const data = props.data
    const update = props.update
    const t = props.t

    const [ checked, setChecked ] = useState(null)

    const load = () => {
        if( data == null )
        {
            return
        }

        if( data[t.var] != null && data[t.var].checked != checked )
        {
            setChecked( data[t.var].checked )
        }

    }

    useEffect( () => {
        load()
    }, [])

    useEffect( () => {
        load()
    }, [data])

    useEffect( () => {
        if( checked != null )
        {
            var res = {}
            res['checked'] = checked
            update( t.var, res )
        }
    },[checked] )
    
    return (
            <div className="d-flex" style={{width:'100%', marginBottom:'5px', padding:'5px'}}>

            { checked &&
            <>
            <div className="d-flex align-items-center" 
                        style={{ width:'300px', float:'left'}}>
            </div>
            <div className="d-flex align-items-center"
                 style={{ width:'300px', float:'left'}}>
                <Form.Check type="checkbox"
                            label={t.name}
                            checked={(checked != null) ? checked : false}
                            onChange={ (e) => setChecked( e.target.checked ) }
                            style={{fontWidth:'bold'}}
                />
            </div>
            </>
            }
            </div>
        )
}

export function GroupInput( props )
{
    const moduleId = props.moduleId
    const data = props.data
    const update = props.update
    const t = props.t

    const [ localData, setLocalData ] = useState( {} )

    const localUpdate = ( key, value ) => {
        var l = structuredClone(localData)
        l[key] = value
        setLocalData(l)
    }

    const load = () => {
        if( data == null )
        {
            return
        }

        if( data[t.var] != null )
        {
            setLocalData( data[t.var] )
        }

    }

    useEffect( () => {
        load()
    }, [])

    useEffect( () => {
        load()
    }, [moduleId])
 
    useEffect( () => {
        if( localData != null && Object.keys(localData).length > 0 )
        {
            update( t.var, localData )
        }
    }, [localData] )

    return (
        <>
        <div className="d-flex" style={{width:'100%', marginBottom:'5px', padding:'5px'}}>
        <div className="d-flex" 
        style={{ width:'300px', float:'left'}}>
        {t.title}
        </div>
        </div>


        { localData &&
            <>
            { t.modules.map( (m,idx) => 
                <InputSelect moduleId={moduleId}
                data={localData}
                update={localUpdate}
                t={m}
                />
            )}
            </>
        }
        </>
    )

}

export default function InputSelect( props )
{
    const moduleId = props.moduleId
    const data = props.data
    const update = props.update
    const t = props.t

    if( data == null )
    {
        return (
            <></>
        )
    }

    if( t.type == 'scalar' )
    {
        return (
            <ScalarInput data={data}
                         update={update}
                         t={t}
            /> 
        )
    }
    else if( t.type == 'choice' )
    {
        return (
            <ChoiceInput data={data}
                         update={update}
                         t={t}
            /> 
        )
    }
    else if( t.type == 'checklist' )
    {
        return (
            <CheckListInput moduleId={moduleId}
                            data={data}
                            update={update}
                            t={t}
            /> 
        )
    }
    else if( t.type == 'textarea' )
    {
        return (
            <TextInput moduleId={moduleId}
                       data={data}
                       update={update}
                       t={t}
            /> 
        )

    }
    else if( t.type == 'checkbox' )
    {
        return (
            <CheckboxInput  moduleId={moduleId}
                            data={data}
                            update={update}
                            t={t}
            /> 
        )
    }
    else if( t.type == "group" )
    {
        return (
            <GroupInput moduleId={moduleId}
                        data={data}
                        update={update}
                        t={t}
            />
        )
    }
    
    return <></>
}

