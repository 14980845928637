import { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import InputSelect from '../Tools/InputFields'

export default function LabAnalysisViewer( props )
{
    const moduleId = props.moduleId
    const instanceData = props.instanceData
    const setInstanceData = props.setInstanceData
    const template = props.template

    const [ data, setData ] = useState(null)

    useEffect( () => {
        
        var d = { ...instanceData }
        for( var tidx in template )
        {
            var t = template[tidx]
            var varName = t.var
            var type = t.type

            if( d[varName] == null )
            {
                d[varName] = null
            }
        }

        setData(d)
    }, [])

    const update = ( key, value ) => {
        data[key] = value
        setData( { ...data } )
        setInstanceData( {...data} )
    }

    return (
        <div style={{ height:'100%', width:'100%', padding:'10px', overflowY:'auto' }}>
            { template.map( (t,idx) => 
                <InputSelect moduleId={moduleId}
                             data={data}
                             update={update}
                             t={t}/>
            )}
        </div>
    )
}
