import { Helmet } from 'react-helmet'
import Container from 'react-bootstrap/Container'
import {useLocation} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react'
import {backendAccessGet, backendAccessPost} from '../../Utils/Authentication'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Modal from 'react-bootstrap/Modal'
import { GiHamburgerMenu } from "react-icons/gi";
import { MdAdd } from "react-icons/md";
import { TbTrashXFilled } from "react-icons/tb";
import { FiEdit } from "react-icons/fi";

import DataModuleSelector from '../../Components/Visits/DataModuleSelector'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { FaArrowRight } from "react-icons/fa";
import { FaRegCircle } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import { IoMdRefresh } from "react-icons/io";

import DataModuleViewer from '../../Components/Visits/DataModuleViewer';
import VisitEditor from '../../Components/Visits/VisitEditor';

import ModuleSelector from '../../Components/Misc/ModuleSelector';


import { build_column_base_style, build_header_style } from '../../Components/Visits/Styles'

export function SurveyActionButton( props )
{
    const patientId = props.patientId 
    const visitId = props.visitId 
    const groupIdx = props.groupIdx
    const modules = props.modules 
    const setDataModules = props.setDataModules
    const loadDataModules = props.loadDataModules

    const orderSurveys = () => {
        let data = {}
        data.patient_id = patientId 
        data.visit_id = visitId 
        data.group_idx = groupIdx 

        backendAccessPost('/api/patients/visits/order-surveys/', data).then( res => {
            if( res.error_message )
            {
                alert( res.error_message )
                return
            }

            setDataModules( res.data_modules )

        })
    }

    if( modules.status == null )
    {
        return (
            <div style={{ color:'#0d6efd', cursor:'pointer'}}
                 onClick={orderSurveys}>
                Fill all in remote
            </div>
        )
    }
    else if( modules.status == 0 ) 
    {
        return (
            <div style={{ width:'135px' }}>
                <div style={{ float:'left'}}>Reference: <b>{ modules.reference }</b></div> 
                <div style={{ float:'right'}}>
                    <IoMdRefresh style={{ color:'#0d6efd', 
                                          fontSize:'16px', 
                                          top:"-5px" }} 
                                 onClick={loadDataModules}
                    />
                </div>
            </div>
        )
    }
    else 
    {
        return (
            <>
            </>
        )
    }
}

export function VisitModuleList( props )
{
    const patientId = props.patientId 
    const visitId = props.visitId 

    const moduleId = props.moduleId 
    const setModuleId = props.setModuleId

    const dataModules = props.dataModules
    const setDataModules = props.setDataModules

    const dataModulesStatus = props.dataModulesStatus
    const setDataModulesStatus = props.setDataModulesStatus

    const dataModule = props.dataModule
    const setDataModule = props.setDataModule

    const categories = props.categories

    //const [ dataModules, setDataModules ] = useState(null)

    const loadDataModules = () => {
        var data = {}
        data['patient_id'] = patientId
        data['visit_id'] = visitId

        backendAccessPost('/api/patients/visits/data-modules/', data).then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                return
            }

            setDataModules( res["data_modules"] )
            setDataModulesStatus( res.modules_status )
        })
    }

    useEffect( () => {
        loadDataModules()
    }, [visitId] )
    
    const selectModule = ( module ) => {
        setModuleId( module.id )
        setDataModule( module )
    }

    return (
        <div>
            { ( dataModules && dataModulesStatus ) &&
                <div>
                { dataModules.map( (group,gidx) => 
                    <div style={{ backgroundColor:'#F6F6F6', marginBottom:'10px',
                              borderRadius:'2px', padding:'10px'}}>
                        <div style={{ paddingLeft:'5px', marginBottom:'5px', height:'40px'}}>
                            <div className="d-flex align-items-center" style={{ height:'100%', float:'left', marginRight:'20px' }}>
                                <b>{ group.category_name }</b>
                            </div>
                            { group.category == "survey" &&
                            <div className="d-flex align-items-center" style={{ height:'100%', float:'left'}}>
                                <SurveyActionButton patientId={patientId} visitId={visitId} groupIdx={gidx} modules={group}
                                                    setDataModules={setDataModules}
                                                    loadDataModules={loadDataModules}
                                />
                            </div>
                            }
                        </div>
                        { group.modules.map( (module,midx) => 
                            <div style={{ backgroundColor:( module.id == moduleId ) ? '#000000':'#FFFFFF', 
                                          color: ( module.id != moduleId ) ? '#000000':'#FFFFFF',
                                          marginBottom:'5px', padding:'8px',
                                          borderRadius:'2px',
                                          width:'100%',
                                          height:'35px'}}
                                 onClick={ e => selectModule(module) }>
                                <div className="d-flex align-items-center" style={{ float:'left', height:'100%', marginRight:'5px' }}>
                                    <ModuleSelector check={dataModulesStatus[module.id]}
                                                    childFalse={<FaRegCircle />}
                                                    childTrue={<FaCircle style={{ color:'#0d6efd'}}/>}
                                    /> 
                                </div>
                                <div className="d-flex align-items-center" style={{ float:'left', height:'100%'}}>
                                { module.name }
                                </div>
                                { moduleId == module.id && 
                                <div className="d-flex align-items-center" style={{ float:'right', height:'100%'}}>
                                    <FaArrowRight />
                                </div>
                                }
                            </div>
                        )}
                    </div>
                )}
                </div>
            }
        </div>
    )
}

export function VisitEditorModalBody( props )
{
    const visitModules = props.visitModules 
    const visitId = props.visitId
    const categories = props.categories 
    const dataModules = props.dataModules

    const saveClose = props.saveClose 
    const discardClose = props.discardClose

    const [ data, setData ] = useState( null )
    const dataRef = useRef(null)
    const [ hasChange, setHasChange ] = useState(false)

    useEffect( () => {
        dataRef.current = JSON.stringify( visitModules )
        setData( structuredClone(visitModules) )
        setHasChange(false)
    },[])

    useEffect( () => {
        let localJson = JSON.stringify(data)

        if( localJson == dataRef.current )
        {
            setHasChange( false )
        }
        else 
        {
            setHasChange( true )
        }
    },[data])

    const saveCloseEditVisit = () => {
        if( hasChange )
        {
            saveClose( data )
        }

        discardClose()
    }
    const discardCloseEditVisit = () => {
        discardClose()
    }

    
    return (
        <>
            <Modal.Body style={{ height:'600px', overflowY:'scroll',  backgroundColor:'#F6F6F6', fontSize:12}}>
            { data &&
                <VisitEditor data={data} setData={setData} visitId={visitId} hasChange={hasChange}
                             categories={categories} dataModules={dataModules} canAdd={true}/>
            }
            </Modal.Body>
            <Modal.Footer>
                <Button variant={ (hasChange) ? "danger" : "primary" } onClick={ saveCloseEditVisit }>Save</Button>
                <Button onClick={ discardCloseEditVisit }>Close</Button>
            </Modal.Footer>
        </>
    )

}

export default function PatientVisit ( props )
{
    //const location = useLocation();
    //const [ patientId, setPatientId ] = useState( null )
    const patientId = props.patientId
    const setPatientId = props.setPatientId
    const [ patientInfo, setPatientInfo ] = useState(null)

    const [ visitTemplates, setVisitTemplates ] = useState(null)
    const [ visitsList, setVisitsList ] = useState(null)

    const [ visitModules, setVisitModules ] = useState(null)
    const [ vistModulesStatus, setVisitModulesStatus ] = useState(null)

    const [ selectedVisitIdx, setSelectedVisitIdx ] = useState(-1)
    const [ selectedVisitId, setSelectedVisitId ] = useState(null)
    const [ selectedVisitName, setSelectedVisitName ] = useState(null)

    const [ moduleId, setModuleId ] = useState(null)
    const [ dataModule, setDataModule ] = useState(null)
    const [ categories, setCategories ] = useState(null)
    const [ dataModules, setDataModules ] = useState(null)


    const [ newVisitIdx, setNewVisitIdx ] = useState(null)

    const [ showAddVisitModal, setShowAddVisitModal ] = useState(false)
    const [ selectedAddVisitId, setSelectedAddVisitId ] = useState(null)

    const [ showVisitEditor, setShowVisitEditor ] = useState(false)

    const toolHeight = '50px'

    useEffect( () => {
        //var patient_id = location.state.pid
        
        let data = {}
        data['patient_id'] = patientId;
        backendAccessPost( '/api/patients/visit-view-data/', data ).then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                return
            }

            //setPatientId(patientId)
            setPatientInfo(res["patient_info"])
            setVisitTemplates(res["visit_templates"])
            setCategories(res["category_names"])
            setDataModules(res["available_modules"])
            setVisitsList(res["visits_list"])
        })
    }, [] )

    useEffect( () => {
        if( selectedVisitIdx >= 0 )
        {
            var visit_id = visitsList[selectedVisitIdx].visit_id
            var visit_name = visitsList[selectedVisitIdx].visit_name
            setSelectedVisitId( visit_id )
            setSelectedVisitName( visit_name )
        }
        else
        {
            setSelectedVisitId(null)
            setSelectedVisitName(null)
        }
    },[selectedVisitIdx])

    const closeVisit = () => {
        setPatientId(null)
    }

    const createNewVisit = () => {
        if( newVisitIdx && newVisitIdx >= 0 )
        {
            if( window.confirm("Create a new visit?") )
            {
                var visit_id = visitTemplates[newVisitIdx].visit_id
                var data = {}
                data['patient_id'] = patientId
                data['visit_template_id'] = visit_id
                backendAccessPost('/api/patients/visits/add/', data).then( res => {
                    if( "error_message" in res )
                    {
                        alert( res["error_message"] )
                        {
                            return;
                        }
                    }

                    setVisitsList( res["visits_list"] )
                    setNewVisitIdx(-1)
                    setSelectedVisitIdx( res["visits_list"].length-1 )
                })
            }
        }
    }

    const removeVisit = ( patientId, visitId ) => {
        
        var data = {}
        data['patient_id'] = patientId 
        data['visit_id'] = visitId

        backendAccessPost('/api/patients/visits/remove/', data).then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                {
                    return;
                }
            }

            setSelectedVisitId(null)
            setSelectedVisitName(null)
            setSelectedVisitIdx(-1)
            setVisitsList( res["visits_list"])

        })
    }

    const removeVisitConfirm = () => {
        if( selectedVisitId == null )
        {
            return
        }

        if( window.confirm(`Do you want to remove visit ${selectedVisitName}?`) )
        {
            removeVisit( patientId, selectedAddVisitId )
        }
    }

    useEffect( () => {
        if( selectedVisitIdx == -1 )
        {
            setSelectedVisitId(null)
            setSelectedVisitName(null)
            return
        }

        setSelectedVisitName( visitsList[selectedVisitIdx].visit_name )
        setSelectedVisitId( visitsList[selectedVisitIdx].visit_id )

    },[selectedVisitIdx])

    const getDateTimestamp = ( dateStr ) => {
        let date = new Date(dateStr)
    
        return date.toISOString().split('T')[0]
    }

    const openAddVisitModal = () => {
        setSelectedAddVisitId( null )
        setShowAddVisitModal( true )
    }

    const addNewVisit = () => {
        var data = {}
        data['patient_id'] = patientId
        data['visit_template_id'] = selectedAddVisitId

        backendAccessPost('/api/patients/visits/add/', data).then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                {
                    return;
                }
            }

            setVisitsList( res["visits_list"] )
            setNewVisitIdx(-1)
            setSelectedVisitIdx( res["visits_list"].length-1 )
            setShowAddVisitModal( false )    
        })
    }

    const openEditVisit = () => {
        setShowVisitEditor( true )
    }

    const saveCloseEditVisit = ( data ) => {

        let dd = {}
        dd['patient_id'] = patientId 
        dd['visit_id'] = selectedVisitId 
        dd['data_modules'] = data

        backendAccessPost('/api/patients/visits/update-data-modules/', dd ).then( res => {
            if( 'error_message' in res )
            {
                alert( res.error_message )
            }

            setVisitModules( data )
            setShowVisitEditor( false )
        })
    }

    return (
        <Container style={{ maxWidth:'1400px', height:'100%', fontSize:12, padding:'5px'}}>
            <div className='d-flex align-items-center' style={{ width:'100%', height:'75px', paddingLeft:'20px'}}>
                { patientInfo &&
                <div>
                    Patient ID : <b>{ patientInfo["patient_id"] }</b><br />
                    Registeration Date :<b>{ getDateTimestamp( patientInfo['timestamp']) }</b><br />
                    Year of birth :<b>{ patientInfo["year_of_birth"]}</b><br />
                    Gender:<b>{patientInfo["gender"]}</b><br />
                </div>
                }
            </div>
            { visitsList &&
            <div style={{ width:'100%', height:'calc(100% - 75px)'}}>
                <div style={{ height:'100%', width:'400px', float:'left' }}>
                    <div className="d-flex align-items-center" 
                         style={{width:'100%', height:toolHeight, padding:'10px' }}>

                        
                        <Dropdown style={{ width:'100%', marginRight:'5px'}} align="start">

                            <Dropdown.Toggle style={{ width:'100%', borderRadius:'2px', backgroundColor:'#FFF', color:'#000',
                                                      border:'1px solid #DADADA'}} variant="secondary">
                                { selectedVisitName &&
                                <>{selectedVisitIdx+1} - {selectedVisitName}</>
                                }
                                { !selectedVisitName &&
                                <>Select a visit</>
                                }
                            </Dropdown.Toggle>
                            
                            <Dropdown.Menu style={{ width:'100%', fontSize:12 }}>
                            { visitsList.map( (visit,idx) => 
                                <Dropdown.Item eventKey={idx} onClick={ e => setSelectedVisitIdx(idx)}>
                                    <b>{ visit.visit_name }</b> <br />
                                    {getDateTimestamp(visit.timestamp)}
                                </Dropdown.Item>
                                
                            )}
                            </Dropdown.Menu>

                        </Dropdown>                       
                            <Button variant="outline-secondary" onClick={openAddVisitModal} 
                                    style={{ marginRight:'5px', borderRadius:'2px', border:'1px solid #DADADA' }}><MdAdd /></Button>
                            <DropdownButton title={<GiHamburgerMenu />} variant="outline-secondary" style={{ borderRadius:'2px'}}>
                                <Dropdown.Item onClick={openEditVisit}><FiEdit />Edit Visit</Dropdown.Item>
                                <Dropdown.Item onClick={removeVisitConfirm}>
                                    <TbTrashXFilled style={{ top:-10 }}/>Remove Visit
                                </Dropdown.Item>
                            </DropdownButton>
                    </div>
                    <div style={{ width:'100%', height:`calc(100% - ${toolHeight})`, padding:'10px'}}>
                    <div style={{ width:'100%', height:'100%',
                                  overflowY:'scroll' }}>
                        { selectedVisitId &&
                            <VisitModuleList patientId={patientId}
                                             visitId={selectedVisitId}
                                             moduleId={moduleId}
                                             dataModules={visitModules}
                                             setDataModules={setVisitModules}
                                             dataModulesStatus={vistModulesStatus}
                                             setDataModulesStatus={setVisitModulesStatus}
                                             setModuleId={setModuleId}
                                             setDataModule={setDataModule}
                                             categories={categories}
                            />
                        }
                    </div>
                    </div>
                </div>
                <div style={{ height:'100%', width:'calc(100% - 400px)', float:'right' }}> 
                { ( patientId && selectedVisitId && dataModule ) &&
                    <DataModuleViewer patientId={patientId}
                                      visitId={selectedVisitId}
                                      dataModule={dataModule}
                                      toolHeight={toolHeight}
                                      setDataModulesStatus={setVisitModulesStatus}
                    />
                }
                </div>
            </div>
            }
            <Modal show={showAddVisitModal} onHide={ e => setShowAddVisitModal(false) }>
                <Modal.Header closeButton>
                    <Modal.Title>New visit</Modal.Title>
                </Modal.Header>
                { visitTemplates &&
                <Modal.Body style={{ height:'300px', overflowY:'scroll',  backgroundColor:'#F6F6F6', fontSize:12}}>
                    { visitTemplates.map( (visit,idx) => 
                        <div style={{ backgroundColor:( visit.visit_id == selectedAddVisitId ) ? '#000000':'#FFFFFF', 
                                          color: ( visit.visit_id != selectedAddVisitId ) ? '#000000':'#FFFFFF',
                                          marginBottom:'5px', padding:'8px',
                                          borderRadius:'2px',
                                          width:'100%',
                                          height:'35px'}}
                                 onClick={ e => setSelectedAddVisitId(visit.visit_id) }>

                            { visit.visit_name }
                        </div>
                    )}
                </Modal.Body>
                }
                <Modal.Footer>
                    <Button onClick={ addNewVisit }>Add</Button>
                    <Button onClick={ e => setShowAddVisitModal(false) }>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal size="xl" show={showVisitEditor}>
                <Modal.Header>
                </Modal.Header>
                { showVisitEditor &&
                <VisitEditorModalBody visitModules={visitModules} 
                                      visitId={selectedVisitId} 
                                      categories={categories}
                                      dataModules={dataModules}
                                      saveClose={ saveCloseEditVisit }
                                      discardClose={ e => setShowVisitEditor(false) }
                />
                }
            </Modal>

        </Container>
    )
}

