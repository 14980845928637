import { useEffect, useState } from "react";

import DNDListVertical from "../Misc/DNDListVertical";
import InputSelect from "./Tools/InputFields";

import Button from "react-bootstrap/Button"
import { IoCopyOutline } from "react-icons/io5";
import { ImCross } from "react-icons/im";
import { ImPlus } from "react-icons/im";

import Form from 'react-bootstrap/Form'
import SurveyEditor from "../SurveyTools/SurveyEditor";
import ModuleSelector from "../Misc/ModuleSelector";
import BlackActionButton from "../UX/BlackActionButton";

export function NoCustomization( props )
{
    const hasChange = props.hasChange

    return (
        <div style={{ height:'100%', width:'100%' }}>
            <div style={{ width:'100%', height:'50px', padding:'5px',  }}>
            </div>
            <div style={{ width:'100%', height:'calc(100% - 50px)', padding:'5px'}}>
                 <div className="d-flex justify-content-center align-items-center"
                      style={{ height:'100%', width:'100%', 
                               border: (hasChange) ? '1px solid #DC4C64' : '1px solid #0057FF', 
                               borderRadius:'2px',
                               padding:'20px' }}
                 >
                    This module is not configurable
                 </div>
            </div>
        </div>
    )
}

export function BasicInfoButton( props )
{
    const item = props.item
    const index = props.index 

    const selectedIdx = props.params.selectedIdx
    const onClick = props.params.onClick 
    const onRemove = props.params.onRemove 
    const canRemove = props.params.canRemove

    return (
        <div className="d-flex align-items-center" 
             style={{ backgroundColor:( selectedIdx == index ) ? '#000000':'#FFFFFF', 
                      color: ( selectedIdx != index ) ? '#000000':'#FFFFFF',
                      border: ( selectedIdx == index ) ? '0.2px solid' : '0.2px dashed',
                      height:'35px', width:'100%', borderRadius:'2px', marginBottom:'5px', padding:'8px',
                      cursor:'pointer' }}
             onClick={ e => onClick( index, item ) }
        >
            <div style={{ marginLeft:'5px', width:'100%', height:'100%'}}>
                { canRemove &&
                <div className="d-flex align-items-center" 
                     style={{ height:'100%', float:'left' }}
                     onClick={e => onRemove(index)} >
                    <ImCross />
                </div>
                }
                <div style={{ height:'100%', marginLeft:'10px', float:'left'}}>
                    {item.name}
                </div>
            </div>
        </div>
    )
}

export function TodoChecklistButton( props )
{
    const item = props.item
    const index = props.index 

    const selectedIdx = props.params.selectedIdx
    const onClick = props.params.onClick 
    const onRemove = props.params.onRemove 
    const canRemove = props.params.canRemove

    return (
        <div className="d-flex align-items-center" 
             style={{ backgroundColor:( selectedIdx == index ) ? '#000000':'#FFFFFF', 
                      color: ( selectedIdx != index ) ? '#000000':'#FFFFFF',
                      border: ( selectedIdx == index ) ? '0.2px solid' : '0.2px dashed',
                      height:'35px', width:'100%', borderRadius:'2px', marginBottom:'5px', padding:'8px',
                      cursor:'pointer' }}
             onClick={ e => onClick( index, item ) }
        >
            <div style={{ marginLeft:'5px', width:'100%', height:'100%'}}>
                <div className="d-flex align-items-center" 
                     style={{ height:'100%', float:'left' }}
                     onClick={e => onRemove(index)} >
                    <ImCross />
                </div>
                <div style={{ height:'100%', marginLeft:'10px', float:'left'}}>
                    {item.content}
                </div>
            </div>
        </div>
    )

}

export function BasicInformation( props )
{
    const moduleId = props.moduleId
    const moduleVersion = props.moduleVersion
    const moduleData = props.moduleData 
    const setModuleData = props.setModuleData 
    const hasChange = props.hasChange
    const createWithId = props.createWithId
    const canRemove = props.canRemove

    const [ selectedIdx, setSelectedIdx ] = useState(-1)

    const data = {}

    useEffect( () => {
        setSelectedIdx(-1)
    }, [moduleId] )
    
    const idBuilder = ( index, item ) => {
        return item.var + index 
    }

    const onReorder = ( list, startIndex, endIndex ) => {
        var l = structuredClone(moduleData)
        const [removed] = l.splice(startIndex, 1);
        l.splice(endIndex, 0, removed);

        setModuleData(l)
        setSelectedIdx(endIndex)
    }

    const onRemove = ( index ) => {
        var l = structuredClone(moduleData)
        const [removed] = l.splice(index, 1); 
        setModuleData(l)
    }

    const onClick = ( index, item ) => {
        setSelectedIdx(index)
    }

    const update = ( key, value ) => {
    }

    return (
        <div style={{ height:'100%', width:'100%' }}>
            <div style={{ width:'100%', height:'50px', padding:'5px'  }}>
            </div>
            <div style={{ width:'100%', height:'calc(100% - 50px)', padding:'5px'}}>
                <div style={{ height:'100%', width:'100%', 
                               border: (hasChange) ? '1px solid #DC4C64' : '1px solid #0057FF', 
                               borderRadius:'2px',
                               padding:'10px' }}>
                    <div style={{ width:'40%', height:'100%', float:'left', padding:'10px', overflowY:'scroll'  }}>
                        <div className="fw-bolder" style={{ width:'100%', height:'40px', marginBottom:'5px'}} >
                            <div className="d-flex justify-content-start align-items-center"
                                 style={{ height:'100%', width:'100px', float:'left'}}>Version : {moduleVersion}
                            </div>
                            <div className="d-flex justify-content-end align-items-center"
                                 style={{ height:'100%', width:'230px', float:'right'}}>
                                { createWithId &&
                                <BlackActionButton onClick={ e => createWithId(moduleId) }>
                                    <div style={{ float:'left', marginRight:'5px' }}>
                                        <IoCopyOutline />
                                    </div>
                                    <div style={{ float:'left' }}>Create new from this module</div>
                                </BlackActionButton>
                                }
                            </div>
                        </div>
                        <DNDListVertical items={moduleData}
                                         direction="vertical"
                                         reorder={onReorder}
                                         Inner={BasicInfoButton}
                                         innerParams={{ selectedIdx: selectedIdx, 
                                                'onClick': onClick,
                                                'onRemove' : onRemove,
                                                'canRemove' : canRemove
                                              }}
                                         idBuilder={idBuilder}
                        />  
                    </div>
                    <div style={{ width:'60%', height:'100%', float:'right', padding:'10px', overflowY:'scroll' }}>
                        <div className="d-flex align-items-center fw-bolder"  
                             style={{ width:'100%', height:'40px', marginBottom:'5px'}} >
                            <div style={{ marginLeft:'10px'}}>Item render</div>
                        </div>
                        { ( selectedIdx >= 0 && moduleData[selectedIdx] ) &&
                        <InputSelect moduleId={selectedIdx}
                                     data={data}
                                     update={ update }
                                     t={moduleData[selectedIdx]} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Biobank( props )
{
    const moduleId = props.moduleId
    const moduleVersion = props.moduleVersion
    const moduleData = props.moduleData 
    const setModuleData = props.setModuleData 
    const hasChange = props.hasChange
    const createWithId = props.createWithId
    const canRemove = props.canRemove

    const [ selectedIdx, setSelectedIdx ] = useState(-1)

    const data = {}

    useEffect( () => {
        setSelectedIdx(-1)
    }, [moduleId] )
    
    const idBuilder = ( index, item ) => {
        return item.var + index 
    }

    const onReorder = ( list, startIndex, endIndex ) => {
        var l = structuredClone(moduleData)
        const [removed] = l.splice(startIndex, 1);
        l.splice(endIndex, 0, removed);

        setModuleData(l)
        setSelectedIdx(endIndex)
    }

    const onRemove = ( index ) => {
        var l = structuredClone(moduleData)
        const [removed] = l.splice(index, 1); 
        setModuleData(l)
    }

    const onClick = ( index, item ) => {
        setSelectedIdx(index)
    }

    const update = ( key, value ) => {
    }

    return (
        <div style={{ height:'100%', width:'100%' }}>
            <div style={{ width:'100%', height:'50px', padding:'5px'  }}>
            </div>
            <div style={{ width:'100%', height:'calc(100% - 50px)', padding:'5px'}}>
                <div style={{ height:'100%', width:'100%', 
                               border: (hasChange) ? '1px solid #DC4C64' : '1px solid #0057FF', 
                               borderRadius:'2px',
                               padding:'10px' }}>
                    <div style={{ width:'40%', height:'100%', float:'left', padding:'10px', overflowY:'scroll'  }}>
                        <div className="fw-bolder" style={{ width:'100%', height:'40px', marginBottom:'5px'}} >
                            <div className="d-flex justify-content-start align-items-center"
                                 style={{ height:'100%', width:'100px', float:'left'}}>Version : {moduleVersion}
                            </div>
                            <div className="d-flex justify-content-end align-items-center"
                                 style={{ height:'100%', width:'230px', float:'right'}}>
                                { createWithId &&
                                <BlackActionButton onClick={ e => createWithId(moduleId) }>
                                    <div style={{ float:'left', marginRight:'5px' }}>
                                        <IoCopyOutline />
                                    </div>
                                    <div style={{ float:'left' }}>Create new from this module</div>
                                </BlackActionButton>
                                }
                            </div>
                        </div>
                        <DNDListVertical items={moduleData}
                                         direction="vertical"
                                         reorder={onReorder}
                                         Inner={BasicInfoButton}
                                         innerParams={{ selectedIdx: selectedIdx, 
                                                'onClick': onClick,
                                                'onRemove' : onRemove,
                                                'canRemove' : canRemove
                                              }}
                                         idBuilder={idBuilder}
                        />  
                    </div>
                    <div style={{ width:'60%', height:'100%', float:'right', padding:'10px', overflowY:'scroll' }}>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function LabAnalysis( props )
{
    const moduleId = props.moduleId
    const moduleVersion = props.moduleVersion
    const moduleData = props.moduleData 
    const setModuleData = props.setModuleData 
    const hasChange = props.hasChange
    const createWithId = props.createWithId
    const canRemove = props.canRemove

    const [ selectedIdx, setSelectedIdx ] = useState(-1)

    const data = {}

    useEffect( () => {
        setSelectedIdx(-1)
    }, [moduleId] )
    
    const idBuilder = ( index, item ) => {
        return item.var + index 
    }

    const onReorder = ( list, startIndex, endIndex ) => {
        var l = structuredClone(moduleData)
        const [removed] = l.splice(startIndex, 1);
        l.splice(endIndex, 0, removed);

        setModuleData(l)
        setSelectedIdx(endIndex)
    }

    const onRemove = ( index ) => {
        var l = structuredClone(moduleData)
        const [removed] = l.splice(index, 1); 
        setModuleData(l)
    }

    const onClick = ( index, item ) => {
        setSelectedIdx(index)
    }

    const update = ( key, value ) => {
    }

    return (
        <div style={{ height:'100%', width:'100%' }}>
            <div style={{ width:'100%', height:'50px', padding:'5px'  }}>
            </div>
            <div style={{ width:'100%', height:'calc(100% - 50px)', padding:'5px'}}>
                <div style={{ height:'100%', width:'100%', 
                               border: (hasChange) ? '1px solid #DC4C64' : '1px solid #0057FF', 
                               borderRadius:'2px',
                               padding:'10px' }}>
                    <div style={{ width:'40%', height:'100%', float:'left', padding:'10px', overflowY:'scroll'  }}>
                        <div className="fw-bolder" style={{ width:'100%', height:'40px', marginBottom:'5px'}} >
                            <div className="d-flex justify-content-start align-items-center"
                                 style={{ height:'100%', width:'100px', float:'left'}}>Version : {moduleVersion}
                            </div>
                            <div className="d-flex justify-content-end align-items-center"
                                 style={{ height:'100%', width:'230px', float:'right'}}>
                                { createWithId &&
                                <BlackActionButton onClick={ e => createWithId(moduleId) }>
                                    <div style={{ float:'left', marginRight:'5px' }}>
                                        <IoCopyOutline />
                                    </div>
                                    <div style={{ float:'left' }}>Create new from this module</div>
                                </BlackActionButton>
                                }
                            </div>
                        </div>
                        <DNDListVertical items={moduleData}
                                         direction="vertical"
                                         reorder={onReorder}
                                         Inner={BasicInfoButton}
                                         innerParams={{ selectedIdx: selectedIdx, 
                                                'onClick': onClick,
                                                'onRemove' : onRemove,
                                                'canRemove' : canRemove
                                              }}
                                         idBuilder={idBuilder}
                        />  
                    </div>
                    <div style={{ width:'60%', height:'100%', float:'right', padding:'10px', overflowY:'scroll' }}>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Survey( props )
{
    const moduleId = props.moduleId
    const moduleVersion = props.moduleVersion
    const moduleData = props.moduleData 
    const setModuleData = props.setModuleData 
    const hasChange = props.hasChange
    const createWithId = props.createWithId
    const canRemove = props.canRemove

    const [ selectedIdx, setSelectedIdx ] = useState(-1)
    const [ loaded, setLoaded ] = useState(false)

    const [ primaryLanguage, setPrimaryLanguage ] = useState(moduleData.primary_language)
    const [ secondaryLanguage, setSecondaryLanguage ] = useState(moduleData.secondary_language) 

    const languages = [ 'English', 'German', 'Swedish' ]
    const data = {}
 
    useEffect( () => {
        setSelectedIdx(-1)
        setPrimaryLanguage( moduleData.primary_language )
        setSecondaryLanguage( moduleData.secondary_language )
    }, [moduleId] )

    useEffect( () => {
        let data = structuredClone(moduleData)
        data.primary_language = primaryLanguage 
        data.secondary_language = secondaryLanguage 
        setModuleData(data)
    },[ primaryLanguage, secondaryLanguage ])
    
    const idBuilder = ( index, item ) => {
        return item.var + index 
    }

    const onReorder = ( list, startIndex, endIndex ) => {
        var l = structuredClone(moduleData)
        const [removed] = l.splice(startIndex, 1);
        l.splice(endIndex, 0, removed);

        setModuleData(l)
        setSelectedIdx(endIndex)
    }

    const onRemove = ( index ) => {
        var l = structuredClone(moduleData)
        const [removed] = l.splice(index, 1); 
        setModuleData(l)
    }

    const onClick = ( index, item ) => {
        setSelectedIdx(index)
    }

    const update = ( key, value ) => {
    }

    return (
        <div style={{ height:'100%', width:'100%' }}>
            <div style={{ width:'100%', height:'50px', padding:'5px'  }}>
                <div style={{ width:'40%', height:'100%', float:'left' }}>
                    <div className="d-flex justify-content-start align-items-center fw-bolder"
                         style={{ height:'100%', width:'100px', float:'left'}}>Version : {moduleVersion}
                    </div>
                    <div className="d-flex justify-content-end align-items-center"
                         style={{ height:'100%', width:'230px', float:'right'}}>
                        { createWithId &&
                        <BlackActionButton onClick={ e => createWithId(moduleId) }>
                            <div style={{ float:'left', marginRight:'5px' }}>
                                <IoCopyOutline />
                            </div>
                            <div style={{ float:'left' }}>Create new from this module</div>
                        </BlackActionButton>
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-end align-items-center" 
                     style={{ width:'60%', height:'100%', float:'right' }}> 
                    <Form.Select value={primaryLanguage} 
                                 onChange={ e => setPrimaryLanguage(e.target.value)} 
                                 style={{ width:'200px', borderRadius:'2px' }}>
                        <option disabled selected value="">Languages</option>
                        { languages.map( (lang,idx) => 
                        <option value={lang}>{lang}</option>
                        )}
                    </Form.Select>
                </div>

            </div>
            <div style={{ width:'100%', height:'calc(100% - 50px)', padding:'5px'}}>
                <div style={{ height:'100%', width:'100%', 
                               border: (hasChange) ? '1px solid #DC4C64' : '1px solid #0057FF', 
                               borderRadius:'2px',
                               padding:'20px',
                            }}
                >
                    <SurveyEditor moduleId={moduleId}
                                  data={moduleData}
                                  setData={setModuleData}
                                  primaryLanguage={primaryLanguage}
                                  secondaryLanguage={secondaryLanguage}
                    />
                </div>
            </div>
        </div>
    )
}

export function TodoCheckList( props )
{
    const moduleId = props.moduleId
    const moduleVersion = props.moduleVersion
    const moduleData = props.moduleData 
    const setModuleData = props.setModuleData 
    const hasChange = props.hasChange
    const createWithId = props.createWithId
    const canRemove = props.canRemove

    const [ selectedIdx, setSelectedIdx ] = useState(-1)

    const [ showAddNew, setShowAddNew ] = useState( false ) 
    const [ addName, setAddName ] = useState("")
    const [ addVariable, setAddVariable ] = useState("")

    const data = {}

    useEffect( () => {
        setSelectedIdx(-1)
    }, [moduleId] )

    const idBuilder = ( index, item ) => {
        return item.variable + index 
    }

    const onReorder = ( list, startIndex, endIndex ) => {
        var l = structuredClone(moduleData.array)

        const [removed] = l.splice(startIndex, 1);
        l.splice(endIndex, 0, removed);

        let out = { array : l }

        setModuleData( out )
        setSelectedIdx(endIndex)
    }

    const onRemove = ( index ) => {
        var l = structuredClone(moduleData.array)
        const [removed] = l.splice(index, 1); 

        let out = { array : l }


        setModuleData(out)
    }

    const onClick = ( index, item ) => {
        setSelectedIdx(index)
        setShowAddNew(false)
    }

    const openAddNew = () => {
        setAddName("")
        setAddVariable("")
        setShowAddNew(true)
    }

    const addNewItem = () => {
        if( addName == "" || addVariable == "" )
        {
            return
        }
    
        let dd = {}
        dd.content = addName 
        dd.variable = addVariable 
        dd.hidden = 0
        
        let l = []

        if( moduleData.array )
        {
            l = structuredClone( moduleData.array )
        }

        l.push( dd )

        let out = { array : l }

        setModuleData( out )
        setShowAddNew( false ) 
    }

    return (
        <div style={{ height:'100%', width:'100%' }}>
            <div style={{ width:'100%', height:'50px', padding:'5px'  }}>
                <div style={{ width:'40%', height:'100%', float:'left' }}>
                    <div className="d-flex justify-content-start align-items-center fw-bolder"
                         style={{ height:'100%', width:'100px', float:'left'}}>Version : {moduleVersion}
                    </div>
                    <div className="d-flex justify-content-end align-items-center"
                         style={{ height:'100%', width:'230px', float:'right'}}>
                        { createWithId &&
                        <BlackActionButton onClick={ e => createWithId(moduleId) }>
                            <div style={{ float:'left', marginRight:'5px' }}>
                                <IoCopyOutline />
                            </div>
                            <div style={{ float:'left' }}>Create new from this module</div>
                        </BlackActionButton>
                        }
                    </div>
                </div> 
            </div>
            <div style={{ width:'100%', height:'calc(100% - 50px)', padding:'5px'}}>
                <div style={{ height:'100%', width:'100%', 
                               border: (hasChange) ? '1px solid #DC4C64' : '1px solid #0057FF', 
                               borderRadius:'2px',
                               padding:'20px',
                            }}
                >
                    <div style={{ width:'40%', height:'100%', float:'left', padding:'10px', overflowY:'scroll'  }}>
                        <div className="fw-bolder" style={{ width:'100%', height:'40px', marginBottom:'5px'}} >
                        </div>
                        { moduleData.array &&
                        <DNDListVertical items={moduleData.array}
                                         direction="vertical"
                                         reorder={onReorder}
                                         Inner={TodoChecklistButton}
                                         innerParams={{ selectedIdx: selectedIdx, 
                                                'onClick': onClick,
                                                'onRemove' : onRemove,
                                                'canRemove' : canRemove
                                              }}
                                         idBuilder={idBuilder}
                        />
                        }
                        
                        <ModuleSelector check={showAddNew}
                                        childTrue={
                                            <div className="d-flex align-items-center" 
                                                 style={{ backgroundColor: '#FFFFFF', 
                                                          color: '#AEAEAE',
                                                          border: '0.2px solid #AEAEAE',
                                                          height:'120px', width:'100%', borderRadius:'2px', 
                                                          marginBottom:'5px', padding:'8px',
                                                          cursor:'pointer' }} 
                                            >
                                                <div style={{ height:'100%', width:'100%' }}>
                                                    <Form.Control placeHolder="Name" size="sm"
                                                                  style={{ marginBottom:'5px', borderRadius:'0px',
                                                                           border:'none', borderBottom:'0.2px solid'}}
                                                                  value={addName}
                                                                  onChange={ e => setAddName(e.target.value)}
                                                    />
                                                    <Form.Control placeHolder="Variable" size="sm" 
                                                                  style={{ marginBottom:'5px', borderRadius:'0px',
                                                                           border:'none', borderBottom:'0.2px solid' }}
                                                                  value={addVariable}
                                                                  onChange={ e => setAddVariable(e.target.value) }
                                                    />
                                                    <Button size="sm" variant="secondary"
                                                            style={{ borderRadius:'2px' }}
                                                            onClick={addNewItem}>Add</Button>
                                                </div>
                                            </div>
                                        }
                                        childFalse={
                                            <div className="d-flex align-items-center" 
                                                 style={{ backgroundColor: '#FFFFFF', 
                                                          color: '#AEAEAE',
                                                          border: '0.2px solid #AEAEAE',
                                                          height:'35px', width:'100%', borderRadius:'2px', 
                                                          marginBottom:'5px', padding:'8px',
                                                          cursor:'pointer' }}
                                                 onClick={openAddNew}
                                            >
                                                <div className="d-flex align-items-center" 
                                                     style={{ height:'100%', float:'left', marginLeft:'5px' }}
                                                >
                                                    <ImPlus />
                                                </div>
                                                <div style={{ height:'100%', marginLeft:'10px', float:'left' }}>
                                                    Add new
                                                </div>
                                            </div>
                                        }
                        />
                    </div>
                    <div style={{ width:'60%', height:'100%', float:'right', padding:'10px', overflowY:'scroll' }}>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default function ModuleEditor( props )
{
    const category = props.category
    const moduleId = props.moduleId
    const moduleVersion = props.moduleVersion
    const moduleData = props.moduleData
    const setModuleData = props.setModuleData
    const hasChange = props.hasChange
    const createWithId = props.createWithId
    const canRemove = props.canRemove

    if( category == "basic_info" )
    {
        return (
            <BasicInformation moduleId={moduleId}
                              moduleVersion={moduleVersion}
                              moduleData={moduleData} 
                              setModuleData={setModuleData}
                              hasChange={hasChange}
                              createWithId={createWithId}
                              canRemove={canRemove}
            />
        )
    }

    if( category == "bio_bank" )
    {
        return (
            <Biobank moduleId={moduleId}
                     moduleVersion={moduleVersion}
                     moduleData={moduleData} 
                     setModuleData={setModuleData}
                     hasChange={hasChange}
                     createWithId={createWithId}
                     canRemove={canRemove}
            />
        )
    }

    if( category == "lab_analysis" )
    {
        return (
            <LabAnalysis moduleId={moduleId}
                         moduleVersion={moduleVersion}
                         moduleData={moduleData} 
                         setModuleData={setModuleData}
                         hasChange={hasChange}
                         createWithId={createWithId}
                         canRemove={canRemove}
            />
        )
    }

    if( category == "survey" || category == "patient_assessment")
    {
        return (
            <Survey moduleId={moduleId}
                    moduleVersion={moduleVersion}
                    moduleData={moduleData} 
                    setModuleData={setModuleData}
                    hasChange={hasChange}
                    createWithId={createWithId}
                    canRemove={canRemove}
            />
        )
    }

    if( category == "todo_checklist" )
    {
        return (
            <TodoCheckList moduleId={moduleId}
                       moduleVersion={moduleVersion}
                       moduleData={moduleData} 
                       setModuleData={setModuleData}
                       hasChange={hasChange}
                       createWithId={createWithId}
                       canRemove={canRemove}
            />
        )
    }


    return (
        <NoCustomization hasChange={hasChange} />
    )
 }
