
import { useState, useEffect } from 'react'
import {backendAccessGet, backendAccessPost} from '../../../Utils/Authentication'
import Row from 'react-bootstrap/Row'

export default function FileViewer( props )
{
    const tag = props.tag
    const reference = props.reference

    const [ image, setImage ] = useState(null)
    const [ imageType, setImageType ] = useState( null )

    const show = () => {
        var data = {}
        data['reference'] = reference

        backendAccessPost("/api/files/fetch/", data=data ).then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                return
            }

            setImage( res['b64'] )
            setImageType( res['info']['data_type'])

        })
    }

    const download = () => {
        var data = {}
        data['reference'] = reference

        backendAccessPost("/api/files/fetch/", data=data ).then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                return
            }

            var info = res["info"]
            var buffer = atob( res['b64'] )
            const url = window.URL.createObjectURL(new Blob([buffer]))

        })

        /*const link = document.createElement("a");
        link.href = url;
        link.download = "file.png";
        console.log( link )
        link.click();*/
    }

    return (
        <div style={{ width:"100%", padding:'5px', backgroundColor:'#E8E8E8',
                      borderRadius:'5px', margin:'2px' }}>
            <div className="d-flex justify-content-center" style={{ width:"100%", marginBottom:'10px'}}>
                <b>{tag}[ <a href="#" onClick={show}> Show </a> ][  <a href="#" onClick={download}> Download </a> ]</b>
            </div>
            
            <div style={{ width:'100%' }}>
                { ( image && imageType ) &&
                    <img style={{ width:'100%' }} src={`data:${imageType};base64,${image}`} />
                }
            </div>
        </div>
    )
}
