

export default function ModuleSelector( props )
{
    const check = props.check 
    const childTrue = props.childTrue
    const childFalse = props.childFalse

    if( check )
    {
        return (
            <>
                { childTrue }
            </>
        )
    }

    return (
        <>
            { childFalse }
        </>
    )
}

