import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import { Container, NavLink, Nav, Button } from 'react-bootstrap';
import { NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { FaUserCircle } from 'react-icons/fa'
import { AiOutlineUser } from "react-icons/ai";
import { IoHomeOutline } from "react-icons/io5";

import { FiSettings, FiLogOut } from 'react-icons/fi'
import { IoIosArrowBack } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa6";

import { Authentication, logout } from '../Utils/Authentication'

//import streampleat from '../streampleat.png' 

function BackButton( props )
{
    const title = props.title

    return (
        <div className="d-flex align-items-center" style={{ height:'100%', width:'100px'}}>
            <div style={{float:'left',  marginRight:'5px' }}>
                <FaArrowLeft />
            </div>
            <div style={{ float:'left'}}>
                <b>{title}</b>
            </div>
        </div>
    )
}

function NavigationBar( props )
{
    const backgroundColor = (props.backgroundColor) ? props.backgroundColor : '#FFFFFF'
    const homeButton = (props.homeButton) ? props.homeButton : false
    const patientsButton = (props.patientsButton) ? props.patientsButton : false

    const [ loaded, setLoaded ] = useState( false )
    const [ authenticated, setAuthenticated ] = useState( false )
    const [ username, setUsername ] = useState( null )
    let navigate = useNavigate()

    useEffect( () => {
        let auth = new Authentication()

        auth.has_token().then( a => {
            setAuthenticated(a)
            setUsername( auth.username() )
            setLoaded( true )
        })

    }, [] )

    return (
        <Navbar style={{ height:"60px",
                             backgroundColor:backgroundColor}}>
            <Container style={{ maxWidth:'1400px', padding:'5px', paddingLeft:'20px', paddingRight:'20px'}}>
            { homeButton &&
            <LinkContainer to="/">
                <Nav.Link>
                    <BackButton title="ETD" />
                </Nav.Link>
            </LinkContainer>
            }

            { patientsButton &&
            <LinkContainer to="/patients">
                <Nav.Link>
                    <BackButton title="Patients" />
                </Nav.Link>
            </LinkContainer>
            }

            <Nav className="ms-auto">
                { authenticated &&  
                <NavDropdown align="end"
                            title=<AiOutlineUser style={{ fontSize:24 }}/>
                            menuVariant="light"
                            id="basic-nav-dropdown"
                            size="sm"
                >
                    <NavDropdown.Item disabled >
                        <b>{username}</b>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item>
                        <NavLink onClick={ e => navigate("/settings") }> <FiSettings /> Settings</NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item>
                    <NavLink onClick={ e => { 
                                        logout()
                                        navigate("/login")
                                    }}><FiLogOut /> Logout</NavLink>
                    </NavDropdown.Item>
                </NavDropdown>
                }
            </Nav>
            </Container>
        </Navbar>
    )
}

export default NavigationBar;
