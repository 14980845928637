import { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import { backendAccessPost, backendAccessGet } from '../../Utils/Authentication'
import BlackActionButton from '../UX/BlackActionButton'

export default function Samples( props )
{
    const [ samples, setSamples ] = useState(null)

    useEffect( () => {
        backendAccessGet("/api/biobank/samples-list/").then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                return
            }

            setSamples( res['samples'] )
        })

    },[])

    const updateSample = ( sid ) => {
        var remaining = window.prompt("How much of the sample is remaining?")

        var data = {}
        data['sample_id'] = sid
        data['remaining'] = remaining

        backendAccessPost('/api/biobank/update-sample/', data).then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                return
            }

            setSamples( res['samples'] )

        })
    }

    const unplaceSample = ( sid ) => {
        var check = window.confirm("Are you sure you want to unplace the sample?")

        if( !check )
        {
            return;
        }

        var data = {}
        data['sample_id'] = sid 

        backendAccessPost('/api/biobank/unplace-sample/', data ).then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                return
            }

            setSamples( res['samples'] )
        })
    }

    const downloadCSV = ( patient_id, visit_id, sample_type, sample_id ) => {
        var csv_data = "";
        csv_data += 'patient_id,visit_id,module_id,var,name,sample_id\n'
        csv_data += patient_id + "," + visit_id + ",," + sample_type + ",," + sample_id + "\n"

        var data = new Blob([csv_data], {type: 'text/csv'});
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', sample_id + ".csv");
        tempLink.click();

    }

    return (
        <div style={{ height:"100%", width:"100%", fontSize:12,
                padding:'10px', overflowY:'scroll'}}>
            { samples &&
            <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ width:'10px'}}>#</th>
                            <th style={{ width:'40px'}}>Sample ID</th>
                            <th style={{ width:'40px'}}>Patient ID</th>
                            <th style={{ width:'40px'}}>Visit ID</th>
                            <th style={{ width:'40px'}}>Box ID</th>
                            <th style={{ width:'40px'}}>Box Location</th>
                            <th style={{ width:'40px'}}>Sample Type</th>
                            <th style={{ width:'40px'}}>Remaining</th>
                            <th style={{ width:'40px'}}>Update</th>
                            <th style={{ width:'50px'}}>Label</th>
                        </tr>
                    </thead>
                    <tbody>
                    { samples.map( (s,idx) =>
                        <tr>
                            <td> {idx + 1} </td>
                            <td> { s.sample_id } </td>
                            <td> { s.patient_id } </td>
                            <td> { s.visit_id } </td>
                            <td> { s.box_id } </td>
                            <td> { s.box_loc } </td>
                            <td> { s.sample_type } </td>
                            <td> { s.remaining } </td>
                            <td>
                                <BlackActionButton onClick={ (e) => updateSample( s.sample_id ) }
                                                   style={{ borderRadius:'2px', width:'56px', 
                                                            marginRight:'5px', float:'left' }}
                                >
                                    Update
                                </BlackActionButton>
                                <BlackActionButton onClick={ (e) => unplaceSample( s.sample_id ) }
                                                   style={{ borderRadius:'2px', width:'62px', 
                                                            marginRight:'5px', float:'left' }}
                                >
                                    Unplace
                                </BlackActionButton>
                            </td>
                            <td> 
                                <Button variant="secondary" size="sm"
                                        style={{ borderRadius:'2px'}}  
                                         onClick={ (e) => downloadCSV( s.patient_id, s.visit_id, 
                                                                       s.sample_type, s.sample_id ) }>
                                    Download CSV
                                </Button>

                            </td>
                        </tr>

                    )}
                    </tbody>
            </Table>
            }

        </div>
    )
}
