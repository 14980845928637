import { useState, useEffect } from "react";
import { Helmet } from 'react-helmet'
import PatientVisit from "./Patients/PatientVisit";
import { useLocation } from 'react-router-dom';


export default function PatientView( props )
{
    const location = useLocation()
    const [ patientId, setPatientId ] = useState(null)

    useEffect( () => {
        let state = location.state 
        setPatientId( state.patientId )
    },[])

    return (
        <>
        { patientId &&
        <div className="d-flex justify-content-center align-items-center" 
             style={{ height: 'calc(100vh - 60px)', width:"100%" }}>
            <PatientVisit patientId={patientId}
                          setPatientId={setPatientId}
            />
        </div>
        }
        </>
    )
}
