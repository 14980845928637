// src/pages/Login.js

import { Helmet } from 'react-helmet'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import { useNavigate } from 'react-router-dom';

import { useState } from "react"

import { login, verify, me } from '../Utils/Authentication'

import { AiOutlineUser } from "react-icons/ai";
import { BiLockAlt } from "react-icons/bi";

export default function Login() {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [hasError, setHasError] = useState(false)

    let navigate = useNavigate()

    const submit = (e) => {
        e.preventDefault()
        if( username !== "" && password !== "" )
        {
            login( { username, password } ).then( success => {
                if( success )
                {
                    setHasError( false )
                    navigate("/")
                }
                else
                {
                    setHasError( true )
                }
            })
        }
    }

    const keyPress = (e) => {
        if( e.charCode == 13 )
        {
            submit(e)
        }
    }

    return (
        <div  style={{ height:"100vh", width:'100%' }}>
            <div className="d-flex justify-content-center align-items-center" 
                 style={{ height:"100%", width:'100%', backgroundColor:'#F6F6F6' }}
                 onKeyPress={ e => keyPress(e) }>
                <div style={{ height:'278px', width:'342px', backgroundColor:'#FFFFFF',
                              padding:'30px', borderRadius:'2px'}}>
                    <div className="d-flex justify-content-center fw-bolder" 
                         style={{ width:'100%', height:'30px', marginBottom:'5px' }}>
                        ETD 
                    </div>
                    <div style={{ width:'100%', height:'40px', marginBottom:'10px', 
                                  paddingBottom:'10px', borderBottom:'0.2px solid #DADADA' }}>

                        <div style={{ float:'left', width:'85%' }}>
                            <Form.Control style={{ border:'none', borderRadius:'2px' }} 
                                          type="text"
                                          size="sm" 
                                          value={username}
                                          onChange={ e => {
                                            setHasError( false )
                                            setUsername( e.target.value )
                                          }}
                                          placeHolder="Username"
                            />
                        </div>
                        <div style={{ float:'right', width:'10%' }}>
                            <AiOutlineUser />
                        </div>
                    </div>
                    <div style={{ width:'100%', height:'40px', marginBottom:'10px' }}>
                        <div style={{ float:'left', width:'85%' }}>
                            <Form.Control style={{ border:'none', borderRadius:'2px' }} 
                                          type="password"
                                          size="sm"
                                          value={password}
                                          onChange={ e => {
                                            setHasError( false )
                                            setPassword( e.target.value )
                                          }}
                                          placeHolder="Password"
                            />
                        </div>
                        <div style={{ float:'right', width:'10%' }}>
                            <BiLockAlt />
                        </div>
                    </div>
                    <div style={{ width:'100%', marginBottom:'20px'}} >
                        <Button style={{ width:"100%", borderRadius:'2px' }}
                                    onClick={ e => submit(e) }
                                >
                                    Login
                        </Button>
                    </div>
                    <div className="d-flex justify-content-center fw-bold" 
                         style={{ width:'100%', marginBottom:'20px', color:'#0d6efd'}} >
                        <div style={{ cursor:'pointer' }} onClick={ e => navigate('/survey')}>
                        Survey
                        </div>
                    </div>                  
                </div>
            </div>
        </div>
    )
}
