

export default function MultiLineText( props )
{
    const fullText = props.text
    
    return (
        <>
            { fullText &&
            <>
                { fullText.split("\\n").map( (text, idx) => 
                <div key={idx}>
                    {text}
                </div>
                )}
            </>
            }
        </>
    )
}
