import { Helmet } from 'react-helmet'
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'

import { useState, useEffect } from 'react'
import {backendAccessPostNoAuth} from '../Utils/Authentication'
import QuestionnaireViewer from '../Components/DataModules/Tools/QuestionnaireViewer'

export function SingleSurvey( props )
{
    const surveys = props.surveys
    const surveyIdx = props.surveyIdx
    const language = props.language
    const submit = props.submit

    const [ reference, setReference ] = useState(null)
    const [ patientId, setPatientId ] = useState(null)
    const [ visitId, setVisitId ] = useState(null)
    const [ moduleId, setModuleId ] = useState(null)
    const [ template, setTemplate ] = useState(null)

    const [ data, setData ] = useState(null)

    const loadSurvey = () => {
        if( surveyIdx != null && surveys != null )
        {
                setPatientId( surveys[surveyIdx]["patient_id"] )
                setVisitId( surveys[surveyIdx]["visit_id"] )
                setModuleId( surveys[surveyIdx]["module_id"] )
                setTemplate( JSON.parse(surveys[surveyIdx]['template']['module_data']) )
                setReference( surveys[surveyIdx]["reference"] )
                setData({})
        }
    }

    useEffect( () => {
        loadSurvey()
        //window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    useEffect( () => {
        loadSurvey()
        //window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    }, [ surveyIdx] )

    useEffect( () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[template])

    const update = ( key, value ) => {
        var d = {}
        if( data != null )
        {
            d = {...data}
        }

        d[key] = value
        setData(d)
    }

    return (
        <>
        { data &&
        <>
            <Container>
                <QuestionnaireViewer data={data}
                                 template={template}
                                 update={update}
                                 language={language} />
            </Container>

            <Container className="d-flex justify-content-center"
                           style={{margin:'20px'}}>
            <Button variant="secondary" onClick={(e) => submit( reference, data )}>Submit</Button>
            </Container>
        </>
        }
        </>
    )
}

export default function Survey ()
{
    const [ appState, setAppState ] = useState( 0 )
    const [ errorMessage, setErrorMessage ] = useState(null)
    const [ reference, setReference ] = useState( "" )
    const [ surveyType, setSurveyType ] = useState( null )
    const [ languages, setLanguages ] = useState(null)
    const [ language, setLanguage ] = useState(null)

    const [ surveyIdx, setSurveysIdx ] = useState(null)
    const [ surveys, setSurveys ] = useState(null)

    useEffect( () => {
        if( reference != "" && errorMessage != null )
        {
            setErrorMessage( null )
        }
    },[reference])

    const submitReference = () => {
        if( reference != "" )
        {
            if( reference.length == 4 )
            {
                var data = {}
                data['reference'] = reference

                backendAccessPostNoAuth("/api/survey/validate/", data).then( res => {
                    if( "error_message" in res )
                    {
                        setErrorMessage( res["error_message"] )
                        return
                    }

                    setLanguages( res["languages"] )
                    setSurveys( res["surveys"] )
                    setSurveysIdx( 0 )
                    setSurveyType("single")
                    setAppState(1)
                })
            }
            if( reference.length == 6 )
            {
                var data = {}
                data['reference'] = reference

                backendAccessPostNoAuth("/api/survey/group-validate/", data).then( res => {
                    if( "error_message" in res )
                    {
                        setErrorMessage( res["error_message"] )
                        return
                    }

                    console.log(res)

                    setLanguages( res["languages"] )
                    setSurveys( res["surveys"] )
                    setSurveysIdx( 0 )
                    setSurveyType("group")
                    setAppState(1)
                })
            }
        }
    }

    const selectLanguage = () => {
        setAppState(2)
    }

    const next_or_reset = () => {
        if( surveyIdx < surveys.length-1 )
        {
            // Next
            setSurveysIdx( surveyIdx + 1 )
        }
        else
        {
            // Reset
            setSurveysIdx(null)
            setSurveys(null)
            setAppState( 0 )
            setReference("")
            setErrorMessage(null)
            setSurveyType(null)
        }

        //window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    }

    const submit = ( ref, data ) => {
        var d = {}
        d['reference'] = ref
        d['instance_data'] = JSON.stringify({'values':data})

        backendAccessPostNoAuth('/api/survey/update/', d).then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                return
            }

            if( surveyType == "group" )
            {
                var dd = {}
                dd['reference'] = reference

                backendAccessPostNoAuth('/api/survey/group-update/', dd).then( res => {
                    if( "error_message" in res )
                    {
                        alert( res["error_message"] )
                        return
                    }
                    next_or_reset()
                })
           }
            else
            {
                next_or_reset()
            }
        })
    }

    return (
        <>
            <Modal show={ appState === 0 } size="lm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Reference
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height:'150px'}}>
                    <div>
                        <Form.Control value={reference} onChange={ e => { setReference( e.target.value )} } />
                    </div>
                    
                    { errorMessage &&
                        <div style={{ marginTop:'20px'}} >
                            <Alert variant="danger">{errorMessage}</Alert>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ submitReference }>Next</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={ appState === 1 } size="lm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Select Language
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                { languages &&
                    <Form.Select aria-label="Select languages"
                             onChange={ e => { setLanguage( e.target.value )}}
                             defaultValue={'DEFAULT'}>
                    <option value="DEFAULT" disabled hidden>Choose here</option>
                    {
                        languages.map( (l,index) => 
                            <option value={l} key={index}>{l}</option>
                        )
                    }
                </Form.Select>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ selectLanguage }>Next</Button>
            </Modal.Footer>
            </Modal>
            
            { ( appState == 2 && surveyIdx >= 0 ) &&
                <SingleSurvey surveys={surveys}
                              surveyIdx={surveyIdx}
                              language={language}
                              submit={submit}
                />

            }

        </>
    )
}
