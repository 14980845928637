import { useState, useEffect } from 'react'

import Title from './ViewerComponets/Title'
import Introduction from './ViewerComponets/Introduction'
import Questions from './ViewerComponets/Questions'
import FinalNotes from './ViewerComponets/FinalNotes'

export default function QuestionnaireViewer( props )
{
    const data = props.data
    const template = props.template
    const language = props.language
    const update = props.update

    return (
        <>
            <Title language={language}
                   template={template} />
            <Introduction language={language}
                          template={template} />
            <Questions language={language}
                       template={template}
                       data={data}
                       update={update} />
            <FinalNotes language={language}
                        template={template} />
        </>
    )
}
