import { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'

import {backendAccessGet, backendAccessPost} from '../../../Utils/Authentication'

import FileViewer from './FileViewer'

export default function ConsentFormViewer( props )
{
    const patientId = props.patientId
    const visitId = props.visitId
    const moduleId = props.moduleId
    const instanceData = props.instanceData
    const setInstanceData = props.setInstanceData
    const template = props.template
    const version = props.version
    const status = props.status
    const reference = props.reference
    const createPlaceHolder = props.createPlaceHolder
    const dumpEmpty = props.dumpEmpty
    const reload = props.reload

    const [ file, setFile ] = useState(null)
    const [ fileValue, setFileValue ] = useState("")
    const [ tag, setTag ] = useState("Consent Form")

    const fileSetter = ( v,f ) =>
    {
        setFileValue( v )
        setFile( f )
    }

    const upload = () => {
        var fr = new FileReader();

        fr.onload = function() {
            var file_data = {}
            file_data['filename'] = file.name
            file_data['b64_binary'] = btoa(fr.result)
            file_data['type'] = file.type
            file_data['tag'] = tag

            var data = {}
            data['patient_id'] = patientId
            data['visit_id'] = visitId
            data['module_id'] = moduleId
            data['file_data'] = file_data

            backendAccessPost('/api/files/store/', data=data ).then( res => {

                if( "error_message" in res )
                {
                    alert( res["error_message"] )
                    return
                }

                var d = structuredClone( instanceData )

                if( d['item'] == null )
                {
                    d['item'] = {}
                }

                d['item']['tag'] = tag
                d['item']['reference'] = res['reference']

                setInstanceData( d )

                setTag("")
                setFile(null)
                setFileValue("")

            })
        }

        fr.readAsBinaryString(file);    
    }

    return (
        <>
        <div className="d-flex justify-content-center"
             style={{ width:'100%', height:'60px', padding:'10px'}}>
            <div style={{ width:'700px' }}>

                <InputGroup>
                    <Form.Control type="file" value={fileValue} onChange={ (e) => fileSetter(e.target.value, e.target.files[0])}/>
                    <Button variant="secondary" onClick={upload}>Upload</Button>
                </InputGroup>
            </div>
        </div>
        { instanceData['item'] &&
            <FileViewer tag={instanceData['item']['tag']}
                        reference={instanceData['item']['reference']}
            />
        }
        </>
    )
}
