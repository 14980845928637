import { BrowserRouter as Router, Routes, Route } from 'react-router-dom' 

import NavigationBar from './Components/NavigationBar'
import PrivateRoute from './Components/PrivateRoute'
import AlreadyLoggedIn from './Components/AlreadyLoggedIn'

import Login from './Pages/Login'
import Logout from './Pages/Logout'
import Home from './Pages/Home'
import Downloads from './Pages/Downloads'
import Documentation from './Pages/Documentation'
import Analytics from './Pages/Analytics'
import Settings from './Pages/Settings'
import Patients from './Pages/Patients'
import PatientView from './Pages/PatientView'
import SOPs from './Pages/SOPs'
import DND from './Pages/DND'
//import CreatePatient from './Pages/Patients/CreatePatient'
//import PatientVisit from './Pages/Patients/PatientVisit'
//import BrowsePatients from './Pages/Patients/BrowsePatients'
import VisitDesigner from './Pages/VisitDesigner'
import ModuleBuilder from './Pages/ModuleBuilder'
import Survey from './Pages/Survey'
import Biobank from './Pages/Biobank'

import Mobile from './Pages/Mobile'

function App() {
  return (
    <div className="App">
        <Router>
            <Routes>
                <Route path="/" element={
                    <>
                        <NavigationBar backgroundColor="#F6F6F6" />
                        <PrivateRoute>
                            <Home />
                        </PrivateRoute>
                    </>
                } />
                <Route path="/patients" element={
                    <>
                        <NavigationBar homeButton={true} backgroundColor='#F6F6F6'/>
                        <PrivateRoute>
                            <Patients />
                        </PrivateRoute>
                    </>
                } />
                <Route path="/patient-view" element={
                    <>
                        <NavigationBar patientsButton={true}/>
                        <PrivateRoute>
                            <PatientView />
                        </PrivateRoute>
                    </>
                } />
                <Route path="/settings" element={
                    <>
                        <NavigationBar homeButton={true}/>
                        <PrivateRoute>
                            <Settings />
                        </PrivateRoute>
                    </>
                } />
                <Route path="/biobank" element={
                    <>
                        <NavigationBar homeButton={true} />
                        <PrivateRoute>
                            <Biobank />
                        </PrivateRoute>
                    </>

                }/>                
                <Route path="/visit-designer" element={
                    <>
                        <NavigationBar homeButton={true}/>
                        <PrivateRoute>
                            <VisitDesigner />
                        </PrivateRoute>
                    </>
                }/>
                <Route path="/module-builder" element={
                    <>
                        <NavigationBar homeButton={true}/>
                        <PrivateRoute>
                            <ModuleBuilder />
                        </PrivateRoute>
                    </>
                }/>
                <Route path="/documentation" element={
                    <>
                        <NavigationBar homeButton={true}/>
                        <PrivateRoute>
                            <Documentation />
                        </PrivateRoute>
                    </>
                }/>
                <Route path="/sops" element={
                    <>
                        <NavigationBar homeButton={true}/>
                        <PrivateRoute>
                            <SOPs />
                        </PrivateRoute>
                    </>
                }/>
               
                <Route path="/login" element={ 
                    <AlreadyLoggedIn>
                        <Login />
                    </AlreadyLoggedIn> 
                } />  
                <Route path="/logout" element={ 
                    <Logout />
                } />
                <Route path="/survey" element={
                    <>
                        <NavigationBar />
                        <Survey />
                    </>
                }/>
                <Route path="/downloads" element={
                    <>
                        <NavigationBar homeButton={true} backgroundColor='#F6F6F6' />
                        <PrivateRoute>
                            <Downloads />
                        </PrivateRoute>
                    </>
                }/>
            </Routes>
        </Router>
    </div>
  );
}

export default App;
