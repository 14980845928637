import { useState, useEffect } from 'react'
import InputSelect from '../Tools/InputFields'

export default function BasicInformationViewer( props )
{
    const moduleId = props.moduleId
    const instanceData = props.instanceData
    const setInstanceData = props.setInstanceData
    const template = props.template

    const [ data, setData ] = useState(null)

    const load = () => {
        var d = structuredClone( instanceData )
        for( var tidx in template )
        {
            var t = template[tidx]
            var varName = t.var
            var type = t.type

            if( d[varName] == null )
            {
                d[varName] = null
            }
        }
        setData(d)
    }

    useEffect( () => {
        load()
    }, [instanceData] )

    useEffect( () => {
        load()
    }, [])

    useEffect( () => {
        load()
    }, [ moduleId ])

    const update = ( key, value ) => {
        console.log( key, value )
        data[key] = value
        setData( { ...data } )
        setInstanceData( {...data} )
    }

    return (
        <div style={{ height:'100%', width:'100%', padding:'10px', overflowY:'auto' }}>
            { template &&
            <>
                { template.map( (t,idx) => 
                    <InputSelect moduleId={moduleId}
                                 data={data}
                                 update={update}
                                 t={t}/>
                )}
            </>
            }
        </div>
    )
}
