import { useState, useEffect, useRef } from 'react' 
import Form from 'react-bootstrap/Form'
import { getText, putText } from './DataAccess'
import ModuleSelector from '../Misc/ModuleSelector'
import { ImCross } from "react-icons/im"
import { FaArrowRight } from "react-icons/fa"
import { FaSquarePlus } from "react-icons/fa6";

export function TextLang( props )
{
    const title = props.title 
    const placeHolder = props.placeHolder
    const data = props.data 
    const setData = props.setData 
    const primaryLanguage = props.primaryLanguage 
    const secondaryLanguage = props.secondaryLanguage
    const reference = props.reference

    const [ isHover, setIsHover ] = useState(false)

    const styleNormal = { height:'calc(100% - 40px)', width:'100%',
                                   borderRadius:'2px', fontSize:12, 
                                   resize:'none', outline:'none', border:'none' }
    const styleHover = { height:'calc(100% - 40px)', width:'100%',
                                   borderRadius:'2px', fontSize:12, 
                                   resize:'none', outline:'none', border:'0.2px solid #DADADA' }

    const updateValue = (val) => {
        let dd = structuredClone(data)
        let v = structuredClone( getText(data, reference) )

        v[primaryLanguage] = val 

        putText( dd, reference, v )
        setData(dd)
    }

    const textValue = () => {
        let val = getText(data,reference)
        return (val[primaryLanguage]) ? val[primaryLanguage] : ""
    }
    
    return (
        <div className="" style={{ width:'100%', height:'100%', padding:'5px' }}
             onMouseEnter={e => setIsHover(true)}
             onMouseLeave={e => setIsHover(false)}
        >
            { title &&
            <div className="d-flex align-items-center fw-bolder" 
                 style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <b style={{ marginLeft:'10px'}}>{ title }</b>
            </div>
            }
            <Form.Control style={(isHover) ? styleHover : styleNormal}                     
                          value={ textValue() }
                          onChange={ e => updateValue(e.target.value) }
                          placeHolder={placeHolder}
            />
        </div>
    )

}

export function TextAreaLang( props )
{
    const title = props.title 
    const placeHolder = props.placeHolder
    const data = props.data 
    const setData = props.setData 
    const primaryLanguage = props.primaryLanguage 
    const secondaryLanguage = props.secondaryLanguage
    const reference = props.reference

    const [ isHover, setIsHover ] = useState(false)

    const styleNormal = { height:'calc(100% - 40px)', width:'100%',
                                   borderRadius:'2px', fontSize:12, 
                                   resize:'none', outline:'none', border:'none' }
    const styleHover = { height:'calc(100% - 40px)', width:'100%',
                                   borderRadius:'2px', fontSize:12, 
                                   resize:'none', outline:'none', border:'0.2px solid #DADADA' }

    const updateValue = (val) => {
        let dd = structuredClone(data)
        let v = structuredClone( getText(data, reference) )

        v[primaryLanguage] = val 

        putText( dd, reference, v )
        setData(dd)
    }

    const textValue = () => {
        let val = getText(data,reference)
        return (val[primaryLanguage]) ? val[primaryLanguage] : ""
    }
    
    return (
        <div className="" style={{ width:'100%', height:'100%', padding:'5px' }}
             onMouseEnter={e => setIsHover(true)}
             onMouseLeave={e => setIsHover(false)}
        >
            { title &&
            <div className="d-flex align-items-center fw-bolder" 
                 style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <b style={{ marginLeft:'10px'}}>{ title }</b>
            </div>
            }
            <Form.Control as="textarea" 
                          style={(isHover) ? styleHover : styleNormal}                     
                          value={ textValue() }
                          onChange={ e => updateValue(e.target.value) }
                          placeHolder={placeHolder}
            />
        </div>
    )
}

export function TextArea( props )
{
    const title = props.title 
    const data = props.data 
    const setData = props.setData 
    const reference = props.reference

    const [ isHover, setIsHover ] = useState(false)

    const styleNormal = { height:'calc(100% - 40px)', width:'100%',
                                   borderRadius:'2px', fontSize:12, 
                                   resize:'none', outline:'none', border:'none' }
    const styleHover = { height:'calc(100% - 40px)', width:'100%',
                                   borderRadius:'2px', fontSize:12, 
                                   resize:'none', outline:'none', border:'0.2px solid #DADADA' }

    const [ value, setValue ] = useState({})
 
    const updateValue = (val) => {
        let dd = structuredClone(data)

        
        putText( dd, reference, val )
        setData(dd)
    }

    const textValue = () => {
        let val = getText(data,reference)
        return (val) ? val : ""
    }
 
    return (
        <div className="" style={{ width:'100%', height:'100%', padding:'5px' }}
             onMouseEnter={e => setIsHover(true)}
             onMouseLeave={e => setIsHover(false)}
        >
            <div className="d-flex align-items-center fw-bolder" 
                 style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <b style={{ marginLeft:'10px'}}>{ title }</b>
            </div>
            <Form.Control as="textarea" 
                          style={(isHover) ? styleHover : styleNormal}
                          value={ textValue() }
                          onChange={ e => updateValue(e.target.value) }
            />
        </div>
    )
}

export function ValueBox( props )
{
    const title = props.title 
    const placeHolder = props.placeHolder

    const getValue = props.getValue 
    const setValue = props.setValue

    const [ isHover, setIsHover ] = useState(false)

    const styleNormal = { height:'calc(100% - 40px)', width:'100%',
                                   borderRadius:'2px', fontSize:12, 
                                   resize:'none', outline:'none', border:'none' }
    const styleHover = { height:'calc(100% - 40px)', width:'100%',
                                   borderRadius:'2px', fontSize:12, 
                                   resize:'none', outline:'none', border:'0.2px solid #DADADA' }
     
    return (
        <div className="" style={{ width:'100%', height:'100%', padding:'5px' }}
             onMouseEnter={e => setIsHover(true)}
             onMouseLeave={e => setIsHover(false)}
        >
            { title &&
            <div className="d-flex align-items-center fw-bolder" 
                 style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <b style={{ marginLeft:'10px'}}>{ title }</b>
            </div>
            }
            <Form.Control as="textarea" 
                          style={(isHover) ? styleHover : styleNormal}                     
                          value={ getValue() }
                          onChange={ e => setValue(e.target.value) }
                          placeHolder={placeHolder}
            />
        </div>
    )

}


export function QuestionButton( props )
{
    const item = props.item
    const index = props.index 

    const selectedIdx = props.params.selectedIdx
    const onClick = props.params.onClick 
    const onRemove = props.params.onRemove 
    const canRemove = props.params.canRemove

    return (
        <ModuleSelector check={index != selectedIdx}
                        childTrue={
                            <div className="d-flex align-items-center" 
                                 style={{ width:'100%', height:'30px', border:'0.2px dashed', 
                                          marginBottom:'5px', borderRadius:'2px'}}
                                 onClick={e => onClick(index,item)}>
                                <div style={{ marginLeft:'10px', float:'left' }}
                                     onClick={ e => onRemove(index) }>
                                    <ImCross />
                                </div>
                                <div style={{marginLeft:'5px', float:'left'}}>
                                    Q{index+1}
                                </div>
                            </div>
                        }

                        childFalse={
                            <div style={{ width:'100%', height:'30px', border:'0.2px solid', 
                                          marginBottom:'5px', borderRadius:'2px', color:'#FFFFFF',
                                          backgroundColor:'#000000'}}
                                 onClick={ e => onClick(index,item) }>
                                <div className="d-flex align-items-center" 
                                     style={{ marginLeft:'10px', float:'left', height:'100%' }}
                                     onClick={ e => onRemove(index) }>
                                    <ImCross />
                                </div>
                                <div className="d-flex align-items-center" 
                                     style={{ marginLeft:'5px', float:'left', height:'100%' }}>
                                    Q{index+1}
                                </div>
                                <div className="d-flex align-items-center" 
                                     style={{ float:'right', height:'100%', marginRight:'10px' }}>
                                    <FaArrowRight />
                                </div>
                            </div>
                        }
        />
    )
}

export function CreateNewQuestionMenu( props )
{
    const addNew = props.addNew 
    const copyLast = props.copyLast 

    const divRef = useRef(null)

    useEffect( () => {
        divRef.current.scrollIntoView({block: "nearest", inline: "nearest", behavior:"smooth"})
    },[] )

    return (
        <div style={{ width:'100%', height:'60px', border:'0.2px solid #AEAEAE', 
                      marginBottom:'5px', borderRadius:'2px', color:'#000000',
                      padding:'10px' }}
             ref={divRef}
        >
            <div className="d-flex align-items-center" 
                 style={{ width:'100%', height:'20px', cursor:'pointer'}}
                 onClick={addNew}>
                <div>
                    <FaSquarePlus style={{color:'blue', marginRight:'5px'}} />Add new 
                </div>
            </div>
            <div className="d-flex align-items-center" 
                 style={{ width:'100%', height:'20px', cursor:'pointer' }}
                 onClick={copyLast}>
                <FaSquarePlus style={{color:'blue', marginRight:'5px'}} />Copy last
            </div>

        </div>
    )
}


