import { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

import {backendAccessGet, backendAccessPost} from '../../../Utils/Authentication'

export function ShowResults( props )
{
    const results = props.results
    const action = props.action

    return (
        <Table striped bordered hover>
             <thead>
                <tr>
                    <th style={{ width: "20px"}}>#</th>
                    <th style={{ width: "50px"}}>Code</th>
                    <th style={{ width: "300px"}}>Description</th>
                    <th style={{ width: "50px "}}>Action</th>
                </tr>
            </thead>
            <tbody>
                { results.map( (res,idx) => 
                <tr>
                    <td> {idx + 1} </td>
                    <td> { res.code } </td>
                    <td> { res.description } </td>
                    <td> <a href="#" onClick={ () => action(idx) }>Add</a> </td>
                </tr>
                )}
            </tbody>
        </Table>
    )
}

export function ShowPatientDiags( props )
{
    const diags = props.diags
    const removeFunc = props.removeFunc

    return (
         <Table striped bordered hover>
             <thead>
                <tr>
                    <th style={{ width: "20px"}}>#</th>
                    <th style={{ width: "50px"}}>Code</th>
                    <th style={{ width: "300px"}}>Description</th>
                    <th style={{ width: "50px "}}>Action</th>
                </tr>
            </thead>
            <tbody>
                { diags.map( (diag,idx) => 
                <tr>
                    <td> {idx + 1} </td>
                    <td> { diag.code } </td>
                    <td> { diag.description } </td>
                    <td> <a href="#" onClick={ () => removeFunc(idx) }>Remove</a> </td>
                </tr>
                )}
            </tbody>
        </Table>

    )
}

export default function DiagnosisViewer( props )
{
    const instanceData = props.instanceData
    const setInstanceData = props.setInstanceData
    const template = props.template

    const [ moduleType, setModuleType ] = useState(null)
    const [ searchQuery, setSearchQuery ] = useState("")
    const [ searchType, setSearchType ] = useState("keyword")
    const [ searchResults, setSearchResults ] = useState(null)

    const [ patientDiags, setPatientDiags ] = useState([])
 
    useEffect( () =>{
        setModuleType(template['module_type'])
        if( "content" in instanceData )
        {
            setPatientDiags( instanceData["content"] )
        }

    },[])

    const doSearch = () => {
        if( searchQuery == "" )
        {
            return;
        }

        var data = {}
        var url = ""

        if( searchType == "code" )
        {
            data["code"] = searchQuery
            data["data_tag"] = moduleType

            url = "/api/data-modules/diagnosis/code-search/"
        }
        else if( searchType == "keyword" )
        {
            data["keywords"] = searchQuery
            data["data_tag"] = moduleType
            url = "/api/data-modules/diagnosis/keyword-search/"
        }

        backendAccessPost( url, data ).then( res => {
            if( res == null )
            {
                return;
            }

            if( "error_message" in res )
            {
                alert( res["error_message"] )
                return
            }

            setSearchResults(res)
        })
    }

    const resetSearch = () => {
        setSearchResults(null)
        setSearchQuery("")
    }

    const addRes = (idx) => {
        if( searchResults == null )
        {
            return;
        }

        patientDiags.push( searchResults[idx] ) 
        instanceData["content"] = patientDiags
        setInstanceData( {...instanceData} )
        setPatientDiags( [...patientDiags] )

        resetSearch()
    }

    const removeDiag = (idx) => {
        patientDiags.splice(idx,1)
        instanceData["content"] = patientDiags
        setInstanceData( {...instanceData} )
        setPatientDiags( patientDiags )
    }

    return (
        <div style={{width:'100%', height:'100%', padding:'10px', overflowY:'auto'}}>
            { moduleType &&
            <>
            <div style={{ width:'100%', backgroundColor:'#F5F5F5', 
                    padding:'10px', borderRadius:'5px', 
                    marginBottom:'10px'}}>
                <div>
                    <b>{moduleType.toUpperCase()} Search</b>
                </div>
                <div>
                    <InputGroup style={{ width:'700px'}} size="sm">
                        <Form.Control
                            style={{ width:'50%'}}
                            value={searchQuery}
                            onChange={ (e) => setSearchQuery(e.target.value) }
                        />
                        <Form.Select value={searchType} onChange={ (e) => setSearchType(e.target.value)}>
                            <option value="keyword">Keyword</option>
                            <option value="code">Code</option>
                        </Form.Select>
                        <Button variant="outline-secondary"
                            onClick={ doSearch } >
                                Search
                        </Button>
                        <Button variant="outline-secondary"
                            onClick={ resetSearch } >
                                Reset
                        </Button>

                    </InputGroup>
                </div>
                { searchResults &&
                    <div style={{marginTop:'10px'}}>
                        <ShowResults results={searchResults}
                                     action={addRes}
                        />
                    </div>
                }

            </div>
            <div style={{ width:'100%', backgroundColor:'#F5F5F5', 
                    padding:'10px', borderRadius:'5px', 
                    marginBottom:'10px'}}>
                <div>
                    <b>Patient Diagnosis</b>
                </div>
                <div style={{marginTop:'10px'}}>
                    <ShowPatientDiags diags={patientDiags}
                                      removeFunc={removeDiag}
                    />
                </div>
            </div>
            </>
            }
        </div>
    )
}
