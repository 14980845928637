import { useState } from 'react'

export default function BlackActionButton( props )
{
    const onClick = props.onClick
    const [ isHover, setIsHover ] = useState(false)

    return (
        <div size="sm" 
             style={{ borderRadius:'2px', backgroundColor: (isHover) ? '#28282B':'#000000', 
                      color:'#FFFFFF', padding:'8px', cursor:'pointer', 
                      ...props.style }}
             onMouseEnter={ e => setIsHover(true) }
             onMouseLeave={ e => setIsHover(false) }
             onClick={ onClick }
        >
            { props.children }
        </div>
    )
}

