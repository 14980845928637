import { Helmet } from 'react-helmet'
import { useState, useEffect, useRef } from 'react'

import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'

import { FaArrowRight } from "react-icons/fa";
import { TbTrashXFilled } from "react-icons/tb";
import { TbTrashX } from "react-icons/tb";

import { PiEyeThin } from "react-icons/pi";
import { PiEyeSlashThin } from "react-icons/pi";
import { ImPlus } from "react-icons/im";
import { FaSquarePlus } from "react-icons/fa6";
import { FaArrowUpLong } from "react-icons/fa6";


import { backendAccessGet, backendAccessPost } from '../Utils/Authentication'
import ModuleSelector from '../Components/Misc/ModuleSelector'
import ModuleEditor from '../Components/DataModules/ModuleEditor'

/*
import DiagnosisBuilder from '../Components/DataModules/Diagnosis/Builder'
import BasicInformationBuilder from '../Components/DataModules/BasicInformation/Builder'
import ImagingBuilder from '../Components/DataModules/Imaging/Builder'
import NotesBuilder from '../Components/DataModules/Notes/Builder'
import BiobankBuilder from '../Components/DataModules/Biobank/Builder'
import LabAnalysisBuilder from '../Components/DataModules/LabAnalysis/Builder'
import TodoChecklistBuilder from '../Components/DataModules/TodoChecklist/Builder'
import PatientAssessmentBuilder from '../Components/DataModules/PatientAssessment/Builder'
import SurveyBuilder from '../Components/DataModules/Survey/Builder'
import MiscBuilder from '../Components/DataModules/Miscellaneous/Builder'
*/

function fixLanguage( data, languages )
{
    if( data.primary_language == "swedish" || data.primary_language == "svenska" )
    {
        data.primary_language = "Swedish"
    }

    data.languages = languages

    return data
}

const data_modules_info = [
    { 'name' : 'Diagnosis',
      'type' : 'diagnosis' },
    { 'name' : 'Basic Information',
      'type' : 'basic_info' },
    { 'name' : 'Imaging',
      'type' : 'imaging' },
    { 'name' : 'Notes',
      'type' : 'note' },
    { 'name' : 'Biobank',
      'type' : 'bio_bank'},
    { 'name' : 'Lab Analysis',
      'type' : 'lab_analysis' },
    { 'name' : 'Todo Checklist',
      'type' : 'todo_checklist' },
    { 'name' : 'Patient Assessment',
      'type' : 'patient_assessment'},
    { 'name' : 'Survey',
      'type' : 'survey' },
    { 'name' : 'Miscellaneous',
      'type' : 'misc' }
]

export function ModuleButton( props )
{
    const moduleId = props.moduleId 
    const moduleIdx = props.moduleIdx
    const moduleName = props.moduleName
    const selectedModuleIdx = props.selectedModuleIdx
    const selectedModuleId = props.selectedModuleId
    const hidden = props.hidden

    const selectModule = props.selectModule 
    const toggleHidden = props.toggleHidden

    return (
        <div style={{ width:'100%', height:'35px', marginBottom:'5px', cursor:'pointer' }}>
            <div style={{ backgroundColor:'#FFF', width:'30px', height:'35px', float:'left',
                          borderTopLeftRadius:'2px',
                          borderBottomLeftRadius:'2px'}}
                onClick={ e => toggleHidden(moduleId) }
            >
                <ModuleSelector check={ hidden == 0 }
                    childTrue={<div className="d-flex justify-content-center align-items-center" 
                                    style={{ height:'100%', float:'left', marginRight:'5px' }}>
                                    <PiEyeThin style={{ fontSize:24, marginLeft:'4px' }} />
                                </div>}
                    childFalse={<div className="d-flex justify-content-center align-items-center" 
                                     style={{ height:'100%', float:'left', marginRight:'5px' }}>
                                    <PiEyeSlashThin style={{ fontSize:24, marginLeft:'4px' }}/>
                                </div>}
                /> 
            </div>
            <div style={{ backgroundColor:( selectedModuleId == moduleId ) ? '#000000':'#FFFFFF', 
                          color: ( selectedModuleId != moduleId ) ? '#000000':'#FFFFFF',
                          padding:'8px', 
                          width:'calc(100% - 30px)',
                          height:'35px', float:'right',
                          borderTopRightRadius:'2px',
                          borderBottomRightRadius:'2px'}}
                 onClick={ e => selectModule(moduleId) }
            > 
                <ModuleSelector check={ selectedModuleId == moduleId }
                                childTrue={
                                    <div className="d-flex align-items-center" 
                                         style={{ float:'left', height:'100%', color: "#FFFFFF"}} >
                                        { moduleName }
                                    </div>
                                }

                                childFalse={
                                    <div className="d-flex align-items-center" 
                                         style={{ float:'left', height:'100%', 
                                                  color: (hidden) ? "#DADADA" : "#000000"}} >
                                        { moduleName }
                                    </div>

                                }
                />
                { selectedModuleId == moduleId && 
                <div className="d-flex align-items-center" 
                     style={{ float:'right', height:'100%', 
                              fontSize:16, marginRight:'5px'}}>
                    <FaArrowRight />
                </div>
                }
            </div>
        </div>
    )
}

export function CategorySelect( props )
{
    const selectedCategory = props.selectedCategory 
    const setSelectedCategory = props.setSelectedCategory

    return (
        <div className="d-flex justify-content-center align-items-center" 
             style={{ width:'100%', height:'50px', padding:'5px' }}>
            <Form.Select style={{ borderRadius:'2px'}} 
                         value={selectedCategory} 
                         onChange={e => setSelectedCategory(e.target.value)}
            >
                <option value="" disabled selected> -- Data category -- </option>
                { data_modules_info.map( ( dm, idx ) =>
                <option value={dm.type}>{dm.name}</option>
                )}
            </Form.Select>
        </div>
    )
}

export function EditMode( props )
{
    const selectedCategory = props.selectedCategory 
    const setSelectedCategory = props.setSelectedCategory

    const dataCategoryRef = useRef(null)

    const dataModules = props.dataModules 
    const setDataModules = props.setDataModules

    const setCreateModeActive = props.setCreateModeActive
    const createWithId = props.createWithId
    const toggleHidden = props.toggleHidden
    
    const [ activeModules, setActiveModules ] = useState([])
    const [ inActiveModules, setInactiveModules ] = useState([])

    const [ selectedModuleId, setSelectedModuleId ] = useState(null)
    const [ selectedModuleVersion, setSelectedModuleVersion ] = useState(null)
    const [ selectedModuleIdx, setSelectedModuleIdx ] = useState( -1 )

    const [ selectedModuleData, setSelectedModuleData ] = useState( null )
    const selectedModuleDataRef = useRef( null )
    const [ hasChange, setHasChange ] = useState(false)

    const resetSelectedModule = ( module_id ) => {
        let data = {}
        data.module_id = module_id

        backendAccessPost('/api/data-modules/get-module-data/', data).then( res => {
            if( res.error_message )
            {
                alert( res.error_message )
                return
            }

            let module_version = res.data.version 
            let module_data = JSON.parse(res.data.module_data)
            
            if( selectedCategory == 'survey' || selectedCategory == 'patient_assessment' )
            {
                module_data = fixLanguage( module_data, ['English', 'Swedish', 'German'] )
            }

            selectedModuleDataRef.current = null        
            setSelectedModuleVersion( module_version )
            setSelectedModuleData( module_data )
            setSelectedModuleId( module_id )
        })
    }

    const selectModule = ( module_id ) => {
        resetSelectedModule( module_id )
    }

    
    const updateModule = () => {
        let data = {}
        data['module_id'] = selectedModuleId 
        data['module_data'] = JSON.stringify(selectedModuleData)

        backendAccessPost('/api/data-modules/update-module-data/', data ).then( res => {
            if( res.error_message )
            {
                alert( res.error_message )
                return
            }

            setSelectedModuleVersion( res.version )
            selectedModuleDataRef.current = JSON.stringify( selectedModuleData )
            setHasChange( false )

        })
    }

    const discardChanges = () => {
        let data = JSON.parse( selectedModuleDataRef.current)
        setSelectedModuleData( data )
    }

    useEffect( () => {
        setSelectedModuleId( null )
        setSelectedModuleIdx( -1 )

        setSelectedModuleData(null)
        selectedModuleDataRef.current = null 
        setHasChange( false )
        dataCategoryRef.current = selectedCategory
    },[selectedCategory])

    useEffect( () => {
        let active_modules = []
        let inactive_modules = []


        for( let d of dataModules )
        {
            if( d.hidden == 0 )
            {
                active_modules.push(d)
            }
            else 
            {
                inactive_modules.push(d)
            }
        }

        setActiveModules( active_modules )
        setInactiveModules( inactive_modules )

    }, [dataModules])

    useEffect( () => {
        if( selectedModuleData == null )
        {
            return
        }

        let dataJson = JSON.stringify(selectedModuleData)

        if( selectedModuleDataRef.current == null )
        {
            selectedModuleDataRef.current = dataJson 
            setHasChange(false)
            return
        }

        if( dataJson == selectedModuleDataRef.current )
        {
            setHasChange( false )
        }
        else
        {
            setHasChange( true )
        }
    },[selectedModuleData])

    return (
        <div style={{ height:'100%' , width:'100%' }}>
            <div style={{ height:'100%', width:'400px', float:'left' }}>
                <CategorySelect selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                /> 
                <div style={{ height:'calc(100% - 50px)', width:'100%', padding:'5px'}}>
                    <div style={{ height:'100%', width:'100%', backgroundColor:'#F6F6F6', borderRadius:'2px',
                                  padding:'20px'}}>
                        { selectedCategory &&
                        <>
                        <div className="d-flex align-items-center" 
                             style={{ marginBottom:'5px', height:'40px', marginLeft:'10px'}}>
                            <b>Modules</b>
                        </div>
                        <div style={{ height:'calc(100% - 125px)', width:'100%', overflowY:'scroll'}}>
                            { activeModules.map( ( module, idx ) => 
                            <ModuleButton moduleId={module.module_id} 
                                          moduleIdx={idx}
                                          selectedModuleIdx={selectedModuleIdx}
                                          selectedModuleId={selectedModuleId}
                                          moduleName={module.module_name} 
                                          hidden={module.hidden}
                                          selectModule={selectModule}
                                          toggleHidden={toggleHidden}/> 
                            )}
                            { inActiveModules.map( ( module, idx ) => 
                            <ModuleButton moduleId={module.module_id} 
                                          moduleIdx={idx}
                                          selectedModuleIdx={selectedModuleIdx}
                                          selectedModuleId={selectedModuleId}
                                          moduleName={module.module_name} 
                                          hidden={module.hidden}
                                          selectModule={selectModule}
                                          toggleHidden={toggleHidden}/> 
                            )}
                        </div>
                        <div style={{ height:'80px', width:'100%', padding:'5px' }}>
                            <ModuleSelector check={hasChange}
                                            childFalse={<div style={{ height:'60px', width:'100%', padding:'5px'}}>
                                                            <Button style={{ width:'100%', borderRadius:'2px'}}
                                                                    onClick={ e => setCreateModeActive(true) }
                                                            >
                                                                Create a new module
                                                            </Button>
                                                        </div>}
                                            childTrue={<div style={{ height:'60px', width:'100%', padding:'5px'}}>
                                                            <Button variant="danger" style={{ width:'100%', 
                                                                                          borderRadius:'2px', 
                                                                                          marginBottom:'10px' }} 
                                                                    onClick={updateModule}
                                                            >
                                                                Save changes
                                                            </Button>
                                                            <div className='d-flex justify-content-center align-items-center' 
                                                                 style={{ width:'100', color:'blue' }}>
                                                                <div style={{ cursor:'pointer' }} onClick={discardChanges} >
                                                                    Discard changes
                                                                </div>
                                                            </div>  
                                                        </div>}
                            />
                        </div>
                        </>
                        }
                        { selectedCategory == null &&
                        <div style={{ width:'100%', fontSize:14 }}>
                            <div className="d-flex justify-content-center" style={{ width:'100%', marginBottom:'10px'}}>
                                <FaArrowUpLong />
                            </div>
                            <div className="d-flex justify-content-center fw-bolder" style={{ width:'100%'}}>
                                Choose a data category
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div style={{ height:'100%', width:'calc(100% - 400px)', float:'right' }}>
                { ( selectedCategory && selectedModuleId && selectedCategory == dataCategoryRef.current ) &&
                <ModuleEditor category={selectedCategory} 
                              moduleId={selectedModuleId}
                              moduleData={selectedModuleData}
                              moduleVersion={selectedModuleVersion}
                              setModuleData={setSelectedModuleData}
                              hasChange={hasChange}
                              createWithId={createWithId}
                />
                }
            </div>
        </div>
    )
}

export function CreateMode( props )
{
    const selectedCategory = props.selectedCategory 
    const setSelectedCategory = props.setSelectedCategory
    const dataFields = props.dataFields
    const createWithIdRef = props.createWithIdRef
    const setCreateModeActive = props.setCreateModeActive
    const closeCreateMode = props.closeCreateMode

    const [ moduleName, setModuleName ] = useState("")
    const [ moduleData, setModuleData ] = useState(null)

    useEffect( () => {
        setModuleName("")
        setModuleData(null)
    }, [selectedCategory])

    const addItem = ( params ) => {
        let dd = structuredClone(moduleData)
        dd.push( params )
        setModuleData(dd)
    } 

    useEffect( () => {
        if( moduleData == null && createWithIdRef.current != null )
        {
            let data = { module_id : createWithIdRef.current }
            backendAccessPost('/api/data-modules/get-module-data/', data ).then( res => {
                if( res.error_message )
                {
                    alert( res.error_message )
                    return
                }

                let mdata = JSON.parse(res.data.module_data)

                if( selectedCategory == 'survey' || selectedCategory == 'patient_assessment' )
                {
                    mdata.languages = [ 'English', 'German', 'Swedish' ]
                }

                setModuleData( mdata )
                createWithIdRef.current = null 
            })
        }
        else if( moduleData == null )
        {
            if( selectedCategory == 'survey' || selectedCategory == 'patient_assessment' )
            {
                let dd = {}
                dd.final_notes = {}
                dd.introduction = {}
                dd.languages = [ 'English', 'German', 'Swedish' ]
                dd.primary_language = dd.languages[0]
                dd.questions = []
                dd.setSecondaryLanguage = null 
                dd.title = {}
                setModuleData(dd)
            }
            else if( selectedCategory == "todo_checklist" )
            {
                let dd = {}
                dd.array = []

                setModuleData(dd)
            }
            else 
            {
                setModuleData([])
            }
        }
    },[ moduleData] )

        
    const createNewModule = () => {
        let data = {}
        data.module_name = moduleName
        data.module_category = selectedCategory
        data.module_data = JSON.stringify(moduleData)

        backendAccessPost('/api/data-modules/add-module-data/', data).then( res => {
            if( res.error_message )
            {
                alert( res.error_message )
                return 
            }

            closeCreateMode( res.data_modules )
        })
    }


    if( moduleData == null )
    {
        return (<></>)
    }

    return (
        <div style={{ height:'100%' , width:'100%' }}>
            <div style={{ height:'100%', width:'400px', float:'left' }}>
                <CategorySelect selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                /> 
                <div style={{ height:'calc(100% - 50px)', width:'100%', padding:'5px'}}>
                    <div style={{ height:'100%', width:'100%', backgroundColor:'#F6F6F6', borderRadius:'2px',
                                  padding:'20px'}}>
                        <div className="d-flex align-items-center justify-content-center" 
                             style={{ marginBottom:'5px', height:'40px', marginLeft:'10px'}}>
                            <b>Create a new module</b>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" 
                             style={{ marginBottom:'5px', height:'40px', marginLeft:'10px', marginBottom:'10px'}}>
                            <Form.Control placeHolder="Module Name"
                                          value={moduleName}
                                          onChange={ e => setModuleName( e.target.value )}
                                          style={{ borderRadius:'2px' }}
                            />
                        </div>

                        <div style={{ height:'calc(100% - 175px)', width:'100%', overflowY:'scroll'}}>
                            { Object.keys(dataFields).map( (key,idx) => 
                            <div className="fw-light" style={{ cursor:'pointer'}}
                                 onClick={ e => addItem( dataFields[key] )}>
                                <FaSquarePlus style={{ color:'#0057FF', marginRight:'10px'}}/>
                                { dataFields[key].name }
                            </div>
                            )}
                        </div>
                        <div style={{ height:'80px', width:'100%', padding:'5px'}}>
                            <div style={{ height:'60px', width:'100%', padding:'5px'}}>
                                <Button style={{ width:'100%', 
                                        borderRadius:'2px', 
                                        marginBottom:'10px' }} 
                                        disabled={moduleName == ""}
                                        onClick={createNewModule}
                                >
                                    Create
                                </Button>
                                <div className='d-flex justify-content-center align-items-center' 
                                     style={{ width:'100', color:'blue' }}>
                                    <div style={{ cursor:'pointer' }} 
                                         onClick={ e => setCreateModeActive(false) }>
                                        Discard
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height:'100%', width:'calc(100% - 400px)', float:'right' }}>
                <ModuleEditor category={selectedCategory} 
                              moduleId=""
                              moduleVersion={0}
                              moduleData={moduleData}
                              setModuleData={setModuleData}
                              hasChange={false}
                              canRemove={true}
                />

            </div>
        </div>
    )
}

export default function ModuleBuilder ( props )
{
    const [ selectedCategory, setSelectedCategory ] = useState( props.selectedCategory )

    const [ dataModules, setDataModules ] = useState([])
    const [ dataFields, setDataFields ] = useState({})

    const [ createModeActive, setCreateModeActive ] = useState(false)
    const createWithIdRef = useRef(null)

    useEffect(() => {
        if( selectedCategory == "" || selectedCategory == null )
        {
            return
        }

        let data = {}
        data['category'] = selectedCategory 

        backendAccessPost('/api/data-modules/get/', data).then( res => {
            if( 'error_message' in res )
            {
                alert( res.error_message )
                return
            }

            setDataModules( res.data_modules )
            if( res.data_fields )
            {
                setDataFields( res.data_fields )
            }
            else 
            {
                setDataFields( {} )
            }
        })

    },[selectedCategory])

    const createWithId = ( module_id ) => {
        createWithIdRef.current = module_id 
        setCreateModeActive(true)
    }

    const toggleHidden = ( module_id ) => {

        let data = {}
        data.module_id = module_id 
        data.module_category = selectedCategory 
        
        backendAccessPost('/api/data-modules/toggle-hidden/', data ).then( res => {
            if( 'error_message' in res )
            {
                alert( res.error_message )
                return 
            }

            setDataModules( res.data_modules )
        }) 
    }

    const closeCreateMode = ( newDataModules ) => {
        setDataModules( newDataModules )
        setCreateModeActive(false)
    }

    return (
        <>
        <Container style={{ maxWidth:'1400px', height:'calc(100vh - 65px)', fontSize:12, 
                            marginTop:'5px' }}> 
            <ModuleSelector check={createModeActive && selectedCategory != null} 
                            childTrue={
                                <CreateMode selectedCategory={selectedCategory}
                                            setSelectedCategory={setSelectedCategory}
                                            dataFields={dataFields}
                                            createWithIdRef={createWithIdRef}
                                            setCreateModeActive={setCreateModeActive}
                                            closeCreateMode={closeCreateMode}
                                />
                            }
                            childFalse={
                                <EditMode selectedCategory={selectedCategory}
                                          setSelectedCategory={setSelectedCategory}
                                          dataModules={dataModules}
                                          setDataModules={setDataModules}
                                          setCreateModeActive={setCreateModeActive}
                                          createWithId={createWithId}
                                          toggleHidden={toggleHidden}
                                />
                            }
            />
        </Container>
        </>
    )
}
