import { Helmet } from 'react-helmet'
import Container from 'react-bootstrap/Container'
import { useState, useEffect } from 'react'

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import Freezers from '../Components/Biobank/Freezers'
import Racks from '../Components/Biobank/Racks'
import Boxes from '../Components/Biobank/Boxes'
import Samples from '../Components/Biobank/Samples'
import Unplaced from '../Components/Biobank/Unplaced'


const modules_info = [
    { 'name' : 'Freezers',
      'type' : 'freezers' },
    { 'name' : 'Racks',
      'type' : 'racks' },
    { 'name' : 'Boxes',
      'type' : 'boxes' },
    { 'name' : 'Samples',
      'type' : 'samples' },
    { 'name' : 'Unplaced',
      'type' : 'unplaced' }
]

export function BiobankOld ()
{
    const [ selectedModule, setSelectedModule ] = useState(null)

    return (
        <>
            <Helmet>
                <title> ETD Clinic | Biobank </title>
            </Helmet>

            <div style={{ height: 'calc(100vh - 60px)', width:"100%"}}>
                <div style={{ height:'100%', width:'250px', backgroundColor:'#F5F5F5', fondSize:'14',
                    float:'left', padding:'10px' }}>
                    { modules_info.map( (module) =>
                    <>
                        { module.type == selectedModule &&
                        <div className="d-flex align-items-center justify-content-center"
                             style={{ padding: "10px", backgroundColor: "#D3D3D3", borderRadius: '5px',
                             marginBottom: '2px'}}>
                      
                            <b>{  module.name }</b>
                        </div>
                        }
                        { module.type != selectedModule &&
                        <div className="d-flex align-items-center justify-content-center"
                             style={{ padding: "10px", backgroundColor: "#E8E8E8", borderRadius: '5px',
                                      marginBottom: '2px'}}
                             onClick={ () => setSelectedModule(module.type) }
                                >
                                        <b>{module.name}</b>
                        </div>

                        }
                    </>
                )}

                </div>
                <div style={{ height:'100%', width:'calc(100% - 250px)', fontSize:'14',
                              float:'right' }}>
                    { selectedModule == "freezers" &&
                        <Freezers />
                    }
                    { selectedModule == "racks" &&
                        <Racks />
                    }
                    { selectedModule == "boxes" &&
                        <Boxes />
                    }
                    { selectedModule == "samples" &&
                        <Samples />
                    }
                    { selectedModule == "unplaced" &&
                        <Unplaced />
                    }
                </div>
            </div>
        </>
    )
}

export default function Biobank ()
{
    const [ key, setKey ] = useState("samples")

    return (
        <>
            <div style={{ height: 'calc(100vh - 60px)', width:"100%", overflowY:'scroll', paddingTop:'10px'}}>
                <Tabs activeKey={key}
                      onSelect={ (k) => setKey(k) }
                      justify
                >
                    <Tab eventKey="freezers" title="Freezers">
                        { key == "freezers" &&
                        <Freezers />
                        }
                    </Tab>
                    <Tab eventKey="racks" title="Racks">
                        { key == "racks" &&
                        <Racks />
                        }
                    </Tab>
                    <Tab eventKey="samples" title="Samples">
                        { key == "samples" &&
                        <Samples />
                        }
                    </Tab>
                    <Tab eventKey="unplaced" title="Unplaced Samples">
                        { key == "unplaced" &&
                        <Unplaced />
                        }
                    </Tab>


                </Tabs>
            </div>
        </>
    )
}
