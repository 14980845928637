import { useState } from 'react'

export default function MenuButton( props )
{
    const title = props.title
    const onClick = props.onClick 

    const [ isHover, setIsHover ] = useState( false )

    return (
        <div className="d-flex align-items-center" 
             style={{ width:'100%', height:'30px', marginBottom:'10px', fontSize:12, borderRadius:'2px',
                      border:'0.2px solid #DADADA', 
                      backgroundColor:(isHover) ? '#000000' : '#FFFFFF',
                      color:(isHover) ? '#FFFFFF' : '#000000',
                      cursor:'pointer'
                    }}
             onMouseEnter={ e => setIsHover(true)}
             onMouseLeave={ e => setIsHover(false)}
             onClick={ onClick }
        >
            <div style={{ marginLeft:'20px'}}>
                { title }
            </div>
        </div>
    )
}

