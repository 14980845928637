import { useState, useEffect } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

import { backendAccessPost, backendAccessGet } from '../../Utils/Authentication'


export default function Freezers( props )
{
    const [ name, setName ] = useState(null)
    const [ capacity, setCapacity ] = useState(null)
    const [ freezers, setFreezers ] = useState(null)

    useEffect( () => {
        backendAccessGet("/api/biobank/freezers-list/").then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                return
            }
            setFreezers( res["freezers"] )

        })
    },[])

    const addFreezer = () => {
        if( capacity != null && name != null )
        {
            var data = {}
            data["freezer_name"] = name
            data["capacity"] = capacity

            backendAccessPost("/api/biobank/add-freezer/", data).then( res => {
                if("error_message" in res)
                {
                    alert( res["error_message"] )
                    return
                }

                setFreezers( res["freezers"] )

                setCapacity(null)
            })
        }
    }

    const downloadCSV = ( freezer_id ) => {
        var csv_data = "";
        csv_data += "freezer_id\n"
        csv_data += freezer_id + "\n";

        var data = new Blob([csv_data], {type: 'text/csv'});
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', freezer_id + ".csv");
        tempLink.click();
    }

    return (
        <div style={{ height:"100%", width:"100%", fontSize:12}}>
            <div className="d-flex justify-content-center" 
                 style={{ height:'150px', width:'100%', padding:'10px'}}>
                <div style={{ height:'100%', width:'300px' }}>
                    <div style={{ width:'100%', paddingBottom:'10px', borderBottom:'0.2px solid #DADADA'}}>
                        <Form.Control placeHolder="Freezer name" 
                                      value={ name } 
                                      onChange={ (e) => setName(e.target.value) }
                                      style={{ width:'100%', border:'none', 
                                               borderRadius:'0px' }} 
                                      size="sm"
                        /> 
                    </div>
                    <div  style={{ width:'100%', paddingBottom:'10px', paddingTop:'5px' }}>
                        <Form.Control placeHolder="Number of racks in freezer" 
                                      value={ capacity } 
                                      onChange={ (e) => setCapacity(e.target.value) }
                                      style={{ width:'100%', border:'none', 
                                               borderRadius:'0px' }}
                                      size="sm"
                        /> 
                    </div>
                    <div className="d-flex justify-content-start" style={{ width:'100%' }}>
                        <Button size="sm" onClick={addFreezer}
                                style={{ borderRadius:'2px'}}>Add Freezer</Button>
                    </div>
                </div>
            </div>
            <div style={{ height:"calc(100% - 80px)", width:"100%", padding:'10px'}}>
                { freezers &&
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ width: "20px"}}>#</th>
                            <th style={{ width: "80px"}}>Freezer Name</th>
                            <th style={{ width: "80px"}}>Freezer ID</th>
                            <th style={{ width: "50px"}}>Version</th>
                            <th style={{ width: "50px"}}>Label</th>
                        </tr>
                    </thead>
                    <tbody>
                    { freezers.map( (f,idx) => 
                        <tr>
                            <td> {idx + 1} </td>
                            <td> { f.freezer_name } </td>
                            <td> { f.freezer_id } </td>
                            <td> { f.version } </td>
                            <td> <Button size="sm" variant="secondary"
                                         onClick={ e => downloadCSV(f.freezer_id) }
                                         size="sm"
                                         style={{ borderRadius:'2px'}}
                                 >
                                    Download CSV
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                }
            </div>
        </div>
    )
}
