import { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import ReactMarkdown from 'react-markdown'
import FileViewer from './FileViewer'

const instructions_markdown = `
#### Login to the Canfield computer [Freiburg]
 1. Username : .\\User and Password : no password
 2. If User is visible : User -> No password login
 3. If Other User is visible : Username : ./User -> no password 
#### Instructions
 1. Open "ktd_image_uploader" application on the Canfield computer
 2. Browse and select the capture directory : D:\\Databases\\ DermaGraphix \\ [Patient Captures] \\ [Current Capture]
    - Example :
 3. Verify the local data
 4. Insert login credentials if needed
 5. Send data
`


export default function Vectra360Viewer( props )
{
    const instanceData = props.instanceData
    const setInstanceData = props.setInstanceData
    const template = props.template
    const version = props.version
    const status = props.status
    const reference = props.reference
    const createPlaceHolder = props.createPlaceHolder
    const dumpEmpty = props.dumpEmpty
    const reload = props.reload

    const [ data, setData ] = useState({})

    useEffect( () => {
        var d = { ...instanceData }

        console.log( instanceData )

        if( d["content"] == null )
        {
            d["content"] = ""
        }

        setData(d)
    }, [])

    const update = ( value ) => {
        data["content"] = value
        setData( { ...data } )
        setInstanceData( {...data} )
    }

    return (
        <div style={{ height:'100%', width:'100%', padding:'10px', overflowY:'auto' }}>
        { version == 0 &&
            <div>
                <center>
                <div style={{marginRight:'3px'}}>
                    <Button variant="secondary" onClick={createPlaceHolder}>Get Upload Reference</Button>
                </div>
                </center>
                <div>
                    <ReactMarkdown style={{width:'100%'}}>{instructions_markdown}</ReactMarkdown>
                </div>

            </div>
            
        }

        { version > 0 &&
            <>
            { status == 1 &&
                <div>
                    <center>
                    <div>
                        <h3>Upload reference</h3>
                    </div>
                    <div>
                        <h1>{reference}</h1>
                    </div>
                    <div> 
                        <Button variant="secondary" onClick={reload}>Reload</Button>
                    </div>
                    </center>
                    <div>
                        <ReactMarkdown style={{width:'100%'}}>{instructions_markdown}</ReactMarkdown>
                    </div>
                </div>
            }

            { status>1 &&
            <>
                { instanceData['files'] &&
                <>
                    { instanceData['files'].map( (item,idx) =>
                    <FileViewer tag={item.tag}
                                reference={item.reference}
                                key={idx}
                    />
                    )}
                </>
                }
            </>
            }
            </>
        }

        </div>
    )
}
