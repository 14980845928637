import { useState, useEffect } from 'react' 
import Form from 'react-bootstrap/Form'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'

import { FaRegPlusSquare } from "react-icons/fa";
import { ImCross } from "react-icons/im"
import { ImPlus } from "react-icons/im";

import DNDListVertical from '../Misc/DNDListVertical'
import { getText, putText } from './DataAccess'
import { TextLang, TextAreaLang, TextArea, QuestionButton, ValueBox } from './SurveyComponents'
import ModuleSelector from '../Misc/ModuleSelector'
import { CreateNewQuestionMenu } from './SurveyComponents'

export function AnswerEditor( props )
{
    const data = props.data 
    const setData = props.setData 
    const primaryLanguage = props.primaryLanguage
    const secondaryLanguage = props.secondaryLanguage
    const reference = props.reference 
    const partIdx = props.partIdx
    const answerIdx = props.answerIdx

    const removeAnswer = props.removeAnswer

    const getValue = () => {
        let val = data[reference[0]][reference[1]].parts[partIdx].answers[answerIdx].value
        return val
    }

    const setValue = (val) => {
        let dd = structuredClone(data)
        dd[reference[0]][reference[1]].parts[partIdx].answers[answerIdx].value = val 
        setData(dd)
    }

    return (
        <div style={{ width:'100%', height:'30px', marginBottom:'10px' }}>
            <div className="d-flex justify-content-start align-items-center" 
                 style={{ float:'left', width:'20px', height:'100%'}}>
                <div style={{ marginTop:'10px', cursor:'pointer' }} 
                     onClick={ e => removeAnswer(answerIdx) }>
                    <ImCross  />
                </div>
            </div>

            <div style={{ float:'left', width:'calc(100% - 70px)', height:'100%'}}>
                <TextAreaLang placeHolder="Answer"
                              data={data}
                              setData={setData}
                              primaryLanguage={primaryLanguage}
                              secondaryLanguage={secondaryLanguage}
                              reference={[ reference[0], reference[1], partIdx, 'answers', answerIdx ]}
                />
            </div>
            <div style={{ float:'right', width:'50px', height:'100% '}}>
                <ValueBox placeHolder="0"
                          getValue={ getValue }
                          setValue={ setValue }
                /> 
            </div>
        </div>
    )

}

export function QuestionPartEditor( props )
{
    const data = props.data 
    const setData = props.setData 
    const primaryLanguage = props.primaryLanguage
    const secondaryLanguage = props.secondaryLanguage
    const reference = props.reference 
    const partIdx = props.partIdx

    const removeAnswer = ( answerIdx ) => {
        let dd = structuredClone( data )
        let answers = dd[reference[0]][reference[1]].parts[partIdx].answers
        answers.splice(answerIdx,1)
        dd[reference[0]][reference[1]].parts[partIdx].answers = answers 
        setData(dd)
    }

    const addAnswer = () => {
        let dd = structuredClone( data )
        let answers = dd[reference[0]][reference[1]].parts[partIdx].answers

        let answerTemp = {}
        answerTemp.position = false 
        answerTemp.second_title = false 
        answerTemp.text = {}
        answerTemp.value = "" 

        answers.push( answerTemp )

        dd[reference[0]][reference[1]].parts[partIdx].answers = answers 
        setData(dd)
    }

    return (
        <div>
            <div style={{ width:'100%', marginTop:'10px', padding:'10px' }}>
                <TextAreaLang placeHolder="Question Title"
                              data={data}
                              setData={setData}
                              primaryLanguage={primaryLanguage}
                              secondaryLanguage={secondaryLanguage}
                              reference={[ reference[0], reference[1],partIdx,'title' ] } 
                />
            </div>
            <div style={{ width:'100%', padding:'10px'}}>
                <div className="fw-bolder" style={{ float:'left'}}>
                    Answers
                </div>
                <div style={{ float:'right', marginRight:'15px'}}>
                    Value
                </div>
            </div>
            <div style={{ width:'100%', padding:'10px'}}>
                { data[reference[0]][reference[1]].parts[partIdx].answers.map(( answer, answerIdx ) =>
                    <AnswerEditor data={data}
                                  setData={setData}
                                  primaryLanguage={primaryLanguage}
                                  secondaryLanguage={secondaryLanguage}
                                  reference={reference}
                                  partIdx={partIdx}
                                  answerIdx={answerIdx}
                                  removeAnswer={removeAnswer}
                                  key={answerIdx}
                    />
                )}
                <div className="flex align-items-center" style={{ width:'100%', 
                                                                  cursor:'pointer', 
                                                                  marginTop:'20px' }}>
                    <div className="fw-bolder" style={{ width:'100px', float:'right' }}
                         onClick={addAnswer}>
                        <ImPlus /> <b style={{ marginLeft:'5px'}}>Add Answer</b>
                    </div>
                </div>
                
            </div>

        </div>
    )
}

export function QuestionEditor( props )
{
    const data = props.data 
    const setData = props.setData 
    const primaryLanguage = props.primaryLanguage 
    const secondaryLanguage = props.secondaryLanguage 
    const reference = props.reference

    const [key, setKey] = useState('part1');
    const [value, setValue] = useState(null)

    useEffect( () => {
        console.log( data[reference[0]][reference[1]] )
    },[])

    const getVariable = () => {
        return (data[reference[0]][reference[1]].variable) ? data[reference[0]][reference[1]].variable : "" 
    }

    const updateVariable = ( value ) => {
        let dd = structuredClone(data)
        dd[reference[0]][reference[1]].variable = value 
        setData(dd)
    }

    const updateMultiSelection = ( checked ) => {
        let dd = structuredClone(data)
        dd[reference[0]][reference[1]].multi_selections = ( checked ) ? "yes" : "no" 
        setData(dd)

    }

    const addPart = () => {
        let dd = structuredClone( data )
        let parts = dd[reference[0]][reference[1]].parts 

        let partTemp = {}
        partTemp.answers = []
        partTemp.range = null 
        partTemp.textarea = false 
        partTemp.textbox = false 
        partTemp.title = {}

        parts.push( partTemp )

        dd[reference[0]][reference[1]].parts = parts 
        dd[reference[0]][reference[1]].nparts = parts.length

        setData(dd)

        return parts.length 
    }

    const removePart = ( partIdx ) => {
        let dd = structuredClone( data )
        let parts = dd[reference[0]][reference[1]].parts 
        parts.splice( partIdx, 1 )
        dd[reference[0]][reference[1]].parts = parts
        dd[reference[0]][reference[1]].nparts = parts.length
        setData(dd)
        setKey( 'part' + partIdx )
    }

    useEffect( () => {
        if( key == "add_part" )
        {
            let idx = addPart()
            setKey( 'part' + idx )
        }
    },[key])

    return (
        <div style={{ width:'100%', height:'100%', overflowY:'scroll'}}>
            <div className="d-flex align-items-center" style={{ height:'50px', width:'100%'}}>
                <div style={{ width:'100%'}}>
                    <div className="fw-bolder" style={{ float:'left', width:'90px', 
                                                        marginLeft:'10px', marginRight:'10px'}}>
                        Question {reference[1]+1}
                    </div>
                    <div className="fw-bolder" style={{ float:'left', marginLeft:'10px', marginTop:'-5px'}}>
                        <Form.Control size="sm" style={{ fontSize:12, outline:'none', border:'none', 
                                                     borderBottom: '0.2px solid #DADADA', borderRadius:'0px'}}
                                      value={getVariable()}
                                      onChange={ e => updateVariable(e.target.value)}
                                      placeHolder="Variable Name"
                        />
                    </div>
                    <div style={{ float:'right' }}>
                        <Form.Check label="Multiple selections"
                                    style={{ marginRight:'10px' }}
                                    checked={data[reference[0]][reference[1]].multi_selections == "yes"}
                                    onChange={ e => updateMultiSelection( e.target.checked ) }
                        />
                    </div>
                </div>
            </div>
            <div style={{ width:'100%', height:'calc(100% - 50px)', marginTop:'-7px', overflowY:'scroll' }}>
                <Tabs activeKey={key}
                      onSelect={(k) => setKey(k)}
                >
                    { data[reference[0]][reference[1]].parts.map( (part,pidx) => 
                    <Tab eventKey={'part' + (pidx+1)} 
                         title={<div>
                                    <div style={{ float:'left'}} onClick={e => removePart(pidx)}>
                                        <ImCross />
                                    </div>
                                    <div style={{ float:'right', marginLeft:'5px' }}>
                                        Part  {pidx+1}
                                    </div>
                                </div>} 
                         key={pidx}>
                        <QuestionPartEditor data={data}
                                            setData={setData}
                                            primaryLanguage={primaryLanguage}
                                            secondaryLanguage={secondaryLanguage}
                                            reference={reference}
                                            partIdx={pidx}
                        />
                    </Tab>
                    )}
                    <Tab eventKey="add_part" title={<ImPlus style={{ color:'black' }}/>}>
                    </Tab>

                </Tabs>
            </div>
        </div>
    )
}

export default function SurveyEditor( props )
{
    const moduleId = props.moduleId
    const data = props.data 
    const setData = props.setData 
    const primaryLanguage = props.primaryLanguage 
    const secondaryLanguage = props.secondaryLanguage

    const [ selectedQuestionIdx, setSelectedQuestionIdx ] = useState(-1)

    const [ showAddMenu, setShowAddMenu ] = useState(false)

    useEffect( () => {
        setSelectedQuestionIdx(-1)
        setShowAddMenu(false)
    },[moduleId])


    const idBuilder = ( index, item ) => {
        return index.toString() 
    }

    const onReorder = ( list, startIndex, endIndex ) => {
        var l = structuredClone(data.questions)

        const [removed] = l.splice(startIndex, 1)
        l.splice(endIndex, 0, removed);

        data.questions = l 
        setData( {...data} )
        setSelectedQuestionIdx(endIndex)
    }

    const onRemove = ( index ) => {
        var l = structuredClone(data.questions)
        const [removed] = l.splice(index, 1); 

        data.questions = l 
        setData( {...data} )
    }

    const addNewQuestion = () => {
        let questions = structuredClone(data.questions)

        let questionTemp = {}
        questionTemp.multi_selections = "no"
        questionTemp.nparts = 0 
        questionTemp.part_trigger = null 
        questionTemp.parts = []
        questionTemp.variable = ""

        let partTemp = {}
        partTemp.answers = []
        partTemp.range = null 
        partTemp.textarea = false 
        partTemp.textbox = false 
        partTemp.title = {}

        questionTemp.parts.push( partTemp )
        questionTemp.nparts = questionTemp.parts.length 

        questions.push( questionTemp )

        let dd = structuredClone(data)

        dd.questions = questions 

        setData( dd )
        setSelectedQuestionIdx( questions.length - 1 )

        setShowAddMenu(false)
    }

    const copyLastQuestion = () => {
        let questions = structuredClone(data.questions)

        if( questions.length == 0 )
        {
            setShowAddMenu(false)
            return 
        }

        let lastQuestion = questions[ questions.length-1 ]
        questions.push( lastQuestion )
        
        let dd = structuredClone(data)

        dd.questions = questions 

        setData( dd )
        setSelectedQuestionIdx( questions.length - 1 )

        setShowAddMenu(false)
    }

    const onClick = ( index, item ) => {
        setSelectedQuestionIdx(index)
        setShowAddMenu(false)
    }

    return (
        <div style={{ width:'100%', height:'100%', overflowY:'scroll'}}>
            <div style={{ width:'100%', height:'150px' }}>
                <div style={{ height:'100%', width:'50%', float:'left' }}>
                    <TextAreaLang title="Title"
                              data={data}
                              setData={setData}
                              primaryLanguage={primaryLanguage}
                              secondaryLanguage={secondaryLanguage}
                              reference={['title']}
                    />
                </div>
                <div style={{ height:'100%', width:'50%', float:'right' }}>
                    <TextAreaLang title="Introduction"
                              data={data}
                              setData={setData}
                              primaryLanguage={primaryLanguage}
                              secondaryLanguage={secondaryLanguage}
                              reference={['introduction']}
                    />
                </div> 
            </div>
            <div style={{ width:'100%', height:'500px' }}>
                <div style={{ width:'120px', height:'100%', float:'left', padding:'5px'}}>
                    <div className="d-flex justify-content-start align-items-center fw-bolder"
                         style={{ height:'50px', width:'100%', marginBottom:'5px' }}>
                            <b style={{ marginLeft:'10px' }}>Questions</b>
                    </div>
                    <div style={{ height:'calc(100% - 55px)', width:'100%', overflowY:'scroll' }}>
                        <DNDListVertical items={(data.questions ) ? data.questions : []}
                                         direction="vertical"
                                         reorder={onReorder}
                                         Inner={QuestionButton}
                                         innerParams={{ 'selectedIdx': selectedQuestionIdx, 
                                                        'onClick': onClick,
                                                        'onRemove' : onRemove,
                                              }}
                                         idBuilder={idBuilder}
                        />
                        <ModuleSelector check={showAddMenu}
                                        childFalse={
                                                    <div className="d-flex align-items-center" 
                                                         style={{ width:'100%', height:'30px', border:'0.2px solid #AEAEAE', 
                                                                  marginBottom:'5px', borderRadius:'2px', color:'#AEAEAE',
                                                                  cursor:'pointer' }}
                                                         onClick={e => setShowAddMenu(true)}
                                                    >
                                                        <div style={{ marginLeft:'10px', float:'left' }}>
                                                            <ImPlus />
                                                        </div>
                                                        <div className="fw-bolder" style={{marginLeft:'5px', float:'left'}}>
                                                            Add
                                                        </div>
                                                    </div>
                                        }
                                        childTrue={
                                                    <CreateNewQuestionMenu 
                                                            addNew={addNewQuestion}
                                                            copyLast={copyLastQuestion}
                                                    /> 
                                        }
                        />

                    </div>
                </div>
                <div style={{ width:'calc(100% - 120px)', height:'100%', 
                              float:'right', padding:'5px' }}>
                
                    <div style={{ width:'100%', height:'100%', 
                                  border:'0.2px solid #DADADA', 
                                  borderRadius:'2px', padding:'5px'
                                }}
                    >
                        { ( selectedQuestionIdx >=0 && data.questions.length > selectedQuestionIdx )  &&
                        <QuestionEditor data={data}
                                        setData={setData}
                                        primaryLanguage={primaryLanguage}
                                        secondaryLanguage={secondaryLanguage}
                                        reference={ ['questions', selectedQuestionIdx ]}
                        />
                        }
                    </div>
                </div>
            </div>
            {/*<div style={{ width:'100%', height:'150px'}}>
                <TextAreaLang title="Final Notes"
                              data={data}
                              setData={setData}
                              primaryLanguage={primaryLanguage}
                              secondaryLanguage={secondaryLanguage}
                              reference={['final_notes']}
                />
            </div>*/}
            <div style={{ width:'100%', height:'150px'}}>
                <TextArea title="Formula"
                              data={data}
                              setData={setData}
                              reference={['formula']}
                />
            </div>
        </div>
    )
}
