import { Helmet } from 'react-helmet'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { FaTrashAlt } from "react-icons/fa";
import { TbTrashX } from "react-icons/tb";


import { backendAccessPost } from '../Utils/Authentication'

//import BrowsePatients from './Patients/BrowsePatients'
import PatientVisit from './Patients/PatientVisit'
//import CreatePatient from './Patients/CreatePatient'

import ModuleSelector from '../Components/Misc/ModuleSelector';
import BlackActionButton from '../Components/UX/BlackActionButton';
import { backendAccessGet } from '../Utils/Authentication';

import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'


export function BlackButton( props )
{
    const onClick = props.onClick
    const [ isHover, setIsHover ] = useState(false)

    return (
        <div className="d-flex justify-content-center align-items-center"
             size="sm" 
             style={{ borderRadius:'2px', backgroundColor: (isHover) ? '#28282B':'#000000', 
                      color:'#FFFFFF', cursor:'pointer', 
                      ...props.style }}
             onMouseEnter={ e => setIsHover(true) }
             onMouseLeave={ e => setIsHover(false) }
             onClick={ onClick }
        >
            { props.children }
        </div>
    )
}

export function LoadPatient( props )
{
    const patientId = props.patientId 
    const setPatientId = props.setPatientId 
    const setState = props.setState
    const navigate = props.navigate

    const LoadPatient = () => {
        navigate('/patient-view', {state:{patientId:patientId}})
    }

    return (
        <div style={{ width:'300px', height:'160px' }}>
            <div className='d-flex justify-content-center align-items-center fw-bolder' 
                 style={{ width:'100%', height:'30px', marginBottom:'10px', fontSize:12}}>
                Patients
            </div>

            <div style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <Form.Control type="text"
                              value={patientId}
                              onChange={ e => setPatientId( e.target.value ) }
                              placeHolder="Patient ID"
                              style={{ borderRadius:'2px' }}
                              size="sm"
                />

            </div>

            <div style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <BlackButton style={{ width:'100%', fontSize:14, height:'33px'}}
                             onClick={LoadPatient}>
                    Load patient data
                </BlackButton>
                        
            </div>
            <div style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <Button size="sm" style={{ width:'100%', borderRadius:'2px'}}
                        onClick={ e => setState("create_patient")} >
                    Create a new patient
                </Button> 
            </div>

            <div className="d-flex justify-content-center align-items-center" 
                 style={{ width:'100%', height:'30px', marginBottom:'10px'}}>

                <div className="fw-normal" style={{ cursor:'pointer', color:'#0d6efd' }}
                     onClick={ e => setState('browse_patients')}>
                    Browse Patients
                </div>
            </div>

        </div>
    )
}

export function CreatePatient( props )
{
    const patientId = props.patientId
    const setPatientId = props.setPatientId
    const setState = props.setState 
    const navigate = props.navigate
    const [ randomId, setRandomId ] = useState(null)
    const [ timestamp, setTimestamp ] = useState(null)
    const [ canEdit, setCanEdit ] = useState(false);
    const [ gender, setGender ] = useState("");
    const [ birthYear, setBirthYear ] = useState("");

    useEffect( () => {
        backendAccessGet('/api/patients/new/').then( res => {
            setRandomId( res["random_id"] )
            setTimestamp( res["timestamp"] )
        })
    }, [] )

    const createPatientAsync = async () => {
        if( gender == "" )
        {
            alert("Please select a gender.")
            return
        }

        if( birthYear == "" )
        {
            alert("Please input the birth year.")
            return
        }

        var data = {'patient_id':randomId}
        var res = await backendAccessPost("/api/patients/exists/", data)

        if( res )
        {
            if( res["id_exists"] )
            {
                alert("Patient ID already exists.")
                return
            }
        }

        data = {}
        data['random_id'] = randomId
        data['timestamp'] = timestamp
        data['year_of_birth'] = birthYear
        data['gender'] = gender

        res = await backendAccessPost("/api/patients/create/", data)

        if( res )
        {
            setPatientId(randomId)
        }
        else
        {
            alert("Something went wrong.")
        }
    }

    const createPatient = () => {
        createPatientAsync().then( res => {
            navigate('/patient-view', {state:{patientId:randomId}})

        })
    }

    const closeCreate = () => {
        setPatientId(null)
        setState("load_patient")
    }

    return (
        <div style={{ width:'300px', height:'310px' }}>
            <div className='d-flex justify-content-center align-items-center fw-bolder' 
                 style={{ width:'100%', height:'30px', marginBottom:'10px', fontSize:12}}>
                Create a new patient
            </div>
            <div style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <Form.Control type="text"
                              value={randomId}
                              onChange={ e => {
                                        setRandomId( e.target.value )
                                    }}
                              style={{ borderRadius:'2px' }}
                              disabled={!canEdit}
                              size="sm"
                              placeHolder="Patient ID"
                />
            </div>
            <div style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <Form.Control type="text"
                              value={timestamp}
                              onChange={ e => {
                                        setTimestamp( e.target.value )
                                    }}
                              style={{ borderRadius:'2px' }}
                              disabled={!canEdit}
                              size="sm"
                              placeHolder="Registeration Date"
                />

            </div>
            
            <div className="d-flex align-items-center" 
                 style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <Form.Check type="switch"
                            id="custom-switch"
                            checked={canEdit}
                            label="Allow edit"
                                        onChange={ e => {
                                            setCanEdit( e.target.checked )
                                        }}
                        />

            </div>
            
            <div style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <Form.Select type="text"
                             value={gender}
                                    onChange={ e => {
                                        setGender( e.target.value )
                                    }}
                             style={{ borderRadius:'2px' }}
                             size="sm"
                >
                    <option value="" selected disabled> - Gender - </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </Form.Select>
            </div>

            <div style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <Form.Control type="text"
                              value={birthYear}
                                    onChange={ e => {
                                        setBirthYear( e.target.value )
                                    }}
                              style={{ borderRadius:'2px' }}
                              size="sm"
                              placeHolder="Birth Year"
                />
            </div>
            
            <div style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <BlackButton style={{ width:'100%', fontSize:14, height:'33px'}}
                             onClick={createPatient}>
                    Create patient
                </BlackButton>

            </div>

            <div className="d-flex justify-content-center align-items-center" 
                 style={{ width:'100%', height:'30px', marginBottom:'10px'}}>
                <div style={{ color:'#0d6efd', cursor:'pointer', fontSize:14 }}
                                 onClick={closeCreate}>
                                Discard
                </div>

            </div>
        </div>
    )
}

export function BrowsePatients( props )
{
    const patientId=props.patientId 
    const setState=props.setState 
    const navigate=props.navigate

    const [ patientsList, setPatientsList ] = useState([])

    const [ showRemoveModal, setShowRemoveModal ] = useState(false)
    const [ removePatientId, setRemovePatientId ] = useState(null)
    const [ checkPatientId, setCheckPatientId] = useState("")
    const [ modalAlertText, setModalAlertText ] = useState( "" )

    const removePatient = ( patient_id ) => {

        setRemovePatientId( patient_id )
        setCheckPatientId("")
        setModalAlertText("")
        setShowRemoveModal( true )
    }

    const confirmRemovePatient = ( patient_id ) => {
        if( checkPatientId != removePatientId )
        {
            setModalAlertText("Patient ID confirmation failed. No data is removed.")
            return
        }

        var data = {}
        data['patient_id'] = removePatientId

        backendAccessPost('/api/patients/remove-patient/', data ).then( res => {
            if( 'error_message' in res )
            {
                alert( res['error_message'] )
                return
            }

            setShowRemoveModal( false )
            setPatientsList( res["patients_list"])
            
        })

    }

    useEffect( () => {
        backendAccessGet('/api/patients/browse/').then( res => {
            if( 'error_message' in res )
            {
                alert( res['error_message'] )
                return
            }

            setPatientsList( res.patients_list )

        })
    },[])

    const load_patient = ( patient_id ) => {
        navigate('/patient-view', {state:{patientId:patient_id}})
    }

    return (
        <div style={{ width:'300px', height:'100%', overflowY:'scroll' }}>
            <div className="d-flex justify-content-center align-items-center" style={{width:'100%', height:'45px'}}>
                <div style={{ color:'#0d6efd', cursor:'pointer' }}
                     onClick={ e => setState("load_patient")} >
                    Discard
                </div>
            </div>
            {patientsList.map( ( pdata, idx) => 
            <div style={{ width:'100%', height:'35px', padding:'5px', borderBottom:( idx != pdata.lenght-1 ) ? '0.2px solid #AEAEAE' : '' }}>
                <div className="d-flex align-items-center" style={{ height:'100%', width:'260px', float:'left', cursor:'pointer' }}
                     onClick={e => load_patient( pdata.patient_id ) }
                >
                    { pdata.patient_id }
                </div>
                <div className="d-flex align-items-center" style={{ height:'100%', width:'30px', float:'right', cursor:'pointer' }}
                     onClick={ e => removePatient( pdata.patient_id )}
                >
                    <TbTrashX />
                </div>

            </div>
            )}
            <Modal show={showRemoveModal} onHide={ e => setShowRemoveModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Patient</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Enter the ID of the patient for confirmation</Form.Label>
                    <Form.Control value={checkPatientId} onChange={ e => setCheckPatientId(e.target.value)} />
                    { modalAlertText != "" &&
                        <Alert variant="danger" style={{ marginTop:'10px'}}>{modalAlertText}</Alert>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={confirmRemovePatient} style={{borderRadius:'2px'}}>Remove</Button>
                    <Button onClick={ e => setShowRemoveModal(false)} style={{borderRadius:'2px'}}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default function Patients ()
{
    const [ pidInput, setPidInput ] = useState(null)
    const [ patientId, setPatientId ] = useState("")
    const [ state, setState ] = useState("load_patient")

    const navigate = useNavigate()

    const load_patient = () => {
        if( pidInput === "" )
        {
            alert("Please insert the patient id")
        }
        else
        {
            let data = {'patient_id':pidInput} 
            backendAccessPost('api/patients/exists/', data ).then( res => {

                if( "error_message" in res )
                {
                    alert( res["error_message"] )
                    return
                }

                if( res )
                {
                    if( res["id_exists"] )
                    {
                        setPatientId( pidInput )
                    }
                    else
                    {
                        alert("Patient ID does not exist.")
                    }
                }
                else
                {
                    alert("Server Error.")
                }
            })         
        }
    }

    useEffect( () => {
        if( patientId == null )
        {
            setState("load_patient")
        }
    },[patientId])

    return (
        <>
            <Helmet>
                <title> ETD Clinic | Patients </title>
            </Helmet>

            <div className="d-flex justify-content-center align-items-center" 
                 style={{ height: 'calc(100vh - 60px)', width:"100%", backgroundColor:'#F6F6F6'}}>
                { state == "load_patient" &&
                    <LoadPatient patientId={patientId}
                                 setPatientId={setPatientId}
                                 setState={setState}
                                 navigate={navigate}
                    />
                }

                { state == "create_patient" &&
                    <CreatePatient patientId={patientId}
                                   setPatientId={setPatientId}
                                   setState={setState}
                                   navigate={navigate}
                    />
                }

                { state == "browse_patients" &&
                    <BrowsePatients patientId={patientId}
                                    setPatientId={setPatientId}
                                    setState={setState}
                                    navigate={navigate}
                    />
                }

                {/*
                <ModuleSelector check={shouldCreate}
                                childFalse={
                                    <LoadPatient patientId={patientId}
                                                 setPatientId={setPatientId}
                                                 setState={setState}
                                                 navigate={navigate}
                                    />
                                }
                                childTrue={
                                    <CreatePatient patientId={patientId}
                                                   setPatientId={setPatientId}
                                                   setState={setState}
                                                   navigate={navigate}
                                    />
                                }
                />
                */}
            </div>
        </>
    )
}
