import Row from 'react-bootstrap/Row'
import QuestionPart from './QuestionPart'

export default function Question( props )
{
    const index = props.index
    const language = props.language
    const question = props.question
    const data = props.data
    const update = props.update

    let parts = question.parts
    let nparts = question.parts.length
    let multi_selections = question.multi_selections

    return (
        <Row>
            <Row>
                <b>Question {index}</b>  
            </Row>
            {   question['parts'].map( (p,part_index) =>
                <QuestionPart questionIndex={index}
                              partIndex={part_index}
                              nParts={nparts}
                              questionPart={p}
                              language={language}
                              data={data}
                              update={update}/>
            )}
        </Row>
    )
}
