import { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

export default function NotesViewer( props )
{
    const moduleId = props.moduleId
    const instanceData = props.instanceData
    const setInstanceData = props.setInstanceData
    const template = props.template

    const [ data, setData ] = useState({})

    useEffect( () => {
        var d = { ...instanceData }

        if( d["content"] == null )
        {
            d["content"] = ""
        }

        setData(d)
    }, [])

    const update = ( value ) => {
        data["content"] = value
        setData( { ...data } )
        setInstanceData( {...data} )
    }

    return (
        <div style={{ height:'100%', width:'100%', padding:'10px', overflowY:'auto' }}> 
            <Form.Control as="textarea" value={data["content"]} onChange={ (e) => update(e.target.value) } rows={10}   />
        </div>
    )
}
