import { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import {backendAccessGet, backendAccessPost} from '../../Utils/Authentication'

import { build_column_base_style, build_header_style } from './Styles'

import BasicInformationViewer from '../DataModules/BasicInformation/Viewer'
import LabAnalysisViewer from '../DataModules/LabAnalysis/Viewer'
import TodoCheckListViewer from '../DataModules/TodoChecklist/Viewer'
import NotesViewer from '../DataModules/Notes/Viewer'
import SurveyViewer from '../DataModules/Survey/Viewer'
import PatientAssessmentViewer from '../DataModules/PatientAssessment/Viewer'
import ImagingViewer from '../DataModules/Imaging/Viewer'
import BiobankViewer from '../DataModules/Biobank/Viewer'
import DiagnosisViewer from '../DataModules/Diagnosis/Viewer'
import MiscViewer from '../DataModules/Miscellaneous/Viewer'

export function CategorySelect( props )
{
    const moduleCategory = props.moduleCategory
    const patientId = props.patientId
    const visitId = props.visitId
    const moduleId = props.moduleId
    const instanceData = props.instanceData
    const setInstanceData = props.setInstanceData
    const setInstance = props.setInstance
    const template = props.template
    const version = props.version
    const status = props.status
    const reference = props.reference
    const createPlaceHolder = props.createPlaceHolder
    const dumpEmpty = props.dumpEmpty
    const reload = props.reload
    const hasChange = props.hasChange

    if( moduleCategory === "basic_info" )
    {
        return (
            <BasicInformationViewer moduleId={moduleId}
                                    instanceData={instanceData}
                                    setInstanceData={setInstanceData}
                                    template={template}
            />
        )
    }
    else if( moduleCategory === "lab_analysis" )
    {
        return (
            <LabAnalysisViewer moduleId={moduleId}
                               instanceData={instanceData}
                               setInstanceData={setInstanceData}
                               template={template}
            />
        )
    }
    else if( moduleCategory === "todo_checklist")
    {
        return (
            <TodoCheckListViewer moduleId={moduleId}
                                 instanceData={instanceData}
                                 setInstanceData={setInstanceData}
                                 template={template}/>
        )
    }
    else if( moduleCategory === "note" )
    {
        return (
            <NotesViewer moduleId={moduleId}
                         instanceData={instanceData}
                         setInstanceData={setInstanceData}
                         template={template}/>

        )
    }
    else if( moduleCategory === "survey" )
    {
        return ( <SurveyViewer moduleId={moduleId}
                               instanceData={instanceData}
                               setInstanceData={setInstanceData}
                               template={template}
                               version={version}
                               status={status}
                               reference={reference}
                               createPlaceHolder={createPlaceHolder}
                               dumpEmpty={dumpEmpty}
                               reload={reload}/>
        )
    }
    else if( moduleCategory === "patient_assessment" )
    {
        return (
            <PatientAssessmentViewer moduleId={moduleId}
                                     instanceData={instanceData}
                                     setInstanceData={setInstanceData}
                                     template={template}/>
        )
    }
    else if( moduleCategory === "imaging" )
    {
        return ( <ImagingViewer patientId={patientId}
                                visitId={visitId}
                                moduleId={moduleId}
                                instanceData={instanceData}
                                setInstanceData={setInstanceData}
                                template={template}
                                version={version}
                                status={status}
                                reference={reference}
                                createPlaceHolder={createPlaceHolder}
                                dumpEmpty={dumpEmpty}
                                reload={reload}/>
        )
    }
    else if( moduleCategory === "bio_bank" )
    {
        return (
            <BiobankViewer  patientId={patientId}
                            visitId={visitId}
                            moduleId={moduleId}
                            instanceData={instanceData}
                            setInstanceData={setInstanceData}
                            setInstance={setInstance}
                            template={template}
                            version={version}
                            status={status}
                            reload={reload}
                            hasChange={hasChange}/>
        )
    }
    else if( moduleCategory === "diagnosis" )
    {
        return (
            <DiagnosisViewer instanceData={instanceData}
                             setInstanceData={setInstanceData}
                             template={template}
            />
        )
    }
    else if( moduleCategory === "misc" )
    {
        return (
            <MiscViewer patientId={patientId}
                        visitId={visitId}
                        moduleId={moduleId}
                        instanceData={instanceData}
                        setInstanceData={setInstanceData}
                        template={template}
                        version={version}
                        status={status}
                        reference={reference}
                        createPlaceHolder={createPlaceHolder}
                        dumpEmpty={dumpEmpty}
                        reload={reload}/>
        )
    }
    else
    {
        return <>Unknown Module</>
    }
}

export function TemplateView( props )
{
    const template = props.template

    const [ content, setContent ] = useState( null )


    useEffect( () => {
        var c = JSON.stringify(template)
        setContent(c)
    }, [])

    return (
        <Form.Control as="textarea" style={{ height:'100%', width:'100%'}}
                          value={content}
        />
    )
}

export function DataView( props )
{
    const data = props.data
    const setData = props.setData
    const [ content, setContent ] = useState( null )

    useEffect( () => {
        var c = JSON.stringify(data)
        setContent(c)
    }, [])

    useEffect( () => {
        if( content == null )
        {
            return
        }

        if( content == "" )
        {
            return
        }

        try {
            var content_decoded = JSON.parse(content)
            setData( content_decoded )
        }
        catch
        {
            alert("Failed to parse the content.")
        }
    }, [content])

    return (
        <Form.Control as="textarea" style={{ height:'100%', width:'100%'}}
                      value={content} onChange={ e => setContent(e.target.value)}
        />
    )
}

export default function DataModuleViewer( props )
{
    const patientId = props.patientId
    const visitId = props.visitId
    const dataModule = props.dataModule
    const setDataModulesStatus = props.setDataModulesStatus
    const toolHeight = props.toolHeight
    

    const [ moduleId, setModuleId ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ moduleCategory, setModuleCategory ] = useState(null)
    const [ instance, setInstance ] = useState(null)
    const [ template, setTemplate ] = useState(null)
    const [ version, setVersion ] = useState(null)
    const [ status, setStatus ] = useState(null)
    const [ reference, setReference ] = useState(null)
        
    const [ instanceData, setInstanceData ] = useState(null)
    const [ instanceDataStr, setInstanceDataStr ] = useState(null)
    const [ hasChange, setHasChange ] = useState(false)

    const [ modalTemplate, setModalTemplate ] = useState( false )
    const [ modalData, setModalData ] = useState( false )
    const [ rawData, setRawData ] = useState( null )

    const load = () => {
        var data = {}
        data['patient_id'] = patientId
        data['visit_id'] = visitId
        data['module_id'] = dataModule.id
        data['category'] = dataModule.category

        setModuleId( dataModule.id )

        backendAccessPost('/api/patients/visits/get-module-data/', data).then( res => {
            if("error_message" in res)
            {
                alert( res["error_message"] )
                return
            }

            setLoading( true )

            setModuleCategory( dataModule.category )
            setInstance( res['instance'] )

            var module_template
            try {
                module_template = JSON.parse( res['template']['module_data'] )
            }
            catch {
                var replaced_str = res['template']['module_data'].replaceAll("\\\"", "\"")
                module_template = JSON.parse( replaced_str )
            }

            setTemplate( module_template )
            setVersion( res['instance']['version'] )
            setStatus( res['instance']['status'] )
            setReference( res['instance']['reference'] )

            var instance_data 
            try {
                instance_data = JSON.parse(res['instance']['data'])
            }
            catch {
                var replaced_str = res['instance']['data'].replaceAll("analysis\\", "analysis\\\\")
                instance_data = JSON.parse( replaced_str )
            }


            setInstanceData( instance_data )
            setInstanceDataStr( JSON.stringify(instance_data) )
            setHasChange(false)
            
            setLoading( false )


        })
    }

    useEffect( () => {
        if( dataModule != null )
        {
            load()
        }
    }, [])

    useEffect( () => {
        if( dataModule != null )
        {
            load()
        }
    }, [ dataModule ] )

    useEffect( () => {
        if( instanceData != null )
        {
            var current_str = JSON.stringify(instanceData)
            if( current_str != instanceDataStr )
            {
                setHasChange( true )
            }
            else
            {
                setHasChange( false )
            }
        }
    }, [instanceData] )

    const dump = () => {
        if( hasChange )
        {
            var data = {}
            data['patient_id'] = patientId
            data['visit_id'] = visitId
            data['module_id'] = dataModule.id
            data['category'] = dataModule.category
            data['instance_data'] = JSON.stringify(instanceData)

            backendAccessPost('/api/patients/visits/dump-module-data/', data).then( res => {
                if( "error_message" in res )
                {
                    alert( res["error_message"] )
                    return
                }

                

                setInstanceDataStr( data['instance_data'] )
                setVersion( res["version"] )
                setDataModulesStatus( res.modules_status )
                setHasChange( false )
            })
        }
    }

    const createPlaceHolder = () => {
        var data = {}
        data['patient_id'] = patientId
        data['visit_id'] = visitId
        data['module_id'] = dataModule.id
        data['category'] = dataModule.category

        backendAccessPost('/api/patients/visits/create-placeholder/', data).then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                return
            }

            load()

        })
    }

    const dumpEmpty = () => {
            var data = {}
            data['patient_id'] = patientId
            data['visit_id'] = visitId
            data['module_id'] = dataModule.id
            data['category'] = dataModule.category
            data['instance_data'] = JSON.stringify({})

            backendAccessPost('/api/patients/visits/dump-module-data/', data).then( res => {
                if( "error_message" in res )
                {
                    alert( res["error_message"] )
                    return
                }

                load()
            })

    }

    const reload = () => {
        load()
    }

    const openViewData = () => {
        setRawData( structuredClone( instanceData ) )
        setModalData( true )
    }

    const saveAndCloseViewData = () => {
        setInstanceData( rawData )
        setModalData( false )
        setRawData( null )
    }

    return (
        <div style={{height:'100%', width:'100%'}}>

        { ( dataModule && template && instanceData && moduleCategory && moduleId && !loading )  &&
            <>
                <div  style={{ height:toolHeight, width:'100%', padding:'10px', alignItems:'center'}}>
                        <div style={{ float:'right' }}>
                        <Button variant={(hasChange) ? "danger" : "primary"} 
                                style={{ borderRadius:'2px', marginRight:'5px' }}
                                onClick={dump}>Save</Button>
                        <Button variant="secondary"
                                style={{ borderRadius:'2px' }}>Version : { version }</Button>
                        </div>
                </div>
                <div style={{ height:`calc(100% - ${toolHeight})`, width:'100%', padding:'10px' }}>
                    <div style={{ height:'100%', width:'100%', 
                                  border:( hasChange) ? '1px solid #DC4C64' : '1px solid #0057FF', borderRadius:'2px',
                                  padding:'10px'}}>
                        <CategorySelect moduleCategory={moduleCategory}
                                        patientId={patientId}
                                        visitId={visitId}
                                        moduleId={moduleId}
                                        instanceData={instanceData}
                                        setInstanceData={setInstanceData}
                                        setInstance={setInstance}
                                        template={template}
                                        version={version}
                                        status={status}
                                        reference={reference}
                                        createPlaceHolder={createPlaceHolder}
                                        dumpEmpty={dumpEmpty}
                                        reload={reload}
                                        hasChange={hasChange}
                        />
                    </div>
                </div>
            </>
        }

            {/*
            <Modal show={modalTemplate} 
                   onHide={ e => setModalTemplate(false) }
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <Modal.Header closeButton>
                    <b>Template</b>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height:'500px', width:'100%'}}>
                        <TemplateView template={template} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ e => setModalTemplate(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalData} 
                   onHide={ e => setModalData(false) } 
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <Modal.Header closeButton>
                    <b>Data</b>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height:'500px', width:'100%'}}>
                        <DataView data={rawData}
                                  setData={setRawData}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ e => setModalData(false)}>Close</Button>
                    <Button variant="secondary" onClick={saveAndCloseViewData}>Save and Close</Button>
                </Modal.Footer>
            </Modal>
                */}
        </div>
    )
}

