import { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { backendAccessPost, backendAccessGet } from '../../Utils/Authentication'
import BlackActionButton from '../UX/BlackActionButton'


export default function Unplaced( props )
{
    const [ samples, setSamples ] = useState(null)

    const reload = () => {
        backendAccessGet("/api/biobank/unplaced-samples-list/").then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                return
            }
            setSamples( res['samples'] )
        })
    }

    useEffect( () => {
        reload()
    },[])

    const placeInBox = ( sample_id, box_id ) => {
        var data = {}
        data['SAMPLE-ID'] = sample_id
        data['BOX-ID'] = box_id

        backendAccessPost("/api/biobank/place-sample/", data ).then( res => {
            if( "error_message" in res )
            {
                alert(res['error_message'])
                return
            }

            reload()
        })
    }

    const hideSample = ( sample_id ) => {
        var check = window.confirm("Are you sure you want to hide the sample?")

        if( !check )
        {
            return
        }

        var data = {}
        data['SAMPLE-ID'] = sample_id

        backendAccessPost("/api/biobank/hide-unplaced-sample/", data ).then( res => {
            if( "error_message" in res )
            {
                alert(res['error_message'])
                return
            }

            reload()
        })
    }
   
    return (
        <div style={{ height:"100%", width:"100%", fontSize:12,
                padding:'10px', overflowY:'scroll'}}>
            { samples &&
            <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ width:'10px'}}>#</th>
                            <th style={{ width:'40px'}}>Sample ID</th>
                            <th style={{ width:'40px'}}>Patient ID</th>
                            <th style={{ width:'40px'}}>Visit ID</th>
                            <th style={{ width:'40px'}}>Box ID</th>
                            <th style={{ width:'40px'}}>Box Location</th>
                            <th style={{ width:'40px'}}>Sample Type</th>
                            <th style={{ width:'40px'}}>Remaining</th>
                            <th style={{ width:'60px'}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    { samples.map( (s,idx) =>
                        <tr>
                            <td> {idx + 1} </td>
                            <td> { s.sample_id } </td>
                            <td> { s.patient_id } </td>
                            <td> { s.visit_id } </td>
                            <td> { s.box_id } </td>
                            <td> { s.box_loc } </td>
                            <td> { s.sample_type } </td>
                            <td> { s.remaining } </td>
                            <td>
                                <BlackActionButton onClick={ e => placeInBox(s.sample_id, s.box_id) }
                                                   style={{ borderRadius:'2px', width:'48px', marginRight:'5px', float:'left' }}
                                >
                                    Place
                                </BlackActionButton>
                                <BlackActionButton onClick={ e => hideSample(s.sample_id) }
                                                   style={{ borderRadius:'2px', width:'43px', marginRight:'5px', float:'left' }}
                                >
                                    Hide
                                </BlackActionButton>
                            </td>
                         </tr>
                    )}
                    </tbody>
            </Table>
            }

        </div>
    )
}
