import Row from 'react-bootstrap/Row'
import Question from './Question'

export default function Questions( props )
{
    const language = props.language
    const template = props.template
    const data = props.data
    const update = props.update
    
    return (
        <Row>
            { template['questions'].map( (q,index) =>
                <Question index={index+1} 
                          language={language}
                          question={q}
                          data={data}
                          update={update} />
                )
            }
        </Row>
    )
}
