import { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
//import Slider from './Slider'

export default function QuestionPart( props )
{
    const questionIndex = props.questionIndex
    const partIndex = props.partIndex
    const nParts = props.nParts
    const questionPart = props.questionPart
    const language = props.language
    const data = props.data
    const update = props.update

    const multi_selections = questionPart["multi_selections"] === "yes";
    const answers = questionPart["answers"]
    const textarea = questionPart["textarea"]

    const var_id = questionIndex + "_" + partIndex

    const [ text, setText ] = useState( data[var_id] )

    const updateRadio = ( answerIndex, checked ) => {
        if( checked )
        {
            update( var_id, answerIndex )
        }
    }

    const updateCheckBox = ( answerIndex, checked ) => {
        update( var_id + "_" + answerIndex, checked )
    }

    const updateTextArea = ( val ) => {
        update( var_id, val )
    }


    return (
        <Row style={{ padding:"30px" }}>
            { nParts > 1 &&
            <Row>
                <b>Part - { partIndex+1 }</b>
            </Row>
            }

            <p> {questionPart['title'][language] } </p>
            { (answers.length > 0 && !multi_selections) &&
                <Row style={{ marginLeft: '30px'}}>
                <Form.Group>
                { answers.map( (a,answerIndex) => 
                    <Row>
                        <Form.Check type='radio' 
                                    name={var_id} 
                                    checked={ data[var_id] == answerIndex }
                                    id={ var_id + "_" + answerIndex } 
                                    label={a.text[language]}
                                    onChange={ (e) => updateRadio( answerIndex, e.target.checked ) }
                        />
                    </Row>
                ) }
                </Form.Group>
                </Row>
            }

            { ( answers.length > 0 && multi_selections ) &&
                <Row style={{ marginLeft: '30px'}}>
                <Form.Group>
                { answers.map( (a,answerIndex) => 
                    <Row>
                        ( {a.value} )
                        <Form.Check type='checkbox' 
                                    name={var_id + '_' + answerIndex}
                                    checked={ data[var_id + "_" + answerIndex ] }
                                    label={ a.text[language]} />
                                    onChange={ (e) => updateCheckBox( answerIndex, e.target.checked ) }
                    </Row>
                ) }
                </Form.Group>
                </Row>
            }

            { textarea &&
                <Form.Control as='textarea' 
                              name={var_id}
                              value={ data[var_id] }
                              onChange={ (e) => updateTextArea( e.target.value ) }
                />
            }
        </Row>
    )
}
