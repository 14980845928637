import { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Vectra360Viewer from './Vectra360Viewer'
import ScannerViewer from './ScannerViewer'
import ConsentFormViewer from './ConsentFormViewer'

export function ImagingSelect( props )
{
    const patientId = props.patientId
    const visitId = props.visitId
    const moduleId = props.moduleId
    const moduleType = props.moduleType
    const instanceData = props.instanceData
    const setInstanceData = props.setInstanceData
    const template = props.template
    const version = props.version
    const status = props.status
    const reference = props.reference
    const createPlaceHolder = props.createPlaceHolder
    const dumpEmpty = props.dumpEmpty
    const reload = props.reload

    if( moduleType == "vectra360" )
    {
        return (
            <Vectra360Viewer instanceData={instanceData}
                             setInstanceData={setInstanceData}
                             template={template}
                             version={version}
                             status={status}
                             reference={reference}
                             createPlaceHolder={createPlaceHolder}
                             dumpEmpty={dumpEmpty}
                             reload={reload} />
        )
    }
    else if( moduleType == "scanner" )
    {
        return (
            <ScannerViewer patientId={patientId}
                           visitId={visitId}
                           moduleId={moduleId}
                           instanceData={instanceData}
                           setInstanceData={setInstanceData}
                           template={template}
                           version={version}
                           status={status}
                           reference={reference}
                           createPlaceHolder={createPlaceHolder}
                           dumpEmpty={dumpEmpty}
                           reload={reload} />
        )
    }
    else if( moduleType == "consent_form" )
    {
        return (
            <ConsentFormViewer patientId={patientId}
                                visitId={visitId}
                                moduleId={moduleId}
                                instanceData={instanceData}
                                setInstanceData={setInstanceData}
                                template={template}
                                version={version}
                                status={status}
                                reference={reference}
                                createPlaceHolder={createPlaceHolder}
                                dumpEmpty={dumpEmpty}
                                reload={reload} />
        )
    }
    else
    {
        return (
            <p>Unknown Imaging Module</p>
        )
    }

}

export default function ImagingViewer( props )
{
    const patientId = props.patientId
    const visitId = props.visitId
    const moduleId = props.moduleId
    const instanceData = props.instanceData
    const setInstanceData = props.setInstanceData
    const template = props.template
    const version = props.version
    const status = props.status
    const reference = props.reference
    const createPlaceHolder = props.createPlaceHolder
    const dumpEmpty = props.dumpEmpty
    const reload = props.reload

    const [ moduleType, setModuleType ] = useState(null)
 
    const load = ( template ) => {
        if( template != null )
        {
            setModuleType( template["module_type"] )
        }
    }

    useEffect( () => { 
        load( template )
    }, [])
    
    useEffect( () => {
        load( template )
    }, [ instanceData, template, version ] )

    return (
        <div style={{ height:'100%', width:'100%', padding:'10px', overflowY:'auto' }}>
            <ImagingSelect patientId={patientId}
                           visitId={visitId}
                           moduleId={moduleId}
                           moduleType={moduleType}
                           instanceData={instanceData}
                           setInstanceData={setInstanceData}
                           template={template}
                           version={version}
                           status={status}
                           reference={reference}
                           createPlaceHolder={createPlaceHolder}
                           dumpEmpty={dumpEmpty}
                           reload={reload}
            />
        </div>
    )
}
