import { Helmet } from 'react-helmet'
import Container from 'react-bootstrap/Container'

export default function SOPs ()
{
    return (
        <>
            <div style={{ height: 'calc(100vh - 60px)', width:"100%"}}>
                <div className="d-flex align-items-center justify-content-center"
                     style={{ height: "100%", width: "100%"}}>
                
                    <h1>SOPs</h1>
                </div>
            </div>
        </>
    )
}
