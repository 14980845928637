import { useState, useEffect } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

import { backendAccessPost, backendAccessGet } from '../../Utils/Authentication'


export default function Racks( props )
{
    const [ rackType, setRackType ] = useState(null)
    const [ freezerId, setFreezerId ] = useState(null)

    const [ types, setTypes ] = useState(null)
    const [ racks, setRacks ] = useState(null)
    const [ freezers, setFreezers ] = useState(null)
    const [ freezersMap, setFreezersMap ] = useState(null)

    useEffect( () => {

        backendAccessGet("/api/biobank/racks-list/").then( res => {
            if( "error_message" in res )
            {
                alert( res["error_message"] )
                return
            }
            setTypes( res["rack_types"] )
            setRacks( res["racks"] )
            setFreezers( res["freezers"] )
            setFreezersMap( res["freezers_map"] )
        })
    },[])

    const addRack = () => {
        if( rackType != null && rackType != "" && freezerId != null && freezerId != "" )
        {
            var data = {}
            data["rack_type"] = rackType
            data["freezer_id"] = freezerId

            backendAccessPost("/api/biobank/add-rack/", data).then( res => {
                if("error_message" in res)
                {
                    alert( res["error_message"] )
                    return
                }

                setRacks( res["racks"] )
                setRackType("")
                setFreezerId("")
            })
        }
    }

    const downloadCSV = ( rack_id ) => {
        var csv_data = "";
        csv_data += "rack_id\n"
        csv_data += rack_id + "\n";

        var data = new Blob([csv_data], {type: 'text/csv'});
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', rack_id + ".csv");
        tempLink.click();

    }

    return (
        <div style={{ height:"100%", width:"100%", fontSize:12}}>
            <div className="d-flex justify-content-center" 
                 style={{ height:'150px', width:'100%', padding:'10px'}}>

                { ( types && freezers ) &&
                    
                <div style={{ height:'100%', width:'300px' }}>
                    <div style={{ width:'100%', paddingBottom:'10px', borderBottom:'0.2px solid #DADADA'}}>

                        <Form.Select style={{ width:'100%'}} 
                                     onChange={ (e) => setRackType(e.target.value)} 
                                     value={rackType}
                                     size="sm"
                                     style={{ border:'none', borderRadius:'0px' }}>
                            <option value="">-- Choose Rack type --</option>
                            { types.map( (t,idx) => 
                                <option value={t.type}>{t.name}</option>
                            )}
                        </Form.Select>
                    </div>
                    <div  style={{ width:'100%', paddingBottom:'10px', paddingTop:'5px' }}>
                        <Form.Select style={{ width:'100%'}} 
                                     onChange={ (e) => setFreezerId(e.target.value)} 
                                     value={freezerId}
                                     size="sm"
                                     style={{ border:'none', borderRadius:'0px' }}>
                            <option value="">-- Choose Freezer --</option>
                            { freezers.map( (f,idx) => 
                                <option value={f.freezer_id}>{f.freezer_name}</option>
                            )}
                        </Form.Select>
                    </div>


                    <div className="d-flex justify-content-start" style={{ width:'100%' }}>
                        <Button onClick={addRack} size="sm" style={{ borderRadius:'2px' }}>Add Rack</Button>
                    </div>
                </div>
                }
            </div>
            <div style={{ height:"calc(100% - 80px)", width:"100%", padding:'10px'}}>
                { ( racks && freezersMap ) &&
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ width: "20px"}}>#</th>
                            <th style={{ width: "80px"}}>Rack ID</th>
                            <th style={{ width: "80px"}}>Freezer Name</th>
                            <th style={{ width: "50px"}}>Version</th>
                            <th style={{ width: "50px"}}>Label</th>
                        </tr>
                    </thead>
                    <tbody>
                    { racks.map( (r,idx) => 
                        <tr>
                            <td> {idx + 1} </td>
                            <td> { r.rack_id } </td>
                            <td> { freezersMap[r.freezer_id] } </td>
                            <td> { r.version } </td>
                            <td> <Button size="sm" variant="secondary"
                                         onClick={ e => downloadCSV(r.rack_id) }
                                         size="sm"
                                         style={{ borderRadius:'2px'}}>
                                    Download CSV
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                }
            </div>
        </div>
    )
}

